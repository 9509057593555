<div>
  <div
    *ngIf="validateQR"
    class="alert alert-primary fw-bold text-center my-3"
    role="alert"
  >
    Showing Checkpoints that need to be validated
  </div>
  <div *ngIf="!validateQR && isAdmin">
    <!-- Nav/Tab section  -->
    <ul class="nav" [ngClass]="isAdmin ? 'nav-tabs' : ''" id="checkpointTab">
      <li class="nav-item" (click)="changeTab(tabNames.tab1)">
        <a
          class="nav-link"
          [ngClass]="selectedTab === tabNames.tab1 ? 'active' : ''"
          >{{ tabNames.tab1 }}</a
        >
      </li>
      <!--QR Code section-->
      <li
        class="nav-item"
        (click)="changeTab(tabNames.tab2)"
        id="qrCheckpointTab"
      >
        <a
          class="nav-link"
          [ngClass]="selectedTab === tabNames.tab2 ? 'active' : ''"
          >{{ tabNames.tab2 }}</a
        >
      </li>
      <li
        class="nav-item"
        (click)="changeTab(tabNames.tab3)"
        id="missedCheckpointTab"
      >
        <a
          class="nav-link"
          [ngClass]="selectedTab === tabNames.tab3 ? 'active' : ''"
          >{{ tabNames.tab3 }}</a
        >
      </li>
    </ul>
  </div>
  <!-- Tab Content starts -->
  <!-- Checkpoint Tab -->
  <div class="tab-content mt-2">
    <div
      class="tab-pane"
      [ngClass]="selectedTab === tabNames.tab1 ? 'active' : ''"
    >
      <ng-container *ngIf="!validateQR">
        <div
          class="me-lg-2 my-2 text-end"
          *ngIf="!selectedCp && totalCheckpointCount > 1"
        >
          <button
            id="toggleCheckpointView"
            class="btn btn-secondary btn-sm"
            (click)="switchView()"
          >
            @if(largeView){ @if(viewType === "mapView"){
            <span>
              <i class="fa-solid fa-table"></i>
            </span>
            }@else {<span>
              <i class="fa-solid fa-map"></i>
            </span>
            } }@else { @if(viewType === "mapView"){
            <span>
              <i class="fa-solid fa-table"></i>
            </span>
            }@else {<span> <i class="fa-solid fa-map"></i> </span>}}
          </button>
        </div>
        <div class="d-flex flex-column flex-sm-row input-btn-group mb-2">
          <div class="mb-2 me-sm-2 col-lg-2" *ngIf="isAdmin">
            <button
              id="addCheckpoint"
              class="btn btn-primary btn-lg w-100"
              (click)="addCheckpoints()"
            >
              + Add Checkpoint
            </button>
          </div>
          <div
            class="mb-2 flex-sm-grow-1"
            *ngIf="totalCheckpointCount > 5"
            id="filterCheckpoint"
          >
            <gtapp-auto-complete
              [data]="checkpointDropDownList"
              placeHolder="Search Checkpoints"
              searchKeyword="name"
              [initialValue]="globalFilter"
              [itemTemplate]="cpLookupTemplate"
              (inputChanged)="onCheckpointLookup($event)"
              (selected)="onCheckpointRedirect($event)"
              (submitted)="globalFilter = $event; getCheckPoints()"
              [enableSearchCloseOption]="true"
              (fieldCleared)="globalFilter = null; getCheckPoints()"
            >
            </gtapp-auto-complete>
            <ng-template #cpLookupTemplate let-item>
              <div>
                <div *ngIf="item?.qr_code" class="float-end">
                  <img
                    src="assets/images/qr-code-logo.avif"
                    alt=""
                    width="26"
                    class="mb-0 pb-0"
                  />
                </div>
                <div>{{ item?.name }}</div>
                <div>
                  @if(item?.site_name){
                  {{ item?.site_name }} [ {{ item?.company }}] }@else {
                  {{ item?.company }}
                  }
                </div>

                <div class="small-font text-secondary">
                  {{ item?.address?.full_address }}
                </div>
              </div>
            </ng-template>
          </div>
          <div class="ms-md-2" *ngIf="qrToBeValidatedCount && isAdmin">
            <button
              class="btn btn-danger btn-lg w-100"
              (click)="validateCheckpoints()"
            >
              Validate Checkpoints
            </button>
          </div>
        </div>
      </ng-container>
      <div id="checkpointList" *ngIf="totalCheckpointCount > 0">
        <div
          class="row row-cols-1 gy-2"
          *ngIf="viewType == 'cardView' && !largeView"
        >
          <gtapp-card-list
            [mobileCardBackendData]="checkPointList"
            [mobileCardDetails]="checkPointCardsDetails"
            [otherParams]="otherParams"
            (sortColumn)="sortColumn($event)"
            (rowClick)="onCheckpointRedirect($event)"
            (paginationChange)="onChangePagination($event)"
          ></gtapp-card-list>

          <!-- <gtapp-infinite-list
          [mobileCardBackendData]="infiniteDataList"
          [mobileCardDetails]="checkPointCardsDetails"
          [totalRows]="totalRows"
          (loadMoreData)="previous = previous + rows; getCheckPoints()"
          (rowClick)="onCheckpointRedirect($event)"
          (sortColumn)="sortColumn($event)"
          (searchColumn)="searchColumn($event)"
        ></gtapp-infinite-list> -->
        </div>
        <div *ngIf="largeView">
          <div
            class="row row-cols-1 gy-2 mt-2"
            [ngClass]="mapLatLong ? 'row row-cols-2' : 'row row-cols-1'"
          >
            <div class="col">
              <gtapp-gt-table
                *ngIf="checkPointList && viewType == 'tableView'"
                [desktopTableBackendData]="checkPointList"
                [desktopTableDetails]="checkPointDetailsTableView"
                [tableStyle]="tableStyle"
                [otherParams]="otherParams"
                (paginationChange)="onChangePagination($event)"
                (sortColumn)="sortColumn($event)"
                (searchColumn)="searchColumn($event)"
                (rowClick)="onCheckPointClick($event)"
              ></gtapp-gt-table>
            </div>
            <div class="col" *ngIf="selectedCp">
              <gtapp-map-leaflet
                *ngIf="mapLatLong"
                [latLon]="mapLatLong"
                [mapId]="'checkpointLocation'"
              ></gtapp-map-leaflet>

              <div class="row row-cols-3">
                <div class="col">
                  <button
                    class="btn btn-secondary btn-lg w-100"
                    (click)="onCheckpointRedirect(selectedCp)"
                  >
                    Edit Checkpoint
                  </button>
                </div>
                <div class="col">
                  <button
                    class="btn btn-secondary btn-lg w-100"
                    (click)="redirectCompanyORSite(selectedCp)"
                  >
                    {{ selectedCp?.site_id ? " View Site " : " View Client " }}
                  </button>
                </div>
                <div class="col">
                  <!-- Only allow administrators to delete checkpoints -->
                  <button
                    class="btn btn-danger btn-lg w-100"
                    (click)="deleteCheckPoint(selectedCp)"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        @if(checkPointList?.length && viewType == 'mapView'){

        <div class="mt-1">
          <gtapp-map-pins-view
            [listData]="checkPointList"
            [mapFrom]="'mobileCpPage'"
            (openItemPage)="onCheckpointRedirect($event)"
          ></gtapp-map-pins-view>
          <div *ngIf="otherParams?.paginationData" class="mt-2">
            <gtapp-pagination-control
              [pageSize]="rows"
              [pageNum]="pageNum"
              [previous]="previous"
              [totalRows]="totalRows"
              (onChangePagination)="onMapChangePagination($event)"
            >
            </gtapp-pagination-control>
          </div>
        </div>
        }
      </div>
    </div>
  </div>

  <!-- QR CODE Tab -->
  <div class="tab-content">
    <div
      class="tab-pane"
      [ngClass]="selectedTab === tabNames.tab2 ? 'active' : ''"
    >
      <gtapp-qr *ngIf="qrCodePage"></gtapp-qr>
    </div>
  </div>
  <div class="tab-content">
    <div
      class="tab-pane"
      [ngClass]="selectedTab === tabNames.tab3 ? 'active' : ''"
    >
      @if(largeView){
      <gtapp-gt-table
        [desktopTableBackendData]="missedCheckpointData"
        [desktopTableDetails]="tableDetailMissedCheckpoint"
        [otherParams]="otherParamsMissedCheckpoint"
        (searchColumn)="searchColumnMissedCheckpoint($event)"
        (sortColumn)="sortColumnMissedCheckpoint($event)"
        (paginationChange)="onChangePaginationMissedCheckpoint($event)"
        (rowClick)="redirectToPatrol($event)"
      >
      </gtapp-gt-table
      >}@else{
      <!-- <gtapp-card-list
        [mobileCardBackendData]="missedCheckpointData"
        [mobileCardDetails]="mobileDetailMissedCheckpoint"
        [otherParams]="otherParamsMissedCheckpoint"
        (searchColumn)="searchColumnMissedCheckpoint($event)"
        (sortColumn)="sortColumnMissedCheckpoint($event)"
        (paginationChange)="onChangePaginationMissedCheckpoint($event)"
        (rowClick)="redirectToPatrol($event)"
      ></gtapp-card-list> -->

      <gtapp-infinite-list
        [mobileCardBackendData]="infiniteDataList"
        [mobileCardDetails]="mobileDetailMissedCheckpoint"
        [totalRows]="totalRowsMissedCheckpoint"
        (loadMoreData)="
          previousMissedCheckpoint =
            previousMissedCheckpoint + pageSizeMissedCheckpoint;
          getMissedCheckpointData()
        "
        (rowClick)="redirectToPatrol($event)"
        (sortColumn)="sortColumnMissedCheckpoint($event)"
        (searchColumn)="searchColumnMissedCheckpoint($event)"
      ></gtapp-infinite-list>

      }
    </div>
  </div>
  <!-- History Section Tab Content -->
  <!-- <div class="tab-content">
    <div
      class="tab-pane"
      [ngClass]="selectedTab === tabNames.tab3 ? 'active' : ''"
    >
      <ng-container>
        <gtapp-user-update-log
          [eventType]="'checkpoint'"
        ></gtapp-user-update-log>
      </ng-container>
    </div>
  </div> -->
</div>
