import { Location } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { isLargeScreen } from '../../../../../../global.variable';
import { AppService } from '../../../../../app.service';
import { AddEditLicenseDetailsComponent } from '../../../../../shared/components/add-edit-license-details/add-edit-license-details.component';
import { AddEditSiteComponent } from '../../../../../shared/components/add-edit-site/add-edit-site.component';
import { ConfirmDialogComponent } from '../../../../../shared/components/confirm-dialog/confirm-dialog.component';
import { ContactComponent } from '../../../../../shared/components/contact/contact.component';
import { ModelDialogueService } from '../../../../../shared/components/modal-dialogue/model-dialogue.service';
import { DataCheckService } from '../../../../../shared/services/data-check.service';
import { LoadingSpinnerService } from '../../../../../shared/services/loading-spinner.service';
import { ToasterService } from '../../../../../shared/services/toaster.service';
import { ProfileService } from '../../../../profile/profile.service';
import { ClientsService } from '../../../clients/clients.service';
import { JobsService } from '../../jobs.service';

@Component({
  selector: 'gtapp-response',
  templateUrl: './response.component.html',
  styleUrl: './response.component.scss',
})
export class ResponseComponent implements OnInit {
  @Input() selectedStatus: any;
  @ViewChild('licenceCheckTemplateRef') licenceCheckTemplateRef: any;
  responseFormData = new UntypedFormGroup({
    clientName: new UntypedFormControl('', Validators.required),
    siteName: new UntypedFormControl('', Validators.required),
    siteContactName: new UntypedFormControl(''),
    monitoringCompanyName: new UntypedFormControl(''),
    responseTypeName: new UntypedFormControl(''),
    assigneeName: new UntypedFormControl(),
    requestDetail: new UntypedFormControl(),
    externalJobId: new UntypedFormControl(),
  });

  isAdmin: boolean = false;
  showSiteContactList: boolean = false;
  isDispatchUser: boolean = false;
  siteLicenceReminderCheck: boolean = false;
  licenseReminderField: boolean = false;
  showSiteMap: boolean = false;
  addJobPressed: boolean = false;
  selectedClient: any;
  responseJobType: any = {
    jobType: {
      name: 'job Type',
      value: '',
      edit: false,
      data: null,
      tempValue: '',
    },
    client: {
      name: 'Client',
      value: '',
      edit: false,
      data: null,
      tempValue: '',
    },
    site: { name: 'Site', value: '', edit: false, data: null, tempValue: '' },
    assignee: {
      name: 'Assignee',
      value: '',
      edit: false,
      data: null,
      tempValue: '',
    },
    ai: {
      name: 'Additional Information',
      value: '',
      edit: false,
      data: null,
      tempValue: '',
    },
    eI: {
      name: 'External Job Id',
      value: '',
      edit: false,
      data: null,
      tempValue: '',
    },
    oc: {
      name: 'On Site Contact',
      value: '',
      edit: false,
      data: null,
      tempValue: '',
    },
    rt: {
      name: 'Response Type',
      value: '',
      edit: false,
      data: null,
      tempValue: '',
    },
    mc: {
      name: 'Monitoring Company',
      value: '',
      edit: false,
      data: null,
      tempValue: '',
    },
  };

  clientList: any = [];
  siteList: any = [];
  siteContacts: any = [];
  assigneeList: any = [];

  responseTypeList: any = [];
  monitoringCompanyList: any = [];
  companyLicenseList: any = [];
  dialogRef: any;
  userData: any;
  largeView: Boolean = isLargeScreen;

  dialogData: any;

  initialDataList: any = {};

  selectedSiteExtraDetails: any;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private _location: Location,
    private jobService: JobsService,
    private profileService: ProfileService,
    private dialogService: ModelDialogueService,
    private clientService: ClientsService,
    private appService: AppService,
    private router: Router,
    private dataCheckService: DataCheckService,
    private spinnerService: LoadingSpinnerService,
    private toasterService: ToasterService,
    private cdr: ChangeDetectorRef
  ) {
    this.userData = this.appService.getUserData();
    this.isAdmin = this.dataCheckService.isUserAdmin();
    this.isDispatchUser = this.dataCheckService.isDispatchUser();
    this.siteLicenceReminderCheck = this.userData?.checks?.license_reminder;

    router.events.subscribe((event: any) => {
      // close dialog boxes if any are open when back is pressed
      if (event.navigationTrigger === 'popstate') {
        this.dialogRef?.close();
      }
    });
  }

  ngOnInit(): void {
    this.responseJobType.jobType.value = this.selectedStatus?.name;
    this.responseJobType.jobType.data = this.selectedStatus;
    this.getMonitoringCompanyList();
    this.getResponseTypes();
    this.shiftFocus('siteId');
    this.getAssignees();
    this.onSiteSearch('');

    let fromSiteInfo = JSON.parse(sessionStorage.getItem('fromSite') || '{}');
    if (Object.keys(fromSiteInfo).length) {
      const siteData = {
        company_name: fromSiteInfo?.site?.company_name,
        id: fromSiteInfo?.site?.id,
        company: {
          company_name: fromSiteInfo?.client?.company_name,
          id: fromSiteInfo?.client?.id,
        },
      };
      this.onSelectSite(siteData);

      sessionStorage.removeItem('fromSite');
    }
    if (this.siteLicenceReminderCheck && this.isAdmin) {
      this.getCompanyLicenses();
    }
  }

  onSiteSearch(event?: any) {
    const searchString = event ? event.target.value : '';
    this.responseJobType.site.tempValue = searchString;

    this.clientService
      .searchSites({
        search_str: searchString,
      })
      .subscribe((res: any) => {
        if (res['status'] == 'success') {
          this.siteList = res?.data?.splice(0, 10);

          if (!event) {
            this.initialDataList.site = JSON.parse(
              JSON.stringify(this.siteList)
            );
          }
        }
      });
  }
  doSiteLicenseCheck() {
    if (
      !this.companyLicenseList.some(
        (i: any) => i === this.responseJobType.site?.data?.address?.state_code
      )
    ) {
      this.dialogService.open(
        this.licenceCheckTemplateRef,
        {
          data: {},
        },
        this.viewContainerRef
      );
    } else {
      this.shiftFocus('mcId');
    }
  }
  ignoreLicenseCheck() {
    this.siteLicenceReminderCheck = false;
    this.shiftFocus('assignee');
    if (this.licenseReminderField === true) {
      let params = { stop_notification: 1 };
      this.clientService
        .updateCompanyDetails({}, params)
        .then((response: any) => {
          this.toasterService.setMessage({
            errorMessage: response['message'],
            successMessage: '',
          });
          this.userData['checks']['license_reminder'] = false;
          this.appService.setUserData(this.userData);
        });
    }
  }
  editField(field: string) {
    if (field == 'site') {
      this.responseJobType.site = {
        name: 'Site',
        value: '',
        edit: false,
        data: null,
        tempValue: '',
      };
      this.responseJobType.client = {
        name: 'Client',
        value: '',
        edit: false,
        data: null,
        tempValue: '',
      };
      this.responseJobType.oc = {
        name: 'On Site Contact',
        value: '',
        edit: false,
        data: null,
        tempValue: '',
      };
      this.responseJobType.assignee = {
        name: 'Assignee',
        value: '',
        edit: false,
        data: null,
        tempValue: '',
      };
      this.responseJobType.mc = {
        name: 'Monitoring Company',
        value: '',
        edit: false,
        data: null,
        tempValue: '',
      };
      this.responseFormData.controls['monitoringCompanyName'].setValue(null);
      this.responseFormData.controls['assigneeName'].setValue(null);
      this.responseFormData.controls['clientName'].setValue(null);
      this.responseFormData.controls['siteName'].setValue(null);
      this.responseFormData.controls['siteContactName'].setValue(null);
      this.shiftFocus('siteId');
    }
    if (field == 'assignee') {
      this.responseJobType.assignee = {
        name: 'Assignee',
        value: '',
        edit: false,
        data: null,
        tempValue: '',
      };
      this.responseFormData.controls['assigneeName'].setValue(null);

      this.shiftFocus('assigneeId');
    }
    if (field == 'rt') {
      this.responseJobType.rt = {
        name: 'Response Type',
        value: '',
        edit: false,
        data: null,
        tempValue: '',
      };
      this.responseFormData.controls['responseTypeName'].setValue(null);
      this.shiftFocus('rtId');
    }
    if (field == 'mc') {
      this.responseJobType.mc = {
        name: 'Monitoring Company',
        value: '',
        edit: false,
        data: null,
        tempValue: '',
      };
      this.responseFormData.controls['monitoringCompanyName'].setValue(null);
      this.shiftFocus('mcId');
    }
    if (field == 'oc') {
      this.responseJobType.oc = {
        name: 'On Site Contact',
        value: '',
        edit: false,
        data: null,
        tempValue: '',
      };
      this.responseFormData.controls['siteContactName'].setValue(null);
      this.shiftFocus('ocId');
    }
    this.updateInitialDataList(field);
  }
  updateInitialDataList(field: any) {
    setTimeout(() => {
      if (field === 'assignee') {
        this.assigneeList = JSON.parse(
          JSON.stringify(this.initialDataList?.[field] || [])
        );
        this.assigneeList?.forEach((guard: any) => {
          guard.validStateLicense = this.validLicenseCheck(guard?.license);
        });
      } else if (field === 'site') {
        this.siteList = JSON.parse(
          JSON.stringify(this.initialDataList?.[field] || [])
        );
      } else if (field === 'rt') {
        this.responseTypeList = JSON.parse(
          JSON.stringify(this.initialDataList?.[field] || [])
        );
      } else if (field === 'mc') {
        this.monitoringCompanyList = JSON.parse(
          JSON.stringify(this.initialDataList?.[field] || [])
        );
      }

      this.cdr.detectChanges();
    }, 200);
  }

  async onSelectSite(data: any) {
    if (data?.key === 0) {
      this.openAddSiteForm();
    }

    if (data?.id) {
      this.responseJobType.oc = {
        name: 'On Site Contact',
        value: '',
        edit: false,
        data: null,
        tempValue: '',
      };
      this.responseFormData.controls['siteContactName'].setValue(null);
      this.showSiteMap = true;
      this.responseJobType.site.value = data?.company_name;
      this.responseJobType.site.data = data;
      this.responseFormData.controls['siteName'].setValue(data?.company_name);
      if (data?.company) {
        this.responseJobType.client.value = data?.company?.company_name;
        this.responseJobType.client.data = data?.company;
        this.responseFormData.controls['clientName'].setValue(
          data?.company_name
        );
      }

      this.getSiteContacts(data?.id);
      this.findSelectedSiteAddInfo(data?.id);
      this.updateInitialDataList('assignee');

      if (this.siteLicenceReminderCheck && this.isAdmin == true) {
        this.doSiteLicenseCheck();
      }
    }
  }
  findSelectedSiteAddInfo(siteId: string) {
    this.selectedSiteExtraDetails = {};
    this.spinnerService.show();
    this.jobService
      .fetchJobDetails(
        { site_id: siteId },
        { additional_site_info: 1, last_mc_for_site: 1 }
      )
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.selectedSiteExtraDetails = response?.data;
          if (this.selectedSiteExtraDetails?.mc_data?.id) {
            this.onMonitoringSelect(this.selectedSiteExtraDetails?.mc_data);
          } else {
            this.shiftFocus('mcId');
          }
        }
        this.spinnerService.hide();
      });
  }
  openAddSiteForm() {
    const dialogRef = this.dialogService.open(AddEditSiteComponent, {
      data: {
        detailData: { company_name: this.responseJobType.site.tempValue },
      },
    });
    dialogRef.afterClosed().subscribe((data: any) => {
      if (data !== 'close') {
        this.onSelectSite(data);
      } else {
        this.editField('site');
      }
    });
  }
  getSiteContacts(id: any) {
    this.clientService.getSiteContacts(id).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.siteContacts = response['data'];
      } else {
        this.toasterService.setMessage({
          errorMessage: 'ERROR MESSAGE',
          successMessage: '',
        });
      }
    });
  }
  selectContact(contact: any) {
    if (contact.key == 0) {
      this.addNewContactForm();
    }
    if (contact?.id) {
      this.responseJobType.oc.value = contact?.full_name;
      this.responseJobType.oc.data = contact;
      this.responseFormData.controls['siteContactName'].setValue(
        contact?.full_name
      );
    }
  }
  addNewContactForm() {
    if (this.responseJobType.site.data) {
      let siteData: any = this.responseJobType.site.data;
      siteData['contacts'] = this.siteContacts;
      this.dialogRef = this.dialogService.open(ContactComponent, {
        data: {
          siteData: siteData,
          addNewSiteContact: true,
          contactType: 'lc',
        },
      });
      this.dialogRef.afterClosed().subscribe((value: any) => {
        if (value && value?.length) {
          this.siteContacts = value;

          let lastAddedContact =
            this.siteContacts[this.siteContacts?.length - 1];
          if (lastAddedContact) this.selectContact(lastAddedContact);
        }
      });
    }
  }
  validLicenseCheck(guardLicenses: any) {
    return guardLicenses.some(
      (license: any) =>
        license.issuer_state ===
        this.responseJobType.site?.data?.address?.state_code
    );
  }

  onAssigneeSearch(event: any) {
    if (event.target.value?.length > 0) {
      this.getAssignees({ full_name: event?.target?.value });
    }
  }

  getAssignees(params?: any) {
    this.jobService.getAssignees(params).subscribe(async (response: any) => {
      if (response['status'] == 'success') {
        if (!params) {
          this.initialDataList.assignee = JSON.parse(
            JSON.stringify(response?.data)
          );
        }
        this.assigneeList = [...response?.data];
        this.assigneeList?.forEach((guard: any) => {
          guard.validStateLicense = this.validLicenseCheck(guard?.license);
        });
        this.cdr.detectChanges();
      }
    });
  }
  assigneeSelected(assignee: any) {
    this.responseJobType.assignee.value = assignee?.full_name;
    this.responseJobType.assignee.data = assignee;
    this.responseFormData.controls['assigneeName'].setValue(
      assignee?.full_name
    );
    this.shiftFocus('rtId');
  }
  onAssigneeSelect(assignee: any) {
    if (assignee?.id) {
      this.responseFormData.controls['assigneeName'].setValue(
        assignee?.full_name
      );
      if (assignee?.validStateLicense) {
        this.assigneeSelected(assignee);
      } else {
        let dialogMsg;
        if (this.userData.profile.email === assignee.email) {
          dialogMsg =
            'You have not added a security licence for this location in your profile, are you sure you want to take this job?';
        } else {
          dialogMsg = `${
            assignee.full_name || assignee.email
          } does not hold a security licence for this location, assign to ${
            assignee.full_name || assignee.email
          } anyway?`;
        }

        this.dialogData = { title: '', message: dialogMsg };
        this.dialogRef = this.dialogService.open(ConfirmDialogComponent, {
          data: {
            title: 'Job Assignment',
            message: dialogMsg,
          },
        });
        this.dialogRef.afterClosed().subscribe((value: any) => {
          if (value === true) {
            this.assigneeSelected(assignee);
          } else {
            this.editField('assignee');
          }
        });
      }
    }
  }
  onAddJob() {
    this.addJobPressed = true;
    this.spinnerService.show();
    let data = {
      job_type_id: this.selectedStatus.id,
      company_id: this.responseJobType?.client?.data?.id,
      location: [this.responseJobType?.site?.data?.id],
      is_abandoned: false,
      request: this.responseFormData.value.requestDetail,
      new_assignee: this.responseJobType?.assignee?.data?.id,
      location_contact_id: this.responseJobType?.oc?.data?.id,
      monitoring_company_id: this.responseJobType?.mc?.data?.id,
      response_type_id: this.responseJobType?.rt?.data?.id,
      external_job_id: this.responseFormData.value.externalJobId,
    };
    this.jobService.createJob(data).then((res: any) => {
      if (res['status'] == 'success') {
        this._location.back();
      } else {
        this.addJobPressed = false;
        this.toasterService.setMessage({
          errorMessage: res['message'],
          successMessage: '',
        });
        this.spinnerService.hide();
      }
    });
  }

  shiftFocus(elementId: string) {
    setTimeout(() => {
      var element = <HTMLInputElement>document.getElementById(elementId);
      element?.focus();
    });
  }

  openTemplate(template: any) {
    this.dialogRef = this.dialogService.open(
      template,
      { data: {} },
      this.viewContainerRef
    );
  }
  getCompanyLicenses() {
    let params = { license_check: 1 };
    this.profileService
      .fetchCompanyDetails(params)
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.userData['checks']['license_reminder'] =
            response?.data?.license_reminder;
          this.siteLicenceReminderCheck = response?.data?.license_reminder;
          this.appService.setUserData(this.userData);
          this.companyLicenseList = response?.data?.license.map(
            (i: any) => i?.issuer_state
          );
        }
      });
  }
  showLicensePopup() {
    this.dialogRef = this.dialogService.open(AddEditLicenseDetailsComponent, {
      data: {
        licenseState: this.responseJobType?.site?.data?.address?.state_code,
        companyLicense: true,
      },
    });
    this.dialogRef.afterClosed().subscribe((value: any) => {
      if (value != 'close') {
        this.companyLicenseList = value;
      }
      this.shiftFocus('mcId');
    });
  }
  onRtSearch(event: any) {
    this.responseJobType.rt.tempValue = event.target.value;

    if (event.target.value.length) {
      this.responseTypeList = this.initialDataList?.rt?.filter((type: any) =>
        type?.name?.toLowerCase()?.includes(event.target.value?.toLowerCase())
      );
    } else {
      this.responseTypeList = this.initialDataList?.rt || [];
    }
  }

  getResponseTypes(params?: any) {
    this.clientService.fetchResponseTypeList(params).subscribe((res: any) => {
      if (res['status'] == 'success') {
        this.responseTypeList = res['data'];

        this.initialDataList.rt = JSON.parse(JSON.stringify(res?.data));
      }
    });
  }
  onMonitoringSelect(company: any) {
    if (company.key === 0) {
      this.openAddMonitoringCompany();
    }
    if (company?.id) {
      this.responseFormData.controls['monitoringCompanyName'].setValue(
        company?.company_name
      );

      this.responseJobType.mc.value = company?.company_name;
      this.responseJobType.mc.data = company;
      this.shiftFocus('assigneeId');
    }
  }
  openAddMonitoringCompany() {
    if (this.responseJobType.mc.tempValue?.length > 0) {
      this.spinnerService.show();
      this.clientService
        .createMonitoringCompany({
          company_name: String(this.responseJobType.mc.tempValue),
        })
        .then((response: any) => {
          if (response['status'] == 'success') {
            this.spinnerService.hide();
            this.onMonitoringSelect(response?.data);
            this.toasterService.setMessage({
              errorMessage: '',
              successMessage: response['message'],
            });
          } else {
            this.spinnerService.hide();
            this.toasterService.setMessage({
              errorMessage: response['message'],
              successMessage: '',
            });
          }
        });
    } else {
      this.toasterService.setMessage({
        errorMessage: "Monitoring Company value can't be empty",
        successMessage: '',
      });
    }
  }
  onResponseTypeSelect(responseType: any) {
    if (responseType.key === 0) {
      this.openAddResponseType();
    }
    if (responseType?.id) {
      this.responseFormData.controls['responseTypeName'].setValue(
        responseType?.name
      );
      this.responseJobType.rt.value = responseType?.name;
      this.responseJobType.rt.data = responseType;
    }
  }
  openAddResponseType() {
    if (this.responseJobType.rt.tempValue?.length > 0) {
      this.spinnerService.show();
      this.clientService
        .createResponseType({ name: String(this.responseJobType.rt.tempValue) })
        .then((response: any) => {
          if (response['status'] == 'success') {
            this.spinnerService.hide();
            this.onResponseTypeSelect(response?.data);
            this.getResponseTypes();
            this.toasterService.setMessage({
              errorMessage: '',
              successMessage: response['message'],
            });
          } else {
            this.spinnerService.hide();
            this.toasterService.setMessage({
              errorMessage: response['message'],
              successMessage: '',
            });
          }
        });
    } else {
      this.toasterService.setMessage({
        errorMessage: "Response Type value can't be empty",
        successMessage: '',
      });
    }
  }
  onMcSearch(event: any) {
    // in case add client auto fill the form
    this.responseJobType.mc.tempValue = event.target.value;
    if (event?.target?.value.length > 0) {
      this.getMonitoringCompanyList({ search_str: event?.target?.value });
    }
    this.responseJobType.mc.value = null;
    this.responseJobType.mc.data = null;
  }
  getMonitoringCompanyList(params?: any) {
    this.clientService
      .fetchMonitoringCompanyList(params)
      .subscribe((res: any) => {
        if (res['status'] == 'success') {
          this.monitoringCompanyList = res['data'];
          if (!params) {
            this.initialDataList.mc = JSON.parse(JSON.stringify(res?.data));
          }
        }
      });
  }
  addAI() {
    this.responseFormData.controls['requestDetail'].setValue(
      this.responseJobType.ai.value
    );
  }
}
