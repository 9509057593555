<div
  class="d-flex flex-column flex-md-row input-btn-group mb-lg-3"
  *ngIf="largeView || !selectedEvent"
>
  <div class="mb-2 mb-lg-0 col-lg-2">
    <button
      class="btn btn-primary btn-lg w-100"
      (click)="openAddFeedbackForm(feedbackRef)"
    >
      + New
    </button>
  </div>
  <div class="ms-md-2 mb-2 mb-lg-0 col-lg-2" *ngIf="initialCount !== fullCount">
    <button class="btn btn-secondary btn-lg w-100" (click)="showAllFeedbacks()">
      {{ showDefaultFeedbacks ? "Show All" : "Reset" }}
    </button>
  </div>
  <div
    class="ms-md-2 flex-md-grow-1 mb-2 mb-md-0"
    *ngIf="!showDefaultFeedbacks && fullCount > 5"
  >
    <gtapp-auto-complete
      [data]="feedbackDropDownList"
      placeHolder="Search"
      searchKeyword="company_name"
      [itemTemplate]="feedbcakTemplate"
      (inputChanged)="onFeedBackLookup($event)"
      (selected)="onRowClick($event)"
      (submitted)="globalFilter = $event; getFeedbacks()"
    >
    </gtapp-auto-complete>

    <ng-template #feedbcakTemplate let-item>
      <div class="small">{{ item?.feedback }}</div>
    </ng-template>
  </div>
</div>

<div
  class="row row-cols-1"
  [ngClass]="selectedEvent ? 'row-cols-lg-2' : 'row-cols-lg-1'"
>
  <div class="col">
    <gtapp-gt-table
      [desktopTableBackendData]="
        selectedEvent && !largeView ? [selectedEvent] : feedbacks
      "
      [desktopTableDetails]="deskTopTableDetail"
      [otherParams]="selectedEvent && !largeView ? {} : otherParams"
      (sortColumn)="sortColumn($event)"
      (paginationChange)="onChangePagination($event)"
      (rowClick)="onRowClick($event)"
    ></gtapp-gt-table>
  </div>

  <div class="col mt-lg-4 details-card" *ngIf="selectedEvent">
    <div class="text-end mb-2">
      <button (click)="openTemplate(deleteRef)" class="btn btn-sm btn-danger">
        Delete Inquiry
      </button>
    </div>
    <dl>
      <dt class="small-font">Description</dt>
      <dd class="gt-multi-line">
        {{
          selectedEvent?.feedback === "null" ||
          selectedEvent?.feedback === "undefined"
            ? ""
            : selectedEvent?.feedback
        }}
      </dd>
    </dl>
    <!-- Comment section -->
    <div *ngIf="selectedEvent?.updates?.length > 0" class="overflow-45vh mb-4">
      <div *ngFor="let update of selectedEvent?.updates">
        <dl>
          <dt class="small-font">
            {{ update?.updated_at | customDate : "d MMM y HH:mm" }} |
            {{
              update?.updated_by?.is_superuser
                ? "Support Team"
                : update?.updated_by?.full_name
            }}
          </dt>
          <dd *ngIf="update?.event_action?.key === 1" class="gt-multi-line">
            {{
              update?.description === "null" ||
              update?.description === "undefined"
                ? ""
                : update?.description
            }}
          </dd>
          <dd *ngIf="update?.event_action?.key === 0">
            STATUS:
            {{ update?.status?.value }}
          </dd>
        </dl>
      </div>
    </div>
    <div class="row-cols-1 mb-2" *ngIf="selectedEvent?.status != 'COMPLETED'">
      <div class="col">
        <textarea
          rows="3"
          class="form-control"
          type="text"
          placeholder="Add comment"
          [(ngModel)]="newComment"
        ></textarea>
      </div>
    </div>
    <div class="row row-cols-2">
      <div class="col">
        <button
          (click)="hideDetails()"
          class="btn btn-lg btn-dark btn-lg w-100"
        >
          Cancel
        </button>
      </div>
      <div class="col">
        <button
          (click)="addComment()"
          class="btn btn-lg btn-primary btn-lg w-100"
          [disabled]="!newComment"
        >
          Update
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Delete feedback dialog -->
<ng-template #deleteRef let-deleteRef="dialogRef">
  <div class="card dialog-box">
    <div class="card-header">
      Delete Inquiry
      <span class="float-end" (click)="deleteRef.close()"
        ><i class="fa-solid fa-xmark"></i
      ></span>
    </div>
    <div class="form-floating">
      <input
        class="form-control"
        type="text"
        [(ngModel)]="deleteReason"
        placeholder="Reason (Optional)"
      />
      <label>Reason (Optional)</label>
    </div>
    <div class="row row-cols-2 mt-4">
      <div class="col">
        <button class="btn btn-dark btn-lg w-100" (click)="deleteRef.close()">
          Cancel
        </button>
      </div>
      <div class="col">
        <button class="btn btn-primary btn-lg w-100" (click)="deleteFeedBack()">
          Confirm
        </button>
      </div>
    </div>
  </div>
</ng-template>
<!-- ADD FEEDBACK POPOVER / DIALOG -->
<ng-template #feedbackRef let-feedbackRef="dialogRef">
  <div class="card dialog-box">
    <div class="card-header">
      Contact Form

      <span
        class="float-end"
        (click)="
          feedbackRef.close('close'); showHideDraggableFeedbackBtn(false)
        "
      >
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>
    <div class="p-1">
      <form [formGroup]="feedbackForm" class="row-cols-1 gy-2">
        <div class="col">
          <textarea
            class="form-control"
            cols="32"
            rows="5"
            wrap="hard"
            maxlength="340"
            placeholder="Need help or have feedback? Please tell us how we can help."
            formControlName="feedback"
          ></textarea>
        </div>
      </form>
    </div>
    <div class="row row-cols-2 mt-4">
      <div class="col">
        <button
          class="btn btn-dark btn-lg w-100"
          (click)="
            feedbackRef.close('close'); showHideDraggableFeedbackBtn(false)
          "
        >
          Cancel
        </button>
      </div>

      <div class="col">
        <button
          (click)="
            addFeedback();
            feedbackRef.close();
            showHideDraggableFeedbackBtn(false)
          "
          class="btn btn-primary btn-lg w-100"
          [disabled]="!feedbackForm.valid"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</ng-template>
