import { OverlayRef } from '@angular/cdk/overlay';
import { Observable, Subject } from 'rxjs';

export class DialogRef {
  private afterClosedSubject = new Subject<any>();

  constructor(private overlayRef: OverlayRef) {}

  public close(result?: any) {
    this.overlayRef.dispose();
    this.afterClosedSubject.next(result);
    this.afterClosedSubject.complete();

    if (!document.querySelector('.dialog-box')) {
      // if condition to avoid nested dialoges
      // adding scroll back by removing the class when the dialog box is closed..
      document.documentElement.style.overflow = '';
      // document.documentElement.classList.remove('cdk-global-scrollblock');
      // removing scroll on background causes the html page to go to top even if it was opened after scrolling down
      // storing the postion to scroll down when the dialog is closed
      // const scrollPosition: any = JSON.parse(
      //   sessionStorage.getItem(btoa('scrollPosition')) || '{}'
      // );
      // sessionStorage.removeItem(btoa('scrollPosition'));
      // if (scrollPosition?.left >= 0 && scrollPosition?.top >= 0)
      //   window.scrollTo(scrollPosition.left, scrollPosition.top);
    }
  }

  public afterClosed(): Observable<any> {
    return this.afterClosedSubject.asObservable();
  }
}
