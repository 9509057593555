<!-- Data Refreshing Alert -->
<ng-container *ngIf="apidataUpdation === true && !offlineMode">
  <div class="alert alert-primary mb-3">
    <div class="fw-bold">
      Fetching Data from Server

      <div class="spinner-grow float-end" role="status"></div>
    </div>
  </div>
</ng-container>

<!-- Job Data Not Found Alert -->
<ng-container *ngIf="!detailData && offlineMode && !apidataUpdation">
  <div class="alert alert-warning mb-2">
    <div>Job Data Not Found</div>
  </div>
</ng-container>
<div *ngIf="detailData?.id">
  <!-- Cancellation Reason -->

  <div class="alert alert-danger alert-dismissible fade show" role="alert"
    *ngIf="cancelReasonData && detailData?.status?.status_identity?.value === 2">
    <div>
      <div>
        CANCELLED:
        <span class="fw-bold">
          {{ detailData?.modified_date | date : "d MMM yyyy H:mm" }}
        </span>
      </div>
    </div>
    <div *ngIf="cancelReasonData?.reason">
      <div>
        CANCEL REASON:

        <span class="fw-bold">{{ cancelReasonData?.reason }}</span>
      </div>
    </div>
  </div>

  <div>
    <!-- <div
      *ngIf="!largeView"
      class="job-status"
      [style]="selectedJobStatus?.additional_info?.style?.snubbed"
    ></div> -->
    <!-- Job Details and Comments -->
    <div class="text-end mb-2">
      <!--job reviewed and approved status-->
      <button (click)="resendJobReport()" class="btn btn-success btn-sm" *ngIf="
          detailData?.status?.status_identity?.value === 10 &&
          (isAdmin || isDispatchUser)
        ">
        Resend Report
      </button>
      <!--job under review status-->
    </div>

    <div [style.padding-bottom]="bottomSectionFixedHeight + ' !important'" id="jobDetail">
      <div class="row row-cols-1 row-cols-lg-3">
        <div class="col details-card">
          <div>
            <dl id="jobType">
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <dt>Job Type</dt>
                  <dd>
                    {{ detailData?.service_type?.name }}
                    <span *ngIf="detailData?.response_type?.name">&nbsp;-&nbsp;
                      <span class="fw-bold">{{
                        detailData?.response_type?.name
                        }}</span>
                    </span>
                    <span *ngIf="detailData?.job_service_type?.name">&nbsp;-&nbsp;
                      <span class="fw-bold">{{
                        detailData?.job_service_type?.name
                        }}</span>
                    </span>
                  </dd>
                </div>
                <div>
                  <ng-container *ngIf="
                      [0, 1].includes(
                        detailData?.service_type?.type_identity
                      ) &&
                      !offlineMode &&
                      ![1, 2, 3, 9, 10, 11, 12, 13].includes(
                        detailData?.status?.status_identity?.value
                      ) &&
                      (isAdmin || isDispatchUser)
                    ">
                    <span (click)="
                        detailData?.service_type?.type_identity === 0
                          ? openUpdateResponseTypeTemplate(
                              responseTypeTemplateRef
                            )
                          : openUpdateServiceTypeTemplate(
                              serviceTypeTemplateRef
                            )
                      " class="me-1">
                      <i class="fa-solid fa-arrow-up-right-from-square md-font-size text-secondary"></i>
                    </span>
                  </ng-container>
                </div>
              </div>
            </dl>
          </div>
          <ng-container *ngIf="detailData?.roster_scheduler?.repeat_type">
            <div class="row row-cols-2" id="shiftInfo">
              <div class="col">
                <dl class="h-100">
                  <!-- Date/Duration Section -->
                  <dt>Start Date</dt>
                  <dd>
                    {{
                    detailData?.schedule_event_date
                    ? (detailData?.schedule_event_date | date : "d MMM y")
                    : (detailData?.roster_scheduler?.start_day
                    | date : "d MMM y")
                    }}
                  </dd>
                </dl>
              </div>
              <div class="col">
                <dl class="h-100">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1">
                      <dt>Shift [Start - End]</dt>
                      <dd>
                        {{ detailData?.roster_scheduler?.start_time }} -
                        {{ detailData?.roster_scheduler?.end_time }}
                      </dd>
                    </div>
                    <div>
                      <span (click)="
                          openScheduleTemplate(changeScheduleTemplateRef)
                        " class="me-1" *ngIf="
                          canScheduleBeUpdated &&
                          (isAdmin || isDispatchUser) &&
                          !offlineMode &&
                          ![1, 2, 3, 9, 10, 11, 12, 13].includes(
                            detailData?.status?.status_identity?.value
                          )
                        ">
                        <i class="fa-solid fa-pen-to-square md-font-size text-secondary"></i>
                      </span>
                    </div>
                  </div>
                </dl>
              </div>
              <div class="col">
                <dl>
                  <!-- Job Duration Section -->
                  <dt>Job Duration</dt>
                  <dd>{{ scheduleDuration }} Hours</dd>
                </dl>
              </div>
              <div class="col">
                <dl *ngIf="
                    showWelfareItem &&
                    (isAdmin ||
                      isDispatchUser ||
                      detailData?.interval_check > 0)
                  ">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1">
                      <dt>Welfare Checks</dt>
                      <dd>{{ detailData?.interval_check }} mins</dd>
                    </div>
                    <div>
                      <span (click)="
                          openIntervalCheckTemplate(welfareIntervalTemplate)
                        " class="me-1" *ngIf="
                          (isAdmin || isDispatchUser) &&
                          intervalData?.length &&
                          !offlineMode &&
                          ![1, 2, 3, 9, 10, 11, 12, 13].includes(
                            detailData?.status?.status_identity?.value
                          )
                        ">
                        <i class="fa-solid fa-pen-to-square md-font-size text-secondary"></i>
                      </span>
                    </div>
                  </div>
                  <!-- Welfare Checks Section -->
                </dl>
              </div>
            </div>
          </ng-container>
          <div>
            <dl id="jobSiteInfo">
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <dt>Site</dt>
                  <dd>
                    {{ detailData?.sites[0]?.company_name }}
                    <br />
                    <span class="small">{{
                      detailData?.company?.company_name
                      }}</span>
                  </dd>
                </div>
                <div id="jobSiteInfoLink">
                  <span (click)="redirect('site')" class="me-1" *ngIf="!offlineMode">
                    <i class="fa-solid fa-link md-font-size text-secondary"></i>
                  </span>
                </div>
              </div>
            </dl>
          </div>
          <div>
            <dl>
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <dt>Address</dt>
                  <dd>
                    {{ detailData?.sites[0]?.address?.full_address }}
                  </dd>
                </div>
                <div id="jobAddressLink">
                  <a [href]="
                      'https://www.google.com/maps/search/?api=1&query=' +
                      detailData?.sites[0]?.address?.latitude +
                      ',' +
                      detailData?.sites[0]?.address?.longitude
                    " target="_blank">
                    <span class="me-1">
                      <i class="fa-solid fa-route md-font-size"></i>
                    </span>
                  </a>
                </div>
              </div>
            </dl>
          </div>
          <div>
            <dl *ngIf="detailData?.sites[0]?.site_information">
              <!--site information-->

              <dt>Site Information</dt>
              <dd class="gt-multi-line">
                {{
                detailData?.sites[0]?.site_information
                ? detailData?.sites[0]?.site_information
                : "No details provided"
                }}
              </dd>
            </dl>
          </div>
          <div>
            <dl *ngIf="detailData?.sites[0]?.site_files?.length">
              <dt>Site Files</dt>
              <dd class="row p-1 pt-3">
                <div *ngFor="
                    let file of detailData?.sites[0]?.site_files;
                    let i = index
                  " class="col-12 mb-2 d-flex align-items-center cursor-pointer">
                  <span (click)="onOpenFile(file)">
                    <span class="badge bg-primary me-2">{{
                      file?.extension
                      }}</span>
                    <span> {{ file?.description }}</span>
                  </span>
                </div>
              </dd>
            </dl>
          </div>
          <div>
            <dl *ngIf="detailData?.sites[0]?.formatted_site_keys?.length">
              <dt>Site Keys</dt>
              <dd class="row p-1">
                <div *ngFor="
                    let siteKey of detailData?.sites[0]?.formatted_site_keys
                  " class="col-12 d-flex align-items-center">
                  <i class="fa-solid fa-key text-warning"></i>
                  <span class="ms-2">
                    {{ siteKey?.value ? siteKey?.value : siteKey }}
                    <span *ngIf="siteKey?.description">[{{ siteKey?.description }}]</span>
                  </span>
                </div>
              </dd>
            </dl>
          </div>

          <ng-container *ngIf="
              (detailData?.monitoring_company?.id ||
                isAdmin === true ||
                isDispatchUser === true) &&
              detailData?.service_type?.type_identity === 0
            ">
            <div>
              <dl>
                <div class="d-flex align-items-center">
                  <div class="flex-grow-1">
                    <dt>Monitoring Company</dt>
                    <dd>
                      {{
                      detailData?.monitoring_company?.company_name
                      ? detailData?.monitoring_company?.company_name
                      : "N/A"
                      }}
                    </dd>
                  </div>
                  <div>
                    <ng-container *ngIf="detailData?.monitoring_company?.id">
                      <span (click)="redirect('mc')" class="me-1" *ngIf="!offlineMode">
                        <i class="fa-solid fa-link md-font-size text-secondary"></i>
                      </span>
                    </ng-container>
                    <ng-container *ngIf="
                        !offlineMode &&
                        ![1, 2, 3, 9, 10, 11, 12, 13].includes(
                          detailData?.status?.status_identity?.value
                        ) &&
                        (isAdmin || isDispatchUser)
                      ">
                      <span (click)="modifyMonitoringCompany(modifyMCTemplate)" class="me-1">
                        <i class="fa-solid fa-arrow-up-right-from-square md-font-size text-secondary ms-3"></i>
                      </span>
                    </ng-container>
                  </div>
                </div>
              </dl>
            </div>
          </ng-container>
        </div>
        <div class="col details-card mt-0 mt-lg-2">
          <div>
            <dl>
              <dt>Job ID</dt>
              <dd>{{ detailData?.job_key }}</dd>
            </dl>
          </div>
          <div>
            <dl *ngIf="
                detailData?.service_type?.type_identity === 0 &&
                (isAdmin || isDispatchUser || detailData?.ext_job_id)
              ">
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <dt>External Job ID</dt>
                  <dd>
                    {{
                    detailData?.ext_job_id ? detailData?.ext_job_id : "N/A"
                    }}
                  </dd>
                </div>
                <div>
                  <span (click)="modifyExternalJobId(modifyExternalJobIdTemplate)" class="me-1" *ngIf="
                      (isAdmin || isDispatchUser) &&
                      !offlineMode &&
                      ![1, 2, 3, 10, 11, 12, 13].includes(
                        detailData?.status?.status_identity?.value
                      )
                    ">
                    <i class="fa-solid fa-pen-to-square md-font-size text-secondary"></i>
                  </span>
                </div>
              </div>
            </dl>
          </div>
          <div *ngIf="
              isAdmin || isDispatchUser || detailData?.location_contacts?.length
            ">
            <dl id="jobOnSiteContacts">
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <dt>Onsite Contacts</dt>
                  <dd>
                    {{ detailData?.location_contacts[0]?.full_name }}

                    <div *ngIf="!detailData?.location_contacts?.length" style="color: var(--color-danger-500)">
                      No Contacts Added
                    </div>
                  </dd>
                </div>
                <div class="d-flex align-items-center">
                  <div *ngIf="detailData?.location_contacts?.length > 1" class="me-4">
                    <button class="btn btn-outline-secondary btn-sm"
                      (click)="modifySiteContactTemplate(modifySiteContactRef)">
                      + {{ detailData?.location_contacts?.length - 1 }} more
                    </button>
                  </div>
                  <span (click)="modifySiteContactTemplate(modifySiteContactRef)" class="me-1">
                    <i class="fa-solid fa-arrow-up-right-from-square md-font-size text-secondary"></i>
                  </span>
                </div>
              </div>
            </dl>
          </div>

          <div>
            <dl *ngIf="isAdmin || isDispatchUser">
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <dt>
                    {{
                    detailData?.assignee?.length > 1
                    ? "Assigned Guards"
                    : "Assigned Guard"
                    }}
                  </dt>
                  <dd>
                    {{ detailData?.assignee[0]?.full_name }}

                    <div *ngIf="!detailData?.assignee?.length" style="color: var(--color-danger-500)">
                      Not Assigned
                    </div>
                  </dd>
                </div>
                <div class="d-flex align-items-center">
                  <div *ngIf="detailData?.assignee?.length > 1" class="me-4">
                    <button class="btn btn-outline-secondary btn-sm" (click)="modifyAssignees(modifyAssigneeRef)">
                      + {{ detailData?.assignee?.length - 1 }} more
                    </button>
                  </div>
                  <span (click)="modifyAssignees(modifyAssigneeRef)" class="me-1">
                    <i class="fa-solid fa-arrow-up-right-from-square md-font-size text-secondary"></i>
                  </span>
                </div>
              </div>
            </dl>
          </div>

          <div>
            <dl id="jobAI">
              <!--additionala information-->
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <dt>Additional Information</dt>
                </div>
                <div>
                  <span (click)="modifyRequestDetails(requestRef)" class="me-1" *ngIf="
                      (isAdmin || isDispatchUser) &&
                      !offlineMode &&
                      ![1, 2, 3, 10, 11, 12, 13].includes(
                        detailData?.status?.status_identity?.value
                      )
                    ">
                    <i class="fa-solid fa-pen-to-square md-font-size text-secondary"></i>
                  </span>
                </div>
              </div>
              <dd class="gt-multi-line">
                {{
                detailData?.request_details
                ? detailData?.request_details
                : "No details provided"
                }}
              </dd>
            </dl>
          </div>

          <!--timestamps for the status -->
          @if(statusTimeStamps?.length){
          <ng-container *ngFor="let status of statusTimeStamps">
            <div>
              <dl>
                <!--additional information-->
                <div class="d-flex align-items-center">
                  <div class="flex-grow-1">
                    <dt>
                      {{ status?.detail }}
                      <span *ngIf="
                          status?.history_changes?.length &&
                          (subscriberUserId == status?.subs_user_id ||
                            isAdmin ||
                            isDispatchUser)
                        ">
                        | Updated [{{
                        (
                        status?.history_changes
                        | misc : "lastCommentEditedDateTime"
                        )?.updated_at | customDate : "d MMM y HH:mm"
                        }}]</span>
                    </dt>
                  </div>
                  <div>
                    <span (click)="
                        editTimeStamp(status, statusTimeStampUpdateTemplateRef)
                      " class="me-1" *ngIf="
                        (isAdmin || isDispatchUser) &&
                        !offlineMode &&
                        detailData?.status?.status_identity?.value === 9
                      ">
                      <i class="fa-solid fa-pen-to-square md-font-size text-secondary"></i>
                    </span>
                  </div>
                </div>
                <dd>
                  {{
                  (status?.user_provided_timestamp
                  ? status?.user_provided_timestamp
                  : status?.updated_at
                  ) | customDate : "d MMM y HH:mm"
                  }}
                </dd>
              </dl>
            </div>
          </ng-container>
          }

          <!-- <div>
            <dl
              *ngIf="
                detailData.service_type?.type_identity !== 0 &&
                (isAdmin || isDispatchUser) &&
                ![1, 2, 3,9,10,11,12].includes(detailData?.status?.status_identity?.value)
              "
            >
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <dt>Geofence Alert</dt>
                  <dd>
                    @if( detailData?.additional_info?.geofence_distance> 0){
                    {{
                      detailData?.additional_info?.geofence_distance || 0
                        | number : "1.0-0"
                    }}
                    metres away from site }@else { Not Configured }
                  </dd>
                </div>
                <div>
                  <span
                    (click)="openFenceAlertTemplate(geoFenceAlertDistanceRef)"
                    class="me-1"
                    *ngIf="
                      (isAdmin || isDispatchUser) &&
                      !offlineMode &&
                      ![1, 2, 3,9,10,11,12].includes(
                        detailData?.status?.status_identity?.value
                      )
                    "
                  >
                    <i
                      class="fa-solid fa-pen-to-square md-font-size text-secondary"
                    ></i>
                  </span>
                </div>
              </div>
            </dl>
          </div> -->
        </div>

        <div class="col" id="jobUpdate" *ngIf="
            ![2, 3].includes(detailData?.status?.status_identity?.value) &&
            largeView
          ">
          <ng-template *ngTemplateOutlet="
              jobActionTemplateRef;
              context: {
                templates: {
                  jobCancelTemplate: jobCancelTemplate,
                  onsiteTimeTempalteRef: onsiteTimeTempalteRef,
                  inTransitTemplateRef: inTransitTemplateRef,
                  commentRef: commentRef
                }
              }
            "></ng-template>
        </div>
        <!-- Comments -->

        <ng-container *ngIf="!largeView">
          <div class="d-flex flex-row-reverse" *ngIf="jobCommentsFiles?.length > 1">
            <dl>
              <dt (click)="changeSortOrder = !changeSortOrder">
                <button class="btn btn-sm btn-dark">
                  Sort
                  <span>
                    <i class="fa-solid fa-sort"></i>
                  </span>
                </button>
              </dt>
            </dl>
          </div>
          <div *ngFor="
              let msg of changeSortOrder
                ? jobCommentsFiles
                : jobCommentsFiles.slice().reverse();
              let i = index
            " class="details-card">
            <dl [ngClass]="
                msg?.is_deleted || isItemNotShownInReport(msg)
                  ? 'opacity-50'
                  : ''
              ">
              <div class="d-flex align-items-center">
                <div class="flex-grow-1 small-font">
                  <dt>
                    <!--update details section-->

                    <span *ngIf="msg?.file">
                      Photo
                      {{
                      msg?.event_action === 19
                      ? "[Device Library]"
                      : "[Camera]"
                      }}
                      |
                    </span>

                    {{ msg?.updated_at | customDate : "d MMM y HH:mm" }} |
                    {{
                    subscriberUserId == msg?.subs_user_id
                    ? "You"
                    : msg?.user_name
                    }}
                    <span *ngIf="
                        msg?.history_changes?.length &&
                        (subscriberUserId == msg?.subs_user_id ||
                          isAdmin ||
                          isDispatchUser)
                      ">
                      | Edited [{{
                      (
                      msg?.history_changes
                      | misc : "lastCommentEditedDateTime"
                      )?.updated_at | customDate : "d MMM y HH:mm"
                      }}]</span>
                  </dt>
                </div>
                <div (click)="
                    editEvent(msg, commentRef, statusTimeStampUpdateTemplateRef)
                  " *ngIf="
                    (subscriberUserId == msg?.subs_user_id ||
                      isAdmin ||
                      isDispatchUser) &&
                    ![1, 2, 3, 10, 11, 12, 13].includes(
                      detailData?.status?.status_identity?.value
                    ) &&
                    !offlineMode &&
                    [7, 8, 19].includes(msg?.event_action)
                  " class="mx-2">
                  <span>
                    <i class="fa-solid fa-pen-to-square md-font-size text-secondary"></i>
                  </span>
                </div>
                <div *ngIf="
                    (subscriberUserId == msg?.subs_user_id ||
                      isAdmin ||
                      isDispatchUser) &&
                    ![1, 2, 3, 10, 11, 12, 13].includes(
                      detailData?.status?.status_identity?.value
                    ) &&
                    !offlineMode &&
                    [7, 8, 19].includes(msg?.event_action)
                  " (click)="
                    deleteRestoreUserUpdate({
                      event: msg,
                      type: msg?.is_deleted ? 'restore' : 'delete'
                    })
                  " class="ms-1">
                  @if(msg?.is_deleted){
                  <span>
                    <i class="fa-regular fa-square-plus md-font-size text-secondary"></i>
                  </span>
                  }@else {
                  <span>
                    <i class="fa-regular fa-square-minus md-font-size text-secondary"></i>
                  </span>
                  }
                </div>
                <div class="ms-1">
                  <span data-bs-toggle="tooltip" data-bs-placement="top" *ngIf="msg?.cachedData"
                    title="This update has not yet been uploaded to the server.">
                    <i class="fa-solid fa-triangle-exclamation text-warning md-font-size"></i>
                  </span>
                </div>
              </div>

              <dd class="mb-4">
                <!-- comment / photo -->
                <div *ngIf="msg?.event_action === 20">CHECKED IN</div>
                <div>
                  <span class="gt-multi-line">
                    {{ msg?.event_action === 1 ? "STATUS: " : "" }}
                    {{
                    msg?.detail === "null" || msg?.detail === "undefined"
                    ? ""
                    : msg?.detail
                    }}
                  </span>
                  <span *ngIf="msg?.event_action === 0" class="small-font">[Checkpoint Scan]</span>
                  <span *ngIf="msg?.event_action === 6" class="small-font">[QR Scan]</span>
                  <span *ngIf="
                      msg?.user_provided_timestamp &&
                      (msg?.user_provided_timestamp
                        | customDate : 'd MMM y HH:mm') !=
                        (msg?.updated_at | customDate : 'd MMM y HH:mm')
                    " class="small-font">
                    [{{
                    msg?.user_provided_timestamp
                    | customDate : "d MMM y HH:mm"
                    }}]
                  </span>
                </div>
                <ng-container *ngIf="msg?.file">
                  <ng-container *ngIf="msg?.cachedData">
                    <img (click)="onOpenImage($event, msg)" [src]="getImgSrc(msg?.file)" height="120"
                      width="120" /></ng-container>
                  <ng-container *ngIf="!msg?.cachedData">
                    <img (click)="onOpenImage($event, msg)" src="{{ msg?.file }}" height="120"
                      width="120" /></ng-container>
                </ng-container>

                <div class="small-font text-secondary text-end">
                  <ng-container *ngIf="
                      (msg | misc : 'getEventUpadteFilters' : 'job')?.delete
                        ?.updated_at
                    ">
                    <div>
                      Deleted:
                      {{
                      (msg | misc : "getEventUpadteFilters" : "job")?.delete
                      ?.updated_at | customDate : "d MMM y HH:mm"
                      }}
                      |
                      {{
                      (msg | misc : "getEventUpadteFilters" : "job")?.delete
                      ?.user_name
                      }}
                    </div>
                  </ng-container>
                  <ng-container *ngIf="
                      (msg | misc : 'getEventUpadteFilters' : 'job')?.restore
                        ?.updated_at && !msg?.is_deleted
                    ">
                    Restored:
                    {{
                    (msg | misc : "getEventUpadteFilters" : "job")?.restore
                    ?.updated_at | customDate : "d MMM y HH:mm"
                    }}
                    |
                    {{
                    (msg | misc : "getEventUpadteFilters" : "job")?.restore
                    ?.user_name
                    }}
                  </ng-container>
                </div>
              </dd>
            </dl>
          </div>
        </ng-container>
      </div>

      <div id="bottom-section-fixed" class="bottom-section" *ngIf="scheduleCheck && !largeView">
        <div id="jobUpdate" class="p-2 my-0">
          <ng-template *ngTemplateOutlet="
              jobActionTemplateRef;
              context: {
                templates: {
                  jobCancelTemplate: jobCancelTemplate,
                  onsiteTimeTempalteRef: onsiteTimeTempalteRef,
                  inTransitTemplateRef: inTransitTemplateRef,
                  commentRef: commentRef
                }
              }
            "></ng-template>
        </div>
      </div>
    </div>

    <ng-container *ngIf="largeView">
      <gtapp-gt-table id="jobHistory" [desktopTableUIData]="
          isAdmin || isDispatchUser
            ? jobHistory?.slice()?.reverse()
            : jobCommentsFiles?.slice()?.reverse()
        " [desktopTableDetails]="jobDetailsTableView" [tableActionIcons]="jobDetailsTableActionIcons"
        [otherParams]="otherParams" [tableStyle]="tableStyle" (rowClick)="onTableRowClick($event)"
        (actionClick)="onActionIconClick($event, commentRef)"></gtapp-gt-table>

      <!-- SELECTED DETAILS -->
      <ng-container *ngIf="selectedEvent">
        <gtapp-selected-event-detail [selectedEvent]="selectedEvent" selectedEventFrom="job" [extraCheck]="
            !offlineMode &&
            ![1, 2, 3, 10, 11, 12, 13].includes(
              detailData?.status?.status_identity?.value
            )
          " (editUserComment)="
            editEvent($event, commentRef, statusTimeStampUpdateTemplateRef)
          " (deleteRestoreUserUpdate)="deleteRestoreUserUpdate($event)"></gtapp-selected-event-detail>
      </ng-container>
    </ng-container>

    <!-- Dialogue for assign users -->
    <ng-template #modifyAssigneeRef let-data let-modifyAssigneeRef="dialogRef">
      <div class="card dialog-box">
        <div class="card-header">
          Select Assignees

          <span *ngIf="detailData?.assignee?.length">
            [{{ detailData?.assignee?.length }} Selected]
          </span>

          <span class="float-end" (click)="modifyAssigneeRef.close()">
            <i class="fa-solid fa-xmark"></i>
          </span>
        </div>
        <div>
          <ng-container *ngIf="
              ![1, 2, 3, 9, 10, 11, 12, 13].includes(
                detailData?.status?.status_identity?.value
              ) && !offlineMode
            ">
            <div class="mb-3">
              <gtapp-auto-complete [data]="guardListData" placeHolder="Search/Select Assignee" searchKeyword="full_name"
                [itemTemplate]="assigneeTemplate" (inputChanged)="getAssignees($event)"
                (selected)="onAssigneeSelect($event)">
              </gtapp-auto-complete>
            </div>

            <ng-template #assigneeTemplate let-guard>
              <div>
                {{ guard?.full_name ? guard.full_name : guard.email }}
                <small *ngIf="!guard?.validStateLicense">
                  <i class="fa-solid fa-shield-halved"></i>
                </small>
                <span *ngIf="guard?.interval_check > 0" class="small-font text-info">
                  [Welfare check on every
                  {{ guard?.interval_check }} mins.]</span>
              </div>
            </ng-template>
          </ng-container>
          <div class="overflow-45vh">
            <table class="table my-2">
              <tbody>
                <tr *ngFor="let assignee of assigneeList" class="cursor-pointer">
                  <td>
                    <div class="d-flex">
                      <div class="flex-grow-1">
                        <span class="cursor-pointer" (click)="
                            ![1, 2, 3, 9, 10, 11, 12, 13].includes(
                              detailData?.status?.status_identity?.value
                            )
                              ? offlineMode
                                ? showOfflineMessage()
                                : addRemoveAssignee(assignee)
                              : ''
                          ">
                          <input class="form-check-input" type="checkbox" [checked]="assignee?.selected" [disabled]="
                              !(
                                ![1, 2, 3, 9, 10, 11, 12, 13].includes(
                                  detailData?.status?.status_identity?.value
                                ) && !offlineMode
                              )
                            " />
                          <span class="ms-2">{{ assignee?.full_name }}
                            <span *ngIf="assignee?.interval_check > 0" class="small-font text-info">
                              [Welfare check on every
                              {{ assignee?.interval_check }} mins.]</span></span>
                        </span>
                      </div>

                      <div (click)="
                          offlineMode
                            ? showOfflineMessage()
                            : openAssigneeDetail(assignee)
                        ">
                        <i class="fa-solid fa-circle-info md-font-size text-secondary me-2"></i>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="mt-3" *ngIf="
              jobAssigneeTotalPages > 1 &&
              jobAssigneeTotalCount != assigneeList?.length
            ">
            <button class="btn btn-secondary btn-lg" (click)="onLoadMoreAssignees()">
              Load More
            </button>
          </div>
          <div class="row row-cols-2 mt-4">
            <div class="col">
              <button class="btn btn-lg w-100 btn-dark" (click)="modifyAssigneeRef.close()">
                Cancel
              </button>
            </div>

            <div class="col">
              <button class="btn btn-primary btn-lg w-100" [disabled]="assigneeUpdateDisabling() || updateBtnPressed"
                (click)="updateAssignee()">
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <!-- ADD COMMENT POPOVER / DIALOG -->
    <ng-template #commentRef let-commentRef="dialogRef" let-data>
      <div class="card dialog-box">
        <div class="card-header">
          {{ data?.detail ? "Edit Comment" : "Add Comment" }}
          <span class="float-end" (click)="commentRef.close(); newComment = ''">
            <i class="fa-solid fa-xmark"></i>
          </span>
        </div>
        <div>
          <div class="form-group input-group">
            <textarea class="form-control" rows="5" wrap="hard" maxlength="340" placeholder="Enter comment here"
              autofocus [(ngModel)]="newComment" id="updateCommentBox"></textarea>
            <span class="input-group-text" *ngIf="newComment" (click)="clearAndFocusTextBox('updateCommentBox')">
              <i class="fa-solid fa-xmark"></i>
            </span>
          </div>
          <div class="row row-cols-2 mt-3">
            <div class="col">
              <button type="button" class="btn btn-dark btn-lg w-100" (click)="commentRef.close(); newComment = ''">
                Cancel
              </button>
            </div>
            <div class="col">
              <button class="btn btn-primary btn-lg w-100" [disabled]="!newComment"
                (click)="addComment(data); commentRef.close()">
                {{ data?.detail ? "Update" : "Add" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <!-- Cancel Job dialoge -->
    <ng-template #jobCancelTemplate let-jobCancelTemplate="dialogRef">
      <div class="card dialog-box">
        <div class="card-header">
          Confirmation Needed

          <span class="float-end" (click)="jobCancelTemplate.close()">
            <i class="fa-solid fa-xmark"></i>
          </span>
        </div>
        <div>
          <div class="alert alert-danger">
            <div>Cancel Job for {{ detailData?.company?.company_name }}</div>
            <div>WARNING: This action cannot be reversed.</div>
          </div>
          <div class="form-floating">
            <input class="form-control" type="text" [(ngModel)]="cancelReason"
              placeholder="Cancellation reason" /><label>Cancellation Reason</label>
          </div>
          <div class="row row-cols-2 mt-3">
            <div class="col">
              <button type="button" class="btn btn-dark btn-lg w-100" (click)="jobCancelTemplate.close()">
                Cancel
              </button>
            </div>
            <div class="col">
              <button class="btn btn-danger btn-lg w-100" [disabled]="!cancelReason || updateBtnPressed"
                (click)="deleteJob(); jobCancelTemplate.close()">
                Cancel Job
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <!-- UPDATE SITE CONTACTS POPOVER / DIALOG -->
    <ng-template #modifySiteContactRef let-modifySiteContactRef="dialogRef">
      <div class="card dialog-box">
        <div class="card-header">
          Onsite Contacts

          <span class="float-end" (click)="modifySiteContactRef.close()">
            <i class="fa-solid fa-xmark"></i>
          </span>
        </div>
        <table class="table my-2">
          <tbody>
            <tr *ngFor="let contact of siteContactList">
              <td>
                <div class="d-flex">
                  <div class="flex-grow-1">
                    <span class="cursor-pointer" (click)="
                        ![1, 2, 3, 9, 10, 11, 12, 13].includes(
                          detailData?.status?.status_identity?.value
                        ) &&
                        (isAdmin || isDispatchUser)
                          ? offlineMode
                            ? showOfflineMessage()
                            : addRemoveSiteContact(contact)
                          : ''
                      ">
                      <input *ngIf="isAdmin || isDispatchUser" class="form-check-input" type="checkbox"
                        [checked]="contact?.selected" [disabled]="
                          !(
                            ![1, 2, 3, 9, 10, 11, 12, 13].includes(
                              detailData?.status?.status_identity?.value
                            ) &&
                            !offlineMode &&
                            (isAdmin || isDispatchUser)
                          )
                        " />
                      <span class="ms-2">{{ contact?.full_name }}</span>
                    </span>
                  </div>
                  <div (click)="
                      offlineMode
                        ? showOfflineMessage()
                        : openSiteContactTemplate(contact)
                    ">
                    <i class="fa-solid fa-circle-info md-font-size text-secondary me-2"></i>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row row-cols-2 mt-3" *ngIf="
            (isAdmin || isDispatchUser) &&
            ![1, 2, 3, 9, 10, 11, 12, 13].includes(
              detailData?.status?.status_identity?.value
            )
          ">
          <div class="col">
            <button type="button" class="btn btn-dark btn-lg w-100" (click)="modifySiteContactRef.close()">
              Cancel
            </button>
          </div>
          <div class="col">
            <button class="btn btn-primary btn-lg w-100" (click)="addLocationContact()">
              Add New Contact
            </button>
          </div>
        </div>
      </div>
    </ng-template>

    <!-- Additional Information POPOVER / DIALOG -->
    <ng-template #requestRef let-data let-requestRef="dialogRef">
      <div class="card dialog-box">
        <div class="card-header">
          Additional Information
          <span class="float-end" (click)="requestRef.close()">
            <i class="fa-solid fa-xmark"></i>
          </span>
        </div>
        <!-- Request Detail Section start-->
        <div class="form-group input-group mb-3">
          <textarea class="form-control" placeholder="Additional Information" cols="20" rows="5" wrap="hard"
            maxlength="900" type="text" [(ngModel)]="newRequest" id="aiField"></textarea>
          <span class="input-group-text" *ngIf="newRequest" (click)="newRequest = ''; clearAndFocusTextBox('aiField')">
            <i class="fa-solid fa-xmark"></i>
          </span>
        </div>
        <div class="row row-cols-2 mt-3">
          <div class="col">
            <button type="button" class="btn btn-dark btn-lg w-100" (click)="requestRef.close()">
              Cancel
            </button>
          </div>
          <div class="col">
            <button class="btn btn-primary btn-lg w-100" (click)="updateRequestDetails(); requestRef.close()">
              Update
            </button>
          </div>
        </div>

        <!-- Request Detail Section end-->
      </div>
    </ng-template>

    <!-- UPDATE MONITORING COMPANY DIALOG -->
    <ng-template #modifyMCTemplate let-modifyMCTemplate="dialogRef">
      <div class="dialog-box card">
        <div class="card-header">
          Monitoring Company
          <span class="float-end" (click)="modifyMCTemplate.close()">
            <i class="fa-solid fa-xmark"></i>
          </span>
        </div>

        <div class="mb-3">
          <gtapp-auto-complete [data]="monitoringCompanyList" placeHolder="Monitoring Company"
            searchKeyword="company_name" [itemTemplate]="MCTemplate" [initialValue]="mcInputValue"
            [addInitialKey]="isAdmin || isDispatchUser" (inputChanged)="onMcSearch($event)" (selected)="addMC($event)"
            [inputId]="'mcId'" [enableCloseOption]="true" (fieldCleared)="editField('mc')">
          </gtapp-auto-complete>
        </div>

        <ng-template #MCTemplate let-item>
          @if (item?.key === 0) {
          <div>Add Monitoring Company</div>
          } @else {
          <div>
            {{ item.company_name }}
          </div>
          }
        </ng-template>
        <div class="row row-cols12 mt-2" *ngIf="detailData?.monitoring_company?.id">
          <div class="col">
            <button (click)="
                modifyMCTemplate.close();
                updateData({ monitoring_company_id: null })
              " class="btn btn-outline-danger btn-lg w-100">
              Remove Monitoring Company
            </button>
          </div>
        </div>
      </div>
    </ng-template>

    <!-- JOB ID DIALOG -->
    <ng-template #modifyExternalJobIdTemplate let-modifyExternalJobIdTemplate="dialogRef">
      <div class="dialog-box card">
        <div class="card-header">
          External JOB ID<span class="float-end" (click)="modifyExternalJobIdTemplate.close()">
            <i class="fa-solid fa-xmark"></i>
          </span>
        </div>

        <!-- Job Id Section start-->
        <div class="input-group">
          <div class="form-floating">
            <input class="form-control" type="text" placeholder="External Job ID" [(ngModel)]="externalNewJobId"
              autocomplete="off" maxlength="32" id="eJIdField" />
            <label>External Job ID</label>
          </div>
          <span class="input-group-text" *ngIf="externalNewJobId"
            (click)="externalNewJobId = ''; clearAndFocusTextBox('eJIdField')">
            <i class="fa-solid fa-xmark"></i>
          </span>
        </div>

        <div class="row row-cols-2 mt-4">
          <div class="col">
            <button class="btn btn-lg w-100 btn-dark" (click)="modifyExternalJobIdTemplate.close()">
              Cancel
            </button>
          </div>

          <div class="col">
            <button class="btn btn-primary btn-lg w-100" [disabled]="externalOldJobId === externalNewJobId"
              (click)="updateJobId(); modifyExternalJobIdTemplate.close()">
              Update
            </button>
          </div>
        </div>
      </div>
    </ng-template>
    <!-- Add OnSite/Offsite datetime model -->
    <ng-template #onsiteTimeTempalteRef let-onsiteTimeTempalteRef="dialogRef" let-data>
      <div class="dialog-box card">
        <div class="card-header">
          {{ data.name }} Time
          <span class="float-end" (click)="onsiteTimeTempalteRef.close(false)">
            <i class="fa-solid fa-xmark"></i>
          </span>
        </div>
        <div class="form-floating mb-3">
          <input type="datetime-local" class="form-control" placeholder="Select Time" [(ngModel)]="dateTimeValue"
            (change)="changeUserProvidedTime($event)" [min]="jobStatusTimeChange?.created_at"
            [max]="jobStatusTimeChange?.currentTime" />
          <label>Select Time</label>
        </div>

        <div class="row row-cols-2 mt-3">
          <div class="col">
            <button type="button" class="btn btn-dark btn-lg w-100" (click)="onsiteTimeTempalteRef.close(false)">
              Cancel
            </button>
          </div>
          <div class="col">
            <button class="btn btn-primary btn-lg w-100" (click)="onsiteTimeTempalteRef.close(data)"
              [disabled]="!jobStatusTimeChange?.valid">
              Update
            </button>
          </div>
        </div>
      </div>
    </ng-template>
    <!-- in transit eta tempalte-->
    <ng-template #inTransitTemplateRef let-inTransitTemplateRef="dialogRef">
      <div class="dialog-box card">
        <div class="card-header">
          ETA to Site
          <span class="float-end" (click)="inTransitTemplateRef.close(false); etaChangeReason = null">
            <i class="fa-solid fa-xmark"></i>
          </span>
        </div>

        <div *ngFor="let eta of etaValueList">
          <button class="btn btn-light btn-lg w-100 mb-2" (click)="updateEtaTime(eta)">
            {{ eta?.key }}
          </button>
        </div>

        <div class="form-floating mb-2">
          <input type="datetime-local" class="form-control" placeholder="ETA to Site" [(ngModel)]="etaValue"
            [min]="minEta" (change)="updateEtaTime($event)" />
          <label> ETA to Site</label>
        </div>

        <div class="form-floating mb-2" *ngIf="detailData?.showEta">
          <input class="form-control" type="text" placeholder="Reason for update" [(ngModel)]="etaChangeReason"
            autocomplete="off" />
          <label>Reason for update</label>
        </div>

        <div class="row row-cols-2">
          <div class="col">
            <button class="btn btn-lg w-100 btn-dark" (click)="
                inTransitTemplateRef.close(false); etaChangeReason = null
              ">
              Cancel
            </button>
          </div>

          <div class="col">
            <button class="btn btn-primary btn-lg w-100" (click)="
                inTransitTemplateRef.close(etaValue); etaChangeReason = null
              ">
              Update
            </button>
          </div>
        </div>
      </div>
    </ng-template>

    <!-- JOB WELFARE INTERVAL DIALOG -->
    <ng-template #welfareIntervalTemplate let-welfareIntervalTemplate="dialogRef">
      <div class="dialog-box card">
        <div class="card-header">
          Welfare Check Interval
          <span class="float-end" (click)="welfareIntervalTemplate.close()">
            <i class="fa-solid fa-xmark"></i>
          </span>
        </div>

        <div class="form-floating">
          <select class="form-select" id="welfareChecks" [(ngModel)]="selectedInterval">
            <option *ngFor="let interval of intervalData" [value]="interval.value">
              {{ interval.name }}
            </option>
          </select>
          <label for="">Welfare Check Interval</label>
        </div>
        <div *ngIf="selectedInterval > 0" class="alert">
          <div class="mb-2">
            Guards will be reminded to submit a welfare check
            <span class="fw-bold">
              {{ welfareReminderDict?.[selectedInterval] }} minutes
              before</span>
            the interval set above.
          </div>
          <div>
            Maximum inactivity time allowed before a missed welfare check is
            recorded:
            <span class="fst-italic fw-bold">
              {{ selectedInterval }} minutes.
            </span>
          </div>
        </div>
        <div class="row row-cols-2 mt-4">
          <div class="col">
            <button class="btn btn-lg w-100 btn-dark" (click)="welfareIntervalTemplate.close()">
              Cancel
            </button>
          </div>

          <div class="col">
            <button class="btn btn-primary btn-lg w-100" [disabled]="selectedInterval == detailData?.interval_check"
              (click)="updateInterval(selectedInterval)">
              Confirm
            </button>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template #changeScheduleTemplateRef let-changeScheduleTemplateRef="dialogRef">
      <div class="dialog-box card">
        <div class="card-header">
          Change Schedule

          <span class="float-end" (click)="changeScheduleTemplateRef.close()">
            <i class="fa-solid fa-xmark"></i>
          </span>
        </div>
        <div class="form-floating mb-3">
          <input type="datetime-local" class="form-control" placeholder="End Time" [(ngModel)]="endDateTime"
            [min]="validEndDate" (change)="changeEndDateTime($event)" />
          <label>End Time</label>
        </div>
        <div class="row row-cols-2 mt-4">
          <div class="col">
            <button class="btn btn-lg w-100 btn-dark" (click)="changeScheduleTemplateRef.close()">
              Cancel
            </button>
          </div>

          <div class="col">
            <button class="btn btn-primary btn-lg w-100" (click)="updateSchedule(); changeScheduleTemplateRef.close()">
              Update
            </button>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<ng-template #geoFenceAlertDistanceRef let-geoFenceAlertDistanceRef="dialogRef" let-data>
  <div class="dialog-box card">
    <div class="card-header">
      Geofence Alert

      <span class="float-end" (click)="geoFenceAlertDistanceRef.close()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>
    @if(data?.seeRadiusOnly){
    <ng-container>
      <gtapp-map-leaflet [latLon]="{
          lat: detailData?.sites[0]?.address?.latitude,
          lon: detailData?.sites[0]?.address?.longitude,
          geoFenceRadius: detailData?.additional_info?.geofence_distance
        }" mapId="smallMapOtherJob"></gtapp-map-leaflet></ng-container>
    }@else {
    <ng-container>
      <gtapp-map-leaflet [latLon]="{
          lat: detailData?.sites[0]?.address?.latitude,
          lon: detailData?.sites[0]?.address?.longitude
        }" [changableRadius]="true" [mapRadiusParams]="{
          minKm: 0,
          maxKm: 5000,
          nearestKmValue: detailData?.additional_info?.geofence_distance
        }" mapId="smallMapOtherJob" (updateMinDistance)="updateMinDistance($event)"></gtapp-map-leaflet>
    </ng-container>
    }

    <div class="row row-cols-3" *ngIf="!data?.seeRadiusOnly">
      <div class="col">
        <button class="btn btn-lg w-100 btn-dark" (click)="geoFenceAlertDistanceRef.close()">
          Cancel
        </button>
      </div>
      <div class="col">
        <button class="btn btn-lg w-100 btn-danger" (click)="
            geoFenceAlertDistanceRef.close();
            geoAlertDistance = 0;
            updateGeoFenceAlert()
          ">
          Remove Alert
        </button>
      </div>

      <div class="col">
        <button class="btn btn-primary btn-lg w-100" (click)="updateGeoFenceAlert(); geoFenceAlertDistanceRef.close()">
          Update
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #jobActionTemplateRef let-templateRefs="templates">
  @if(![1,2,3, 9, 10, 11, 12, 13].includes(
  detailData?.status?.status_identity?.value )){
  <ng-container>
    <!-- check JobStatus model or global.variable.ts page for status info-->
    <div class="text-center p-2 fw-bold rounded-2" [style]="selectedJobStatus?.additional_info?.style?.snubbed">
      {{ selectedJobStatus?.name ? selectedJobStatus?.name : "NOT STARTED" }}
      <ng-container *ngIf="selectedJobStatus?.status_identity?.value === 4">
        <div class="fw-semibold">{{ onsiteTimer }}</div>
      </ng-container>
      <ng-container *ngIf="detailData?.status?.status_identity?.value === 8">
        <!--show only when the status in 'In Transit'-->

        @if(detailData?.etaDiff?.minutes ){
        <div
          class="cursor-pointer fw-normal rounded-1 border border-primary text-dark m-auto py-2 px-2 w-75 bg-secondary bg-gradient text-white"
          *ngIf="detailData?.showEta" (click)="
            offlineMode
              ? showOfflineMessage()
              : detailData?.status?.status_identity?.value === 8
              ? openTemplate(templateRefs?.inTransitTemplateRef)
              : ''
          ">
          <span>
            <i class="fa-regular fa-clock text-white"></i>
          </span>
          ETA:
          <span class="fw-semibold">
            {{ detailData?.eta | customDate : "h:mm a" }}</span>
          <span *ngIf="!detailData?.sameDay">
            {{ detailData?.eta | customDate : "d MMM y" }}</span>
          @if(detailData?.etaDiff?.minutes >0){ ({{
          detailData?.etaDiff?.minutes
          }}
          minutes) }@else {
          <!-- Only show if job is still In Transit -->
          <span class="text-white bg-danger px-1">
            {{ detailData?.etaDiff?.minutes * -1 }} mins late</span>
          }
        </div>
        }@else{
        <span class="p-1" (click)="
            offlineMode
              ? showOfflineMessage()
              : detailData?.status?.status_identity?.value === 8
              ? openTemplate(templateRefs?.inTransitTemplateRef)
              : ''
          ">
          <i class="fa-regular fa-clock text-primary"></i>
        </span>
        }
      </ng-container>
    </div>
    @if(largeView){
    <div class="row row-cols-2 g-1 pt-2" *ngIf="scheduleCheck && !offlineMode">
      <div class="col">
        <button *ngIf="detailData?.service_type?.enable_comments === true"
          class="btn btn-primary btn-lg w-100 rounded-0" (click)="openAddCommentTemplate(templateRefs?.commentRef)">
          + Add Comment
        </button>
      </div>

      <div class="col" (click)="statusDropdownOpen = !statusDropdownOpen">
        <button class="btn btn-success w-100 btm-btn-height rounded-0 d-flex align-items-center justify-content-center"
          (blur)="closeStatusDropDown()">
          Change Status
          <div class="ms-4">
            @if(statusDropdownOpen){
            <span>
              <i class="fas fa-chevron-up"></i>
            </span>
            }@else {
            <span>
              <i class="fas fa-chevron-down"></i>
            </span>
            }
          </div>
        </button>
        <div class="col dropdown">
          <div class="dropdown-content-down" *ngIf="statusDropdownOpen">
            <a *ngFor="let item of statusList" (click)="
                changeJobStatus(
                  item,
                  templateRefs?.jobCancelTemplate,
                  templateRefs?.onsiteTimeTempalteRef,
                  templateRefs?.inTransitTemplateRef
                );
                statusDropdownOpen = false
              ">{{ item?.status?.name }}</a>
          </div>
        </div>
      </div>
    </div>
    } @else {
    <div class="dropdown" *ngIf="!offlineMode && detailData?.status?.status_identity?.value !== 1">
      <div id="jobChangeStatus" class="pt-2" (click)="statusDropdownOpen = !statusDropdownOpen">
        <button class="btn btn-primary w-100 btm-btn-height d-flex align-items-center justify-content-center rounded-0"
          (blur)="closeStatusDropDown()">
          Change Status
          <div class="ms-4">
            @if(statusDropdownOpen){
            <span>
              <i class="fas fa-chevron-up"></i>
            </span>
            }@else {
            <span>
              <i class="fas fa-chevron-down"></i>
            </span>
            }
          </div>
        </button>
      </div>
      <div class="dropdown-content-up" *ngIf="statusDropdownOpen">
        <a *ngFor="let item of statusList" (click)="
            changeJobStatus(
              item,
              templateRefs?.jobCancelTemplate,
              templateRefs?.onsiteTimeTempalteRef,
              templateRefs?.inTransitTemplateRef
            );
            statusDropdownOpen = false
          ">{{ item?.status?.name }}</a>
      </div>
    </div>
    <div id="jobUpdateButtons" class="d-flex justify-content-between py-2"
      *ngIf="detailData?.status?.status_identity?.value !== 1">
      <div *ngIf="detailData?.service_type?.enable_comments === true"
        class="d-flex align-items-center justify-content-center flex-fill me-1 border border-primary btm-btn-height"
        (click)="openAddCommentTemplate(templateRefs?.commentRef)">
        <i class="fa-regular fa-message fa-2x"></i>
      </div>
      <div *ngIf="detailData?.service_type?.enable_uploads === true"
        class="d-flex align-items-center justify-content-center flex-fill ms-1 border border-primary btm-btn-height"
        (click)="openCamPopup()">
        <i class="fa-solid fa-camera fa-2x"></i>
      </div>
    </div>
    }
  </ng-container>
  } @else if ( detailData?.status?.status_identity?.value == 1 || ([9, 10, 11,
  12, 13].includes( detailData?.status?.status_identity?.value ) && ! (isAdmin
  || isDispatchUser)) ) {
  <!-- case where status is complete or (invoicing status and user is guard=> show job as completed/closed)-->
  <div class="text-center p-2 fw-bold mb-1" [ngStyle]="
      detailData?.can_reopen
        ? selectedJobStatus?.additional_info?.style?.snubbed
        : {
            color: 'var(--highlight-color)',
            background: 'var(--highlight-bg)'
          }
    ">
    {{ detailData?.can_reopen ? "JOB COMPLETED" : "JOB CLOSED" }}
  </div>
  <ng-container *ngIf="
      detailData?.can_reopen &&
      !offlineMode &&
      lastStatus?.status?.id &&
      detailData?.status?.status_identity?.value == 1
    ">
    <div class="text-center d-flex align-items-center justify-content-center mb-2">
      <button (click)="moveJobBack()" class="btn btn-outline-primary w-100 btn-lg">
        Reopen Job
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="
      !offlineMode &&
      !detailData?.sites[0]?.auto_send_reports &&
      detailData?.status?.status_identity?.value == 1 &&
      jobCompleteInvoiceRelatedStatus?.review?.status?.id &&
      (isAdmin || isDispatchUser)
    ">
    <div class="d-flex align-items-center justify-content-center">
      <button class="w-100 btn-success btn btn-lg" (click)="reviewJob()">
        Preview Report
      </button>
    </div>
  </ng-container>

  } @else {
  <ng-container [ngSwitch]="detailData?.status?.status_identity?.value">
    <ng-container *ngSwitchCase="9">
      <div class="text-center p-2 fw-bold" [ngStyle]="selectedJobStatus?.additional_info?.style?.snubbed">
        JOB UNDER REVIEW
      </div>
      <ng-container *ngIf="
          !offlineMode && jobCompleteInvoiceRelatedStatus?.approve?.status?.id
        ">
        <div class="row row-cols-2">
          <div class="col">
            <button class="btn btn-lg btn-success w-100 rounded-0" (click)="approveJob()">
              Approve
            </button>
          </div>
          <div class="col">
            <button class="btn btn-lg btn-success w-100 rounded-0" (click)="approveJob(true)">
              Approve & Send
            </button>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="10">
      <div class="text-center d-flex align-items-center justify-content-center mb-2">
        <button (click)="reviewJob()" class="btn btn-outline-warning w-100 btn-lg">
          Edit Report
        </button>
      </div>
      <div class="text-center p-2 fw-semibold" [ngStyle]="selectedJobStatus?.additional_info?.style?.snubbed">
        Completed
      </div>
    </ng-container>
  </ng-container>
  }
</ng-template>

<ng-template #statusTimeStampUpdateTemplateRef let-statusTimeStampUpdateTemplateRef="dialogRef" let-data>
  <div class="dialog-box card">
    <div class="card-header">
      Update {{ data.name }} Time
      <span class="float-end" (click)="statusTimeStampUpdateTemplateRef.close(false)">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>
    <div class="small border border-1 text-secondary p-2 mb-2 rounded-2">
      {{ data.name }} time must be between
      {{ data?.minDateTime | customDate : "d MMM y HH:mm" }} &
      {{ data?.maxDateTime | customDate : "d MMM y HH:mm" }}
    </div>
    <div class="form-floating mb-3">
      <input type="datetime-local" class="form-control" placeholder="Select Time" [(ngModel)]="data.newValue"
        (change)="changeStatusTimeStamp($event, data)" [min]="data?.minDateTime" [max]="data?.maxDateTime" />
      <label>Select Time</label>
    </div>

    <div class="row row-cols-2 mt-3">
      <div class="col">
        <button type="button" class="btn btn-dark btn-lg w-100" (click)="statusTimeStampUpdateTemplateRef.close(false)">
          Cancel
        </button>
      </div>
      <div class="col">
        <button class="btn btn-primary btn-lg w-100" (click)="statusTimeStampUpdateTemplateRef.close(data)"
          [disabled]="!data?.valid">
          Update
        </button>
      </div>
    </div>
  </div>
</ng-template>

<!-- edit response type  DIALOG -->
<ng-template #responseTypeTemplateRef let-responseTypeTemplateRef="dialogRef" let-data>
  <div class="dialog-box card">
    <div class="card-header">
      Update Response Type

      <span class="float-end" (click)="responseTypeTemplateRef.close()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>

    <div>
      <div class="mb-3">
        <gtapp-auto-complete [data]="responseTypeList" [maxLength]="256" placeHolder="Search Response Type"
          searchKeyword="name" [initialValue]="previousResponseTypeValue" [itemTemplate]="responseTypeTemplate"
          [addInitialKey]="isAdmin || isDispatchUser" (inputChanged)="onSearchResponseType($event)"
          (selected)="onSelectResponseType($event)" [inputId]="'rtId'" [enableCloseOption]="true"
          (fieldCleared)="editField('rt')">
        </gtapp-auto-complete>
      </div>

      <ng-template #responseTypeTemplate let-item>
        @if (item?.key === 0) {
        <div>Add New Response Type</div>
        } @else {

        <div>{{ item?.name }}</div>

        }
      </ng-template>

      <div class="row row-cols12 mt-2" *ngIf="detailData?.response_type?.id">
        <div class="col">
          <button (click)="
              responseTypeTemplateRef.close();
              updateData({ response_type_id: null })
            " class="btn btn-outline-danger btn-lg w-100">
            Remove Response Type
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- edit service type  DIALOG -->
<ng-template #serviceTypeTemplateRef let-serviceTypeTemplateRef="dialogRef" let-data>
  <div class="dialog-box card">
    <div class="card-header">
      Update Service Type

      <span class="float-end" (click)="serviceTypeTemplateRef.close()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>

    <div>
      <div class="mb-3">
        <gtapp-auto-complete [data]="serviceTypeListData" [maxLength]="256" placeHolder="Search Service Type"
          searchKeyword="name" [initialValue]="previousServiceTypeValue" [itemTemplate]="serviceTypeTemplate"
          [addInitialKey]="isAdmin || isDispatchUser" (inputChanged)="onSTSearch($event)"
          (selected)="onServiceTypeSelect($event)" [inputId]="'stId'" [enableCloseOption]="true"
          (fieldCleared)="editField('st')">
        </gtapp-auto-complete>
      </div>

      <ng-template #serviceTypeTemplate let-item>
        @if (item?.key === 0) {
        <div>Add New Service Type</div>
        } @else {

        <div>{{ item?.name }}</div>

        }
      </ng-template>

      <div class="row row-cols12 mt-2" *ngIf="detailData?.job_service_type?.id">
        <div class="col">
          <button (click)="
              serviceTypeTemplateRef.close();
              updateData({ service_type_id: null })
            " class="btn btn-outline-danger btn-lg w-100">
            Remove Service Type
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>