<div class="me-lg-2 my-2 text-end" *ngIf="archivedCount && !clientId">
  <button
    class="btn btn-secondary btn-sm"
    (click)="getArchivedUnarchivedList()"
    id="siteDeleteBtn"
  >
    {{ showArchivedList ? "Show Active" : "Show Deleted" }}
  </button>
</div>
<div *ngIf="showArchivedList" class="fw-bold text-center my-3" role="alert">
  Showing Deleted Sites
</div>

<div class="d-flex flex-column flex-sm-row input-btn-group mb-2">
  <div class="mb-2 me-sm-2 col-lg-2" *ngIf="!showArchivedList">
    <button
      id="addSite"
      class="btn btn-primary btn-lg w-100"
      (click)="openAddSiteForm()"
    >
      + Add Site
    </button>
  </div>
  <div class="mb-2 flex-sm-grow-1" *ngIf="totalSiteCount > 5" id="filterSite">
    <gtapp-auto-complete
      [data]="siteDropDownList"
      placeHolder="Search Sites"
      searchKeyword="company_name"
      [itemTemplate]="userTemplate"
      (inputChanged)="onSiteLookup($event)"
      (selected)="openSiteView($event)"
      (submitted)="globalFilter = $event; sitePaginationData = {}; getSites()"
      [enableSearchCloseOption]="true"
      (fieldCleared)="globalFilter = null; sitePaginationData = {}; getSites()"
    >
    </gtapp-auto-complete>

    <ng-template #userTemplate let-item>
      <div>{{ item?.company_name }} [{{ item?.company?.company_name }}]</div>
    </ng-template>
  </div>
</div>
<ng-container *ngIf="totalSiteCount > 0">
  @if (!largeView) {
  <div id="siteList">
    <gtapp-card-list
      [mobileCardBackendData]="sitePaginationData[pageNumSite]"
      [mobileCardDetails]="siteCardViewDetail"
      [otherParams]="otherParams"
      (sortColumn)="sortColumn($event)"
      (searchColumn)="searchColumn($event)"
      (rowClick)="openSiteView($event)"
      (paginationChange)="onChangePagination($event)"
    ></gtapp-card-list>
  </div>
  }@else {
  <!-- DESKTOP VIEW -->
  <div id="siteList">
    <gtapp-gt-table
      [desktopTableBackendData]="sitePaginationData[pageNumSite]"
      [desktopTableDetails]="siteTableDetail"
      [tableStyle]="tableStyle"
      [otherParams]="otherParams"
      (sortColumn)="sortColumn($event)"
      (searchColumn)="searchColumn($event)"
      (rowClick)="openSiteView($event)"
      (paginationChange)="onChangePagination($event)"
    ></gtapp-gt-table>
  </div>
  }
</ng-container>
