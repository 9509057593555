import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { globalChartHeight } from '../../../../global.variable';

@Component({
  selector: 'gtapp-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrl: './bar-chart.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BarChartComponent implements OnInit, OnChanges {
  @Input() barChartData: any = [];
  @Input() charInfo: any;
  maxChartValue: number = 0;
  defaultChartHeight: number = globalChartHeight; // in pixels
  averageValue: number = 0;
  averageValueInPx: any;
  ngOnInit(): void {
    this.updateGraph();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.updateGraph();
  }
  updateGraph() {
    const barChartValue: any = this.charInfo?.value?.name;

    const allAvailableValues = this.barChartData?.map(
      (item: any) => item?.[barChartValue]
    );
    this.maxChartValue =
      Math.max(...allAvailableValues) || this.defaultChartHeight;
    try {
      const sumValue =
        allAvailableValues?.reduce((sum: any, val: any) => sum + val) || 1;

      this.averageValue = Math.round(sumValue / 24);

      this.averageValueInPx = `${
        Math.round(
          this.defaultChartHeight * (this.averageValue / this.maxChartValue)
        ) +
        (20 + 26 + 25)
      }px`; // adding the padding  of bar chart class and the height of bar label and height og avg value
    } catch (error) {}
  }
}
