<div class="p-1" id="companyProfilePage">
  <input
    #fileInput
    id="fileInput"
    style="display: none; cursor: pointer"
    type="file"
    accept="image/*"
    (change)="handleFileInput($event.target)"
  />
  <div
    class="row row-cols-sm-1 mb-2"
    [ngClass]="subscriberLogoUrl ? 'row-cols-md-2' : ''"
  >
    <div class="col-12 col-lg-4 order-1 order-lg-3" *ngIf="subscriberLogoUrl">
      <!-- Company Logo -->
      <div class="row gy-2 row-cols-2 row-cols-md-1">
        <div (click)="openCamPopup()" class="profile-picture-container">
          <img
            class="mb-2"
            [ngClass]="largeView ? 'profile-picture' : 'profile-picture-mobile'"
            src="{{ subscriberLogoUrl }}"
            alt="User-Profile-Image"
          />
        </div>
        <div
          *ngIf="subscriberLogoUrl"
          class="col d-flex align-items-center justify-content-center"
        >
          <div class="row gy-2 row-cols-1">
            <div class="col">
              <button
                class="btn text-primary w-100 btn-md"
                (click)="openCamPopup()"
              >
                Update Company Logo
              </button>
            </div>
            <div class="col">
              <button class="btn w-100 btn-md" (click)="removeImage()">
                Delete Company Logo
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-lg-8 order-2 order-lg-1">
      <div
        class="row row-col-sm-1"
        [ngClass]="subscriberLogoUrl ? 'row-cols-lg-2' : 'row-cols-md-2'"
      >
        <div class="col-12 col-lg-8">
          <!-- Comapny DETAILS -->
          <div class="mb-1 row details-card">
            <dl>
              <dt>Company Name</dt>
              <dd class="fw-bold">
                {{ companyDetails?.name }}
              </dd>
            </dl>
            <dl>
              <dt>Address</dt>
              <dd>
                {{ companyDetails?.address?.full_address }}
              </dd>
            </dl>
            <div class="col" *ngIf="companyDetails?.phone">
              <dt>Phone</dt>
              <dd>{{ companyDetails?.phone }}</dd>
            </div>
            <dl>
              <dt>Email</dt>
              <dd>{{ companyDetails?.email }}</dd>
            </dl>
          </div>
        </div>
        <!-- BUTTONS -->
        <div class="col-12 col-lg-4">
          <div class="row">
            <div>
              <button
                type="button"
                class="btn btn-primary w-100 btn-lg"
                (click)="openTemplate(companyDialog)"
              >
                Update Details
              </button>
            </div>
            <div class="col-12" *ngIf="!subscriberLogoUrl" id="companyLogo">
              <button
                class="btn-primary btn w-100 btn-lg"
                (click)="openCamPopup()"
              >
                Upload Company Logo
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div>
    <div class="col-12 col-lg-3">
      <dl class="d-none">
        <dt class="fw-bold d-none d-md-inline-block">
          <span class="d-flex align-items-center">
            <span>Company Licence</span>
            <button
              type="button"
              class="ms-2 btn-primary btn-sm btn"
              (click)="addNewLicense()"
            >
              + Add Company Master Licence
            </button>
          </span>
        </dt>
      </dl>
      <div id="companyLicence">
        <button
          type="button"
          class="d-block btn btn-primary btn-lg mb-2 w-100"
          (click)="addNewLicense()"
        >
          + Add Company Master Licence
        </button>
      </div>
    </div>
    <div>
      <gtapp-add-edit-license-details
        [licensesDataList]="companyLicenses"
        [showLicensesList]="true"
        (deleteLicense)="deleteLicense($event)"
      ></gtapp-add-edit-license-details>
    </div>
    <ng-container *ngIf="isAdmin && largeView">
      <div class="mb-5"></div>
      <gtapp-user-update-log [eventType]="'subscriber'">
      </gtapp-user-update-log>
    </ng-container>
  </div>
</div>

<ng-template #companyDialog let-cpRef="dialogRef">
  <div class="card dialog-box">
    <div class="card-header">
      Company Details
      <span
        class="float-end"
        (click)="companyDialogueStep = 1; cpRef.close('close')"
      >
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>
    <form [formGroup]="companyForm" gtappFormEnterAction>
      <div class="d-flex flex-column gap-2">
        <ng-container *ngIf="companyDialogueStep === 1">
          <div *ngIf="companyDetails?.name" class="form-floating">
            <input
              class="form-control"
              type="text"
              formControlName="name"
              placeholder="Company Name"
            />
            <label for="name">Company Name</label>
          </div>
          <div class="form-floating">
            <input
              class="form-control"
              type="text"
              autocomplete="off"
              autocorrect="off"
              autocapitalize="off"
              formControlName="email"
              placeholder="Email"
            />
            <label for="email">Email</label>
          </div>
          <div class="form-floating">
            <input
              class="form-control"
              type="tel"
              minlength="10"
              maxlength="10"
              formControlName="phone"
              placeholder="Telephone"
            />
            <label for="phone">Telephone</label>
          </div>
          <div class="row row-cols-2">
            <div class="col">
              <button
                type="button"
                class="btn btn-lg btn-secondary w-100"
                (click)="companyDialogueStep = 1; cpRef.close('close')"
              >
                Cancel
              </button>
            </div>
            <div class="col">
              <button
                type="button"
                class="btn btn-lg btn-primary w-100"
                (click)="companyDialogueStep = 2"
              >
                Next
              </button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="companyDialogueStep === 2">
          <gtapp-address-lookup [addressForm]="companyForm">
          </gtapp-address-lookup>
          <div class="row row-cols-2 gy-2">
            <div class="col">
              <button
                type="button"
                class="btn btn-lg btn-dark w-100"
                (click)="companyDialogueStep = 1"
              >
                Back
              </button>
            </div>
            <div class="col">
              <button
                type="button"
                class="btn btn-lg btn-primary w-100"
                id="submitbtn"
                [disabled]="!companyForm.valid"
                (click)="updateData(); cpRef.close(); companyDialogueStep = 1"
              >
                Submit
              </button>
            </div>
          </div>
        </ng-container>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #smtpConfigTemplate let-smtpConfigTemplate="dialogRef">
  <div class="card dialog-box">
    <div class="card-header">
      SMTP Configuration
      <span class="float-end" (click)="smtpConfigTemplate.close('close')">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>
    <form [formGroup]="smtpConfigForm">
      <div class="row row-cols-1 mb-4">
        <div class="form-floating">
          <input
            class="form-control"
            type="text"
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            formControlName="smtp_host"
            placeholder="Host"
          />
          <label>Host</label>
        </div>
        <div
          class="d-flex align-items-center py-2 px-5 justify-content-between"
        >
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              id="tlsOption"
              formControlName="protocol"
              value="tls"
              name="protocol"
              (change)="changeSLayer('tls')"
            />
            <label class="form-check-label" for="tlsOption"> TLS </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              id="sslOption"
              formControlName="protocol"
              value="ssl"
              name="protocol"
              (change)="changeSLayer('ssl')"
            />
            <label class="form-check-label" for="sslOption"> SSL </label>
          </div>
        </div>
        <div class="form-floating">
          <input
            class="form-control"
            type="tel"
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            formControlName="smtp_port"
            placeholder="Port"
          />
          <label>Port</label>
        </div>
        <div class="form-floating">
          <input
            class="form-control"
            type="email"
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            formControlName="smtp_email"
            placeholder="Email"
          />
          <label for="email">Email</label>
        </div>
        <div class="form-floating">
          <input
            class="form-control"
            type="password"
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            formControlName="smtp_password"
            placeholder="Password"
          />
          <label>Password</label>
        </div>
      </div>
    </form>
    <ng-container *ngIf="smtpConfigForm.valid && !smtpCorrectlyConfigured">
      <ng-container *ngIf="smtpErrorMessage">
        <div class="alert alert-danger mb-2">{{ smtpErrorMessage }}</div>
      </ng-container>

      <div class="row row-cols-2 mb-4">
        <div class="form-floating">
          <input
            class="form-control"
            type="email"
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            placeholder="Test Email Address"
            [(ngModel)]="sampleEmailAddress"
          />
          <label for="email">Test Email Address</label>
        </div>

        <div>
          <button
            type="button"
            class="btn btn-lg btn-success w-100"
            id="submitbtn"
            [disabled]="!sampleEmailAddress"
            (click)="sendSampleEmail()"
          >
            Send Sample Email
          </button>
        </div>
      </div>
    </ng-container>
    <div class="row row-cols-2 gy-2">
      <div class="col">
        <button
          type="button"
          class="btn btn-lg btn-dark w-100"
          (click)="smtpConfigTemplate.close()"
        >
          Cancel
        </button>
      </div>
      <div class="col">
        <button
          type="button"
          class="btn btn-lg btn-primary w-100"
          id="submitbtn"
          [disabled]="!smtpCorrectlyConfigured"
          (click)="smtpConfigTemplate.close(); configureSMTP()"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</ng-template>
