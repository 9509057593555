import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewContainerRef,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  getInitalDateRangeForListData,
  isLargeScreen,
} from '../../../../global.variable';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { ContactComponent } from '../../../shared/components/contact/contact.component';
import { ModelDialogueService } from '../../../shared/components/modal-dialogue/model-dialogue.service';
import { LoadingSpinnerService } from '../../../shared/services/loading-spinner.service';
import { ToasterService } from '../../../shared/services/toaster.service';
import { ClientsService } from '../clients/clients.service';
import { getDateTimeRange } from '../tableDetails';
import { UserPromptService } from '../user-prompts/user-prompt.service';
import { UsersService } from '../users/users.service';

@Component({
  selector: 'gtapp-reports',
  templateUrl: './reports.component.html',
  styleUrl: './reports.component.scss',
})
export class ReportsComponent implements OnInit {
  uiTabs = {
    tab1: 'Patrols',
    tab2: 'Jobs',
    tab3: 'Discrepancies',
    tab4: 'User Prompts',
    tab5: 'Configured Reports',
  };
  largeView: Boolean = isLargeScreen;

  userEventDetailsTableView: any = [];
  userEventDetailsMobileView: any = {};

  jobReportDetailsMobile = {
    individualDetails: [
      {
        name: 'created_at',
        datetimeObj: true,
        dateRangeKey: 'created_at',
        ...getDateTimeRange(3),
        cardRowStyle: {
          'font-size': 'small',
          'float': 'right',
        },
      },
      {
        name: 'job_type',
        cardRowStyle: {
          'font-size': 'small',
        },
      },

      {
        name: 'site_name',
        cardRowStyle: { 'font-weight': 'bold' },
        suffixStyle: { 'font-weight': 'normal' },
        suffixNestedValue: (row: any) => {
          return ` [${row?.company_name}]`;
        },
      },
      // Address
      {
        name: 'site_address',
        cardRowStyle: { 'font-size': 'small' },
      },

      {
        nestedValue: (row: any) => {
          if (row?.ext_job_id) {
            return `${row?.job_key} [${row?.ext_job_id}]`;
          } else {
            return row?.job_key;
          }
        },
        cardRowStyle: {
          'font-size': 'small',
        },
      },
    ],
  };

  jobCompletedReportTableDetails = [
    {
      header: 'Created At',
      name: 'created_at',
      datetimeObj: true,
      dateRangeKey: 'created_at',
      sortKey: 'created_at',
      ...getDateTimeRange(3),
    },
    {
      header: 'Site',
      sortKey: 'site_name',
      name: 'site_name',
      searchKey: 'site_name',
      tableColumnStyle: {
        'white-space': 'pre-line',
      },
      suffixNestedValue: (row: any) => {
        return ` \n ${row?.site_address} \n[${row?.company_name}]`;
      },
      suffixStyle: { 'font-size': '0.8rem' },
    },
    // {
    //   header: 'Address',
    //   name: 'site_address',
    //   searchKey: 'site_address',
    //   columnDataStyle: {},
    // },
    {
      header: 'Job',
      sortKey: 'job_key',
      // searchKey: 'job_key',
      tableColumnStyle: {
        'white-space': 'pre-line',
      },
      nestedValue: (row: any) => {
        let appendList = [row?.job_type, row?.job_key];
        if (row?.ext_job_id) appendList.push(`[${row?.ext_job_id}]`);

        return appendList.join('\n');
      },
    },

    {
      header: 'Onsite',
      name: 'on_site_date',
      datetimeObj: true,
    },
    {
      header: 'Offsite',
      name: 'off_site_date',
      datetimeObj: true,
    },
    {
      header: 'Time Onsite',

      nestedValue: (row: any) => {
        return this.getTimeDifference(row);
      },
    },
    {
      header: 'Completed',
      sortKey: 'latest_update',
      name: 'completed_date',
      datetimeObj: true,
    },

    {
      header: 'Add. Info.',
      name: 'additional_information',
      columnDataStyle: {
        'white-space': 'pre-line',
      },
    },

    {
      header: 'Comments',

      nestedValue: (row: any) => {
        let sortedComments = row?.job_comments?.sort(
          (a: any, b: any) =>
            new Date(a?.comment_time).getTime() -
            new Date(b?.comment_time).getTime()
        );

        let comments = sortedComments?.map(
          (commentObj: any) => commentObj?.comment
        );

        return comments?.join('\n\n');
      },
      columnDataStyle: {
        'white-space': 'pre-line',
      },
    },
    {
      header: 'Guard',
      nestedValue: (row: any) => {
        return row?.assignees?.join('\n');
      },
      columnDataStyle: {
        'white-space': 'pre-line',
      },
    },
  ];
  jobCancelledReportTableDetails = [
    {
      header: 'Created At',
      name: 'created_at',
      datetimeObj: true,
      dateRangeKey: 'created_at',
      sortKey: 'created_at',
      ...getDateTimeRange(3),
    },
    {
      header: 'Site',
      sortKey: 'site_name',
      name: 'site_name',
      searchKey: 'site_name',
      tableColumnStyle: {
        'white-space': 'pre-line',
      },
      suffixNestedValue: (row: any) => {
        return ` \n ${row?.site_address} \n[${row?.company_name}]`;
      },
      suffixStyle: { 'font-size': '0.8rem' },
    },

    {
      header: 'Job',
      sortKey: 'job_key',
      // searchKey: 'job_key',
      tableColumnStyle: {
        'white-space': 'pre-line',
      },
      nestedValue: (row: any) => {
        let appendList = [row?.job_type, row?.job_key];
        if (row?.ext_job_id) appendList.push(`[${row?.ext_job_id}]`);

        return appendList.join('\n');
      },
    },
    {
      header: 'Cancelled',
      sortKey: 'latest_update',
      name: 'cancelled_date',
      datetimeObj: true,
    },
    {
      header: 'Cancel Reason',
      nestedValue: (row: any) => {
        let sortedComments = row?.job_comments?.sort(
          (a: any, b: any) =>
            new Date(a?.comment_time).getTime() -
            new Date(b?.comment_time).getTime()
        );
        let comments = sortedComments?.map(
          (commentObj: any) => commentObj?.comment
        );
        return comments?.length > 0 ? comments[comments.length - 1] : '';
      },
    },

    {
      header: 'Add. Info.',
      name: 'additional_information',
      columnDataStyle: {
        'white-space': 'pre-line',
      },
    },

    {
      header: 'Comments',

      nestedValue: (row: any) => {
        let sortedComments = row?.job_comments?.sort(
          (a: any, b: any) =>
            new Date(a?.comment_time).getTime() -
            new Date(b?.comment_time).getTime()
        );
        let comments = sortedComments?.map(
          (commentObj: any) => commentObj?.comment
        );
        return comments?.join('\n\n');
      },
      columnDataStyle: {
        'white-space': 'pre-line',
      },
    },
    {
      header: 'Guard',
      nestedValue: (row: any) => {
        return row?.assignees?.join('\n');
      },
      columnDataStyle: {
        'white-space': 'pre-line',
      },
    },
  ];

  dateRange: any = {
    start: new Date(new Date().setDate(new Date().getDate() - 1)),
    end: new Date(new Date().setDate(new Date().getDate() + 1)),
  };
  sortKeys: any = [];
  searchParams: any;
  totalRows: number = 0;
  previous: number = 0;
  pageSize: number = 10;
  pageNum: number = 1;
  totalPages: number = 0;

  otherParams: any = {};

  scrollPosition: any;

  allowPDFExport: boolean = false;
  allowBillingReportExport: boolean = false;
  userEvents: any;
  clientDropDownList: any = [];
  selectedEvent: any;
  selectedUsers: any = [];
  usersList: any = [];
  userPrompts: any = [];
  selectedPromptIds: any = [];

  jobReportTypes = ['Completed', 'Cancelled'];
  selectedJobReportType: String = 'Completed';

  activeTab: string = this.uiTabs.tab1;

  reportContactDataDetail = [
    {
      header: 'Report',
      sortKey: 'report_name',
      searchKey: 'report_name',
      tableColumnStyle: {
        'white-space': 'pre-line',
      },
      nestedValue: (row: any) => {
        return row?.report_name ? `${row?.report_name}` : 'Completed Jobs';
      },
      suffixNestedValue: (row: any) => {
        return row?.report_name ? `\n[Patrols]` : '';
      },
      suffixStyle: {
        'font-size': '0.8rem',
      },
    },
    {
      header: 'Site',
      name: 'site_name',
      sortKey: 'site_name',
      searchKey: 'site_name',
      nestedValue: (row: any) => {
        return row?.site_name ? row.site_name : row?.company_name;
      },
      suffixNestedValue: (row: any) => {
        return row?.site_name ? `\n${row?.company_name}` : null;
      },
      suffixStyle: {
        'font-size': 'small',
      },
      tableColumnStyle: {
        'white-space': 'pre-line',
      },
    },
    { header: '', name: '', showAction: true },
  ];
  reportContactDataList: any = [];
  reportContactDataListTemp: any = [];
  reportContactDataDetailActionColumn = [
    {
      condition: function (row: any) {
        return {
          icon: 'fa-solid fa-link',
          title: 'Go to Report',
          type: 'redirect',
        };
      },
    },
  ];
  filterValue = { contact: '', site: '' };

  showSummaryOnly: boolean = false;

  reportSiteClientLookup: any = {
    client: {
      name: 'Client',
      value: '',

      data: null,
    },
    site: { name: 'Site', value: '', data: null },
  };
  siteListData: any = [];
  initialDataList: any = {};

  constructor(
    private userService: UsersService,
    private spinnerService: LoadingSpinnerService,
    private clientService: ClientsService,
    private dialogService: ModelDialogueService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private viewContainerRef: ViewContainerRef,
    private toasterService: ToasterService,
    private userPromptService: UserPromptService
  ) {
    this.dateRange = getInitalDateRangeForListData(
      this.userEventDetailsMobileView?.individualDetails || []
    );
  }

  ngOnInit(): void {
    this.getHomeScreenPrompts();
    this.getReportContactList();
    this.searchClients('');
    this.onSiteSearch('');
    [this.userEventDetailsTableView, this.userEventDetailsMobileView] =
      this.getPatrolTableDetails();

    const savedTab = sessionStorage.getItem('selectedTab');
    if (savedTab) {
      this.changeTab(savedTab);
    }
  }

  resetVariables() {
    this.reportSiteClientLookup = {
      client: {
        name: 'Client',
        value: '',
        data: null,
      },
      site: {
        name: 'Site',
        value: '',
        data: null,
      },
    };

    this.showSummaryOnly = false;
    this.totalPages = 0;
    this.selectedUsers = [];
    this.selectedPromptIds = [];
    this.selectedEvent = null;
    this.sortKeys = [];
    this.searchParams = null;
    this.userEvents = [];
    this.otherParams.paginationData = {};
    this.dateRange = null;
    this.selectedJobReportType === 'Completed';
    [this.userEventDetailsTableView, this.userEventDetailsMobileView] =
      this.getPatrolTableDetails();

    this.dateRange = getInitalDateRangeForListData(
      this.userEventDetailsMobileView?.individualDetails || []
    );
  }
  changeTab(event: any) {
    this.activeTab = event;

    // come back to the last selected tab if redirectd back/reloaded
    sessionStorage.setItem('selectedTab', this.activeTab);
    this.resetVariables();
    switch (this.activeTab) {
      case this.uiTabs.tab1:
        this.sortKeys = ['updated_at'];
        [this.userEventDetailsTableView, this.userEventDetailsMobileView] =
          this.getPatrolTableDetails();

        break;
      case this.uiTabs.tab2:
        this.userEventDetailsTableView = [
          ...this.jobCompletedReportTableDetails,
        ];
        this.userEventDetailsMobileView = {
          ...this.jobReportDetailsMobile,
        };
        this.getEventLogData();

        break;
      case this.uiTabs.tab3:
        this.sortKeys = ['updated_at'];
        [this.userEventDetailsTableView, this.userEventDetailsMobileView] =
          this.getPatrolTableDetails();

        this.getEventLogData();
        break;
      case this.uiTabs.tab4:
        this.sortKeys = ['updated_at'];
        this.userEventDetailsTableView = [
          {
            header: 'Date',
            name: 'updated_at',
            datetimeObj: true,
            dateRangeKey: 'updated_at',
            sortKey: 'updated_at',
            ...getDateTimeRange(3),
          },
          {
            header: 'Prompt',
            nestedValue: (row: any) => {
              const promptNames = row?.prompts?.map(
                (prompt: any) => prompt?.prompt_data?.name
              );
              return promptNames?.join(',');
            },
          },

          {
            header: 'Site',
            name: 'site_name',
            searchKey: 'site_name',
          },
          {
            header: 'Guard',
            name: 'user_name',
            sortKey: 'user_name',
            searchKey: 'user_name',
          },
        ];
        this.userEventDetailsMobileView = {
          individualDetails: [
            {
              name: 'updated_at',
              datetimeObj: true,
              dateRangeKey: 'updated_at',
              header: 'Event Time',
              ...getDateTimeRange(3),
              sortKey: 'updated_at',
              cardRowStyle: {
                'font-size': 'small',
                'float': 'right',
              },
            },
            {
              name: 'user_name',
              cardRowStyle: {
                'font-size': 'small',
                'float': 'end',
              },
            },
            {
              header: 'Prompt',
              cardRowStyle: {
                'font-size': 'small',
                'font-weight': 'bold',
              },
              nestedValue: (row: any) => {
                const promptNames = row?.prompts?.map(
                  (prompt: any) => prompt?.prompt_data?.name
                );
                return promptNames?.join(',');
              },
            },

            {
              header: 'Site',
              name: 'site_name',
            },
          ],
        };
        break;
      case this.uiTabs.tab5:
        break;
    }
  }
  getEventLogData() {
    let body: any = {
      time_offset: new Date().getTimezoneOffset(),
    };

    if (this.dateRange) body.date_range = this.dateRange;
    if (this.sortKeys) body.sort_key = this.sortKeys;
    if (this.searchParams) body.search_filters = this.searchParams;
    if (this.pageSize) body.rows = this.pageSize;
    if (this.previous) body.previous = this.previous;

    switch (this.activeTab) {
      case this.uiTabs.tab1:
        if (
          !this.reportSiteClientLookup?.site?.data?.id &&
          !this.reportSiteClientLookup?.client?.data?.id
        ) {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: 'Select a Site/Client',
          });
          return;
        }
        body.event_type = 'patrol';
        if (this.reportSiteClientLookup?.site?.data?.id) {
          body.site_id = this.reportSiteClientLookup?.site?.data?.id;
        } else {
          body.company_id = this.reportSiteClientLookup?.client?.data?.id;
        }

        break;
      case this.uiTabs.tab2:
        body.event_type = 'job_report';
        body.job_report_type = this.selectedJobReportType?.toLowerCase();
        break;
      case this.uiTabs.tab3:
        if (this.reportSiteClientLookup?.site?.data?.id) {
          body.site_id = this.reportSiteClientLookup?.site?.data?.id;
        }
        if (this.reportSiteClientLookup?.client?.data?.id) {
          body.company_id = this.reportSiteClientLookup?.client?.data?.id;
        }
        body.event_type = 'exceptions';
        break;
      case this.uiTabs.tab4:
        if (!this.selectedPromptIds?.length) return;
        if (
          !this.reportSiteClientLookup?.site?.data?.id &&
          !this.reportSiteClientLookup?.client?.data?.id
        ) {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: 'Select a Site/Client',
          });
          return;
        }

        body.event_type = 'patrol';
        body.user_prompt_ids = this.selectedPromptIds;
        if (this.reportSiteClientLookup?.site?.data?.id) {
          body.site_id = this.reportSiteClientLookup?.site?.data?.id;
        } else {
          body.company_id = this.reportSiteClientLookup?.client?.data?.id;
        }

        break;
    }
    this.spinnerService.show();
    this.userService.getUserUpdateLog(body).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.allowPDFExport = true;
        this.allowBillingReportExport = true;
        this.selectedEvent = null;
        this.spinnerService.hide();
        this.totalRows = response['total_size'];
        this.otherParams.paginationData = {
          totalRows: this.totalRows,
          previousRows: this.previous,
          rows: this.pageSize,
          pageNum: this.pageNum,
          totalPages: Math.ceil(this.totalRows / this.pageSize),
        };
        this.userEvents = response['data'];

        this.totalPages = Math.ceil(this.totalRows / this.pageSize);
      } else {
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: response['message'],
        });
        this.spinnerService.hide();
      }
    });
  }

  jobReportSelectRadio(event: any) {
    this.selectedJobReportType = event.target.value;
    if (this.selectedJobReportType === 'Completed') {
      this.userEventDetailsTableView = this.jobCompletedReportTableDetails;
    } else {
      this.userEventDetailsTableView = this.jobCancelledReportTableDetails;
    }
    this.dateRange = getInitalDateRangeForListData(
      this.userEventDetailsMobileView?.individualDetails || []
    );
    this.getEventLogData();
  }
  shiftFocus(elementId: string) {
    setTimeout(() => {
      var element = <HTMLInputElement>document.getElementById(elementId);
      element?.focus();
    }, 200);
  }
  getHomeScreenPrompts() {
    this.userPromptService
      .getHomeUserPromptList({})
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.userPrompts = response?.data;
        }
      });
  }
  getReportContactList() {
    this.spinnerService.show();

    let body: any = {
      event_type: 'report_contacts',
    };

    this.userService.getUserUpdateLog(body).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.reportContactDataList = response['data'];
        this.reportContactDataListTemp = response['data'];
        this.spinnerService.hide();
      } else {
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: response['message'],
        });
        this.spinnerService.hide();
      }
    });
  }

  getTimeDifference(rowData: any) {
    const { on_site_date, off_site_date } = rowData;

    // * onsite and offsite time exists; else return null
    // * onsite and offsite hour and minute match || onsite > offsite; return 0;
    // * onsite < offsite; return difference

    if (off_site_date && on_site_date) {
      let date1 = new Date(on_site_date);
      let date2 = new Date(off_site_date);

      if (
        date1 > date2 ||
        (date1.getHours() === date2.getHours() &&
          date1.getMinutes() === date2.getMinutes())
      ) {
        return 0;
      }

      const diffInMs = date2.getTime() - date1.getTime();
      const diffInHours = diffInMs / (1000 * 60 * 60);
      const hours = Math.floor(diffInHours);
      const minutes = Math.round((diffInHours - hours) * 60);

      // Pad the hours and minutes with leading zeros
      const hours_str = hours < 10 ? '0' + hours : hours;
      const minutes_str = minutes < 10 ? '0' + minutes : minutes;

      return `${hours_str}:${minutes_str}`;
    } else {
      return 0;
    }
  }

  onTableRowClick(event: any) {
    this.scrollPosition = window.scrollY;
    this.selectedEvent = event;
    if (this.largeView) window.scrollTo(0, document.body.scrollHeight);
  }
  onChangePagination(data: any) {
    this.previous = data?.paginationData?.previousRows;
    this.pageNum = data?.paginationData?.pageNum;
    this.pageSize = data?.paginationData?.rows;
    this.getEventLogData();
    this.otherParams.paginationData = { ...data?.paginationData };
  }
  sortColumn(body: any) {
    this.sortKeys = body?.sortList || [];
    this.searchParams = body?.searchParams || {};
    this.getEventLogData();
  }
  searchColumn(event: any) {
    this.dateRange = null;
    this.searchParams = event?.searchParams || {};
    this.getEventLogData();
  }

  onClientSearch(event: any) {
    if (
      event.target.value.length === 3 ||
      (event.target.value.length > 3 && this.clientDropDownList?.length)
    ) {
      this.searchClients(event.target.value);
    }
  }
  searchClients(searchStr?: any) {
    this.clientService.searchClients(searchStr).subscribe((res: any) => {
      if (res['status'] == 'success') {
        this.clientDropDownList = res?.data;
        if (!searchStr) {
          this.initialDataList.client = JSON.parse(
            JSON.stringify(this.clientDropDownList)
          );
        }
      }
    });
  }
  onClientSelect(data: any) {
    this.resetVariables();
    this.reportSiteClientLookup = {
      client: {
        name: 'Client',
        value: data?.company_name,

        data: data,
      },
      site: { name: 'Site', value: '', data: null },
    };
    this.sortKeys = ['updated_at'];
    this.getEventLogData();
  }
  onSiteSearch(event?: any) {
    const searchString = event ? event.target.value : '';

    this.clientService
      .searchSites({
        search_str: searchString,
      })
      .subscribe((res: any) => {
        if (res['status'] == 'success') {
          this.siteListData = res?.data?.splice(0, 10);
          if (!event) {
            this.initialDataList.site = JSON.parse(
              JSON.stringify(this.siteListData)
            );
          }
        }
      });
  }
  onSelectSite(data: any) {
    this.resetVariables();
    this.reportSiteClientLookup = {
      client: {
        name: 'Client',
        value: '',

        data: null,
      },
      site: {
        name: 'Site',
        value: data?.company_name,

        data: data,
      },
    };
    this.sortKeys = ['updated_at'];
    this.getEventLogData();
  }

  exportData(exportType: any) {
    let userIds = [...new Set([...this.selectedUsers].map((item) => item.id))];
    let body: any = {
      export_type: exportType,
      user_ids: userIds,
      time_offset: new Date().getTimezoneOffset(),
    };
    if (this.dateRange) body.date_range = this.dateRange;
    if (this.sortKeys) body.sort_key = this.sortKeys;
    if (this.searchParams) body.search_filters = this.searchParams;
    switch (this.activeTab) {
      case this.uiTabs.tab1:
        if (
          !this.reportSiteClientLookup?.site?.data?.id &&
          !this.reportSiteClientLookup?.client?.data?.id
        ) {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: 'Select a Site/Client',
          });
          return;
        }
        if (!userIds?.length) return;

        body.event_type = 'patrol';
        if (this.reportSiteClientLookup?.site?.data?.id) {
          body.site_id = this.reportSiteClientLookup?.site?.data?.id;
        } else {
          body.company_id = this.reportSiteClientLookup?.client?.data?.id;
        }
        body.summary_only = this.showSummaryOnly;

        break;
      case this.uiTabs.tab2:
        body.event_type = 'job_report';
        body.job_report_type = this.selectedJobReportType?.toLowerCase();
        break;
      case this.uiTabs.tab3:
        if (this.reportSiteClientLookup?.site?.data?.id) {
          body.site_id = this.reportSiteClientLookup?.site?.data?.id;
        }
        if (this.reportSiteClientLookup?.client?.data?.id) {
          body.company_id = this.reportSiteClientLookup?.client?.data?.id;
        }
        body.event_type = 'exceptions';
        break;
      case this.uiTabs.tab4:
        if (!this.selectedPromptIds?.length) return;
        if (
          !this.reportSiteClientLookup?.site?.data?.id &&
          !this.reportSiteClientLookup?.client?.data?.id
        ) {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: 'Select a Site/Client',
          });
          return;
        }

        body.event_type = 'patrol';
        body.user_prompt_ids = this.selectedPromptIds;
        if (this.reportSiteClientLookup?.site?.data?.id) {
          body.site_id = this.reportSiteClientLookup?.site?.data?.id;
        } else {
          body.company_id = this.reportSiteClientLookup?.client?.data?.id;
        }

        break;
    }

    this.spinnerService.show();
    this.userService.exportUserUpdateLog(body).then((response: any) => {
      if (response['status'] == 'success') {
        this.toasterService.setMessage({
          successMessage: response['message'],
          errorMessage: '',
        });

        this.restartEverything();
        this.spinnerService.hide();
      } else {
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: response['message'],
        });
        this.spinnerService.hide();
      }
    });
  }
  restartEverything() {
    this.allowBillingReportExport = false;
    this.allowPDFExport = false;
    this.userPrompts?.forEach((item: any) => {
      item.selected = false;
    });
    this.resetVariables();
    this.reportSiteClientLookup = {
      client: {
        name: 'Client',
        value: '',
        data: null,
      },
      site: {
        name: 'Site',
        value: '',
        data: null,
      },
    };
  }

  hideDetails() {
    this.selectedEvent = null;

    // let scrollPosition: any = window.localStorage.getItem('scrollToView');
    setTimeout(() => {
      window.scrollTo(0, this.scrollPosition);
    }, 100);
  }

  openTemplate(template?: any) {
    this.userPrompts?.forEach((item: any) => {
      item.selected = false;
    });
    this.dialogService.open(
      template,
      {
        data: {},
      },
      this.viewContainerRef
    );
  }
  removeUser(deleteUser: any): void {
    this.selectedUsers = this.selectedUsers.filter(
      (user: any) => user?.id !== deleteUser?.id
    );
  }

  addUser(id: string, selectedUser: any): void {
    if (selectedUser?.key === 0) {
      this.addToContactsTemp();
    } else {
      if (this.selectedUsers.some((user: any) => user?.id === id)) {
        this.toasterService.setMessage({
          errorMessage: 'User already added',
          successMessage: '',
        });
      } else {
        this.selectedUsers.push({
          id: id,
          name:
            selectedUser?.user_full_name ||
            selectedUser?.full_name ||
            selectedUser?.email ||
            selectedUser.user_email,

          email: selectedUser?.email || selectedUser.user_email,
          mobile_number: selectedUser?.mobile_number,
        });
        this.usersList = this.usersList?.filter(
          (value1: any) =>
            !this.selectedUsers.some((value2: any) => value1.pk === value2.id)
        );
      }
    }
  }
  getUsers(event: any = null) {
    let value = event?.target?.value;
    let params: any = { union: 1 };
    if (value) {
      params['search_str'] = value;
    }
    if (this.reportSiteClientLookup?.site?.data?.id) {
      params.site_id = this.reportSiteClientLookup?.site?.data?.id;
    }
    if (this.reportSiteClientLookup?.client?.data?.id) {
      params.company_id = this.reportSiteClientLookup?.client?.data?.id;
    }

    this.clientService
      .searchLocationContacts(params)
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          let users = response['data'];
          users = users.filter(
            (value1: any) =>
              !this.selectedUsers.some((value2: any) => value1.pk === value2.id)
          );
          this.usersList = users;
        } else {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
      });
  }

  addToContactsTemp() {
    let data: any = {};
    if (this.reportSiteClientLookup?.site?.data?.id) {
      data = {
        siteData: this.reportSiteClientLookup?.site?.data,
        patrolReportUsers: true,
        contactType: 'lc',
      };
    }
    if (this.reportSiteClientLookup?.client?.data?.id) {
      data = {
        clientData: this.reportSiteClientLookup?.client?.data,
        patrolReportUsers: true,
        contactType: 'lc',
      };
    }

    const dialogRef = this.dialogService.open(ContactComponent, {
      data: data,
    });
    dialogRef.afterClosed().subscribe((value: any) => {
      this.spinnerService.hide();
      if (value !== 'false' && value?.length) {
        let lastAddedContact = value[value?.length - 1];
        if (lastAddedContact)
          this.addUser(lastAddedContact?.id, lastAddedContact);
      }
    });
  }

  onJobTableRowClick(event: any) {
    this.spinnerService.show();
    this.router.navigateByUrl(`/job-detail/${event?.job_key}`, {
      state: event?.job_id,
    });
    window.localStorage.setItem('urlId', event?.job_id);
  }
  mailBillingReport() {
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        title: 'Send Report to your email',
      },
    });
    dialogRef.afterClosed().subscribe((value: any) => {
      if (value === true) {
        this.spinnerService.show();

        let timezoneOffset = new Date();
        let body: any = {
          event_type: 'job_report',
          export_type: 'csv',
          time_offset: timezoneOffset.getTimezoneOffset(),
          job_report_type: this.selectedJobReportType?.toLowerCase(),
        };

        if (this.dateRange) body.date_range = this.dateRange;

        if (this.sortKeys) body.sort_key = this.sortKeys;
        if (this.searchParams) body.search_filters = this.searchParams;

        this.userService.exportUserUpdateLog(body).then((response: any) => {
          if (response['status'] == 'success') {
            this.toasterService.setMessage({
              successMessage: response['message'],
              errorMessage: '',
            });
            this.restartEverything();

            this.spinnerService.hide();
          } else {
            this.toasterService.setMessage({
              successMessage: '',
              errorMessage: response['message'],
            });
            this.spinnerService.hide();
          }
        });
      }
    });
  }
  selectUserPrompt(promtData: any) {
    promtData.selected = !promtData?.selected;
    this.selectedPromptIds = [
      ...new Set(
        this.userPrompts
          ?.filter((item: any) => item?.selected)
          ?.map((item: any) => item?.id)
      ),
    ];
  }

  onReportContactActionIconClick(event: any) {
    if (event?.type == 'redirect') {
      if (event?.event?.site_uuid) {
        this.router.navigate(
          ['/view-site', { sKey: String(event?.event?.site_uuid) }],
          { fragment: event?.event?.report_uuid ? 'pc' : 'jc' }
        );
      } else {
        this.router.navigate(
          ['/view-client', { cKey: String(event?.event?.company_uuid) }],
          { fragment: event?.event?.report_uuid ? 'pc' : 'jc' }
        );
      }
    }
  }
  filterByContact(contact: any, searchStr: string) {
    return searchStr
      ? contact?.contact_data?.full_name?.toLowerCase()?.includes(searchStr) ||
          contact?.contact_data?.email?.toLowerCase()?.includes(searchStr)
      : contact;
  }
  expandCollapseContactTable(selectedContact: any) {
    selectedContact.contact_data.expanded =
      !selectedContact?.contact_data?.expanded;
    this.reportContactDataListTemp?.forEach((contact: any) => {
      if (contact?.contact_data?.email === selectedContact?.contact_data?.email)
        contact.contact_data.expanded = selectedContact.contact_data.expanded;
    });
  }

  filterReportContacts(filterBy: string = 'site') {
    this.reportContactDataList = JSON.parse(
      JSON.stringify(this.reportContactDataListTemp)
    );
    if (this.filterValue.site) {
      this.reportContactDataList?.forEach((contact: any) => {
        const existingReports = [...contact?.reports];

        contact.reports = existingReports?.filter(
          (report: any) =>
            report?.site_name
              ?.toLowerCase()
              ?.includes(this.filterValue.site?.toLowerCase()) ||
            report?.company_name
              ?.toLowerCase()
              ?.includes(this.filterValue.site?.toLowerCase())
        );
      });
    }

    this.reportContactDataList = this.reportContactDataList?.filter(
      (contact: any) =>
        this.filterByContact(
          contact,
          this.filterValue.contact?.toLowerCase()
        ) && contact?.reports?.length
    );
  }
  editField(field: string) {
    this.selectedEvent = null;
    this.reportSiteClientLookup.site = {
      name: 'Site',
      value: '',
      data: null,
    };
    this.reportSiteClientLookup.client = {
      name: 'Client',
      value: '',
      data: null,
    };
    if (field == 'site') {
      this.shiftFocus('siteId');
    }
    if (field == 'client') {
      this.shiftFocus('clientLookupId');
    }

    this.updateInitialDataList(field);
  }
  inputFocused(field: string) {
    this.reportSiteClientLookup.client = {
      name: 'Client',
      value: '',
      data: null,
    };
    this.reportSiteClientLookup.site = {
      name: 'Site',
      value: '',
      data: null,
    };
    if (field === 'site') {
      this.reportSiteClientLookup.client = {
        name: 'Client',
        value: '',
        data: null,
        hide: true,
      };
      setTimeout(() => {
        this.reportSiteClientLookup.client = {
          name: 'Client',
          value: '',
          data: null,
          disabled: true,
        };
      }, 10);
    }
    if (field === 'client') {
      this.reportSiteClientLookup.site = {
        name: 'Site',
        value: '',
        data: null,
        hide: true,
      };
      setTimeout(() => {
        this.reportSiteClientLookup.site = {
          name: 'Site',
          value: '',
          data: null,
          disabled: true,
        };
      }, 10);
    }
    this.updateInitialDataList(field);
  }
  updateInitialDataList(field: any) {
    this.siteListData = [];
    this.clientDropDownList = [];
    setTimeout(() => {
      if (field === 'site') {
        this.siteListData = JSON.parse(
          JSON.stringify(this.initialDataList?.[field] || [])
        );
      } else if (field === 'client') {
        this.clientDropDownList = JSON.parse(
          JSON.stringify(this.initialDataList?.[field] || [])
        );
      }

      this.cdr.detectChanges();
    }, 200);
  }
  getPatrolTableDetails() {
    const patrolTableDetailMobileView = {
      individualDetails: [
        {
          name: 'updated_at',
          datetimeObj: true,
          dateRangeKey: 'updated_at',
          header: 'Event Time',
          ...getDateTimeRange(3),
          sortKey: 'updated_at',
          cardRowStyle: {
            'font-size': 'small',
            'float': 'right',
          },
          sortOrder: 'asc',
        },
        {
          name: 'user_name',
          cardRowStyle: {
            'font-size': 'small',
            'float': 'end',
          },
        },
        {
          name: 'action',
          cardRowStyle: {
            'font-size': 'small',
            'font-weight': 'bold',
            'text-transform': 'uppercase',
          },
        },

        {
          name: 'detail',
          cardRowStyle: {
            'font-size': 'small',
          },
        },
        {
          header: 'Site',
          name: 'site_name',
        },
      ],
    };

    const patrolTableDetail = [
      {
        header: 'Date',
        name: 'updated_at',
        datetimeObj: true,
        dateRangeKey: 'updated_at',
        sortKey: 'updated_at',
        ...getDateTimeRange(3),
        sortOrder: 'asc',
      },
      {
        header: 'Event Type',
        name: 'action',
      },

      {
        header: 'Checkpoint',
        columnDataStyle: {
          'white-space': 'pre-line',
        },
        nestedValue: (row: any) => {
          return [0, 6].includes(row?.event_action)
            ? [row?.detail, row?.address].join('\n')
            : '';
        },
      },
      {
        header: 'Site',
        name: 'site_name',
        searchKey: 'site_name',
      },
      {
        header: 'Guard',
        name: 'user_name',
        sortKey: 'user_name',
        searchKey: 'user_name',
      },
    ];
    return [patrolTableDetail, patrolTableDetailMobileView];
  }
}
