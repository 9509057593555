import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { isLargeScreen, isLargeScreenFun } from '../../../../global.variable';
import { AppService } from '../../../app.service';
import { AddEditSiteComponent } from '../../../shared/components/add-edit-site/add-edit-site.component';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { ModelDialogueService } from '../../../shared/components/modal-dialogue/model-dialogue.service';
import { LoadingSpinnerService } from '../../../shared/services/loading-spinner.service';
import { OrientationLockService } from '../../../shared/services/orientationlock.service';
import { ToasterService } from '../../../shared/services/toaster.service';
import { ClientsService } from '../clients/clients.service';

@Component({
  selector: 'gtapp-sites',
  templateUrl: './sites.component.html',
  styleUrl: './sites.component.scss',
})
export class SitesComponent implements OnInit {
  globalFilter: any;

  previousSite: number = 0;
  pageSizeSite: number = 10;
  pageNumSite: number = 1;
  totalPagesSite: number = 0;
  sitePaginationData: any = {};
  paginationSiteCount: any;
  totalSiteCount: number = 0;

  siteDropDownList: any = [];
  tableStyle = {
    'overflow': 'auto',
    'max-height': '100%',
  };

  dialogref: any;

  sortKey: any;
  searchFilters: any;

  otherParams: any = {};
  largeView: Boolean = isLargeScreen;
  isLargeScreenSubscription: Subscription = new Subscription();

  siteTableDetail: any = [
    {
      header: 'Name',
      name: 'company_name',
      sortKey: 'company_name',
      searchKey: 'company_name',
    },
    {
      header: 'Address',
      nestedValue: (row: any) => {
        return row?.address?.full_address;
      },
    },
    {
      header: 'Client',
      nestedValue: (row: any) => {
        return row?.company?.company_name;
      },
    },
  ];
  siteCardViewDetail = {
    individualDetails: [
      {
        nestedValue: (row: any) => {
          return `${row?.company_name} [${row?.company?.company_name}]`;
        },
        cardRowStyle: { 'font-weight': '600' },
      },
      {
        nestedValue: (row: any) => {
          return row?.address?.full_address;
        },
        cardRowStyle: { 'font-size': 'small' },
      },
    ],
  };
  clientId: any;
  archivedCount: number = 0;
  showArchivedList: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private dialogService: ModelDialogueService,
    private router: Router,
    private appService: AppService,
    private spinnerService: LoadingSpinnerService,
    private orientationService: OrientationLockService,
    private clientService: ClientsService,
    private toasterService: ToasterService
  ) {
    // WHEN USER CLICK THE BACK BUTTON
    router.events.subscribe((event: any) => {
      if (event.navigationTrigger === 'popstate') {
        this.dialogref?.close();
      }
    });
  }
  ngOnDestroy() {
    this.isLargeScreenSubscription.unsubscribe();
  }
  ngOnInit(): void {
    this.clientId = this.route.snapshot.paramMap.get('clientId');
    this.isLargeScreenSubscription =
      this.orientationService.isLargeScreen.subscribe(async (event: any) => {
        if (event) {
          this.largeView = await isLargeScreenFun();
        }
      });
    let userData = this.appService.getUserData();

    this.pageSizeSite = Number(userData?.preferences?.site_page_size || 10);
    this.getSites();
  }

  onSiteLookup(event: any) {
    if (
      event.target.value.length === 3 ||
      (event.target.value.length > 3 && this.siteDropDownList?.length)
    ) {
      this.clientService
        .getSiteList({ search_str: event?.target.value })
        .subscribe((response: any) => {
          if (response['status'] == 'success') {
            this.siteDropDownList = response?.data;
          }
        });
    }
  }
  openAddSiteForm() {
    const dialogRef = this.dialogService.open(AddEditSiteComponent, {
      data: {},
    });
    dialogRef.afterClosed().subscribe((value: any) => {
      if (value !== 'close') {
        this.sitePaginationData = {};
        this.getSites();
      }
    });
  }
  openSiteView(site: any) {
    if (this.showArchivedList) {
      this.archiveUnarchive(site);
    } else {
      this.router.navigate(['/view-site', { sKey: String(site.id) }]);
    }
  }
  getArchivedUnarchivedList() {
    this.showArchivedList = !this.showArchivedList;
    this.clearValues();

    this.spinnerService.show();
    this.getSites();
  }

  getSites() {
    let params: any = { extra_details: 1 };
    if (this.pageSizeSite) {
      params['rows'] = this.pageSizeSite;
      if (this.largeView) {
        const userData = this.appService.getUserData();
        if (userData?.preferences) {
          userData.preferences.site_page_size = this.pageSizeSite;
          this.appService.setUserData(userData);
        }
      }
    }
    if (this.clientId) {
      params['company'] = this.clientId;
    }

    if (this.previousSite) {
      params['previous'] = this.previousSite;
    }
    if (this.globalFilter) {
      params['search_str'] = this.globalFilter;
    }
    if (this.showArchivedList) {
      params['is_deleted'] = 1;
    }

    if (this.searchFilters) {
      Object.entries(this.searchFilters).forEach(
        ([key, value]) => (params[key] = value)
      );
    }

    if (this.sortKey) {
      params['sort_key'] = this.sortKey;
    }

    if (
      !this.sitePaginationData?.[this.pageNumSite]?.length ||
      this.globalFilter
    ) {
      this.spinnerService.show();
      this.clientService.getSiteList(params).subscribe((response: any) => {
        if (response['status'] == 'success') {
          // if (response?.site_count === 0) {
          //   this.openAddSiteForm();
          // }
          this.totalSiteCount = response?.site_count;
          this.paginationSiteCount = response['total_size'];
          this.totalPagesSite = Math.ceil(
            this.paginationSiteCount / this.pageSizeSite
          );
          this.archivedCount = response?.archived_count || 0;
          this.otherParams.paginationData = {
            totalRows: this.paginationSiteCount,
            previousRows: this.previousSite,
            rows: this.pageSizeSite,
            pageNum: this.pageNumSite,
            totalPages: this.totalPagesSite,
          };
          this.sitePaginationData[this.pageNumSite] = [...response['data']];
        }
        this.spinnerService.hide();
      });
    }
  }

  onChangePagination(event: any) {
    this.previousSite = event?.paginationData?.previousRows;
    this.pageNumSite = event?.paginationData?.pageNum;
    if (event?.paginationData?.rows != this.pageSizeSite) {
      this.sitePaginationData = {};
    }
    this.pageSizeSite = event?.paginationData?.rows;
    this.getSites();

    this.otherParams.paginationData = { ...event?.paginationData };
  }

  sortColumn(body: any) {
    this.sortKey = body?.sortList || [];
    this.clearValues();
    this.getSites();
  }
  searchColumn(event: any) {
    this.searchFilters = event?.searchParams;
    this.clearValues();
    this.getSites();
  }
  clearValues() {
    this.previousSite = 0;
    this.previousSite = 0;
    this.pageNumSite = 1;

    this.globalFilter = '';
    this.sitePaginationData = {};
  }
  archiveUnarchive(site: any) {
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        title: `Restore  Site `,
        message: ``,
      },
    });
    dialogRef.afterClosed().subscribe((value) => {
      if (value === true) {
        this.spinnerService.show();
        this.clientService
          .enableDisableSiteById(site?.id)
          .then((response: any) => {
            this.spinnerService.hide();
            if (response?.status == 'success') {
              this.toasterService.setMessage({
                successMessage: response['message'],
                errorMessage: '',
              });
              this.router.navigate(['/view-site', { sKey: String(site.id) }]);
            } else {
              this.toasterService.setMessage({
                successMessage: '',
                errorMessage: response['message'],
              });
            }
          });
      }
    });
  }
}
