<div class="container gt-auth-block">
  <div class="row">
    <div class="col-xs-12 d-md-none d-sm-block">
      <div class="gt-logo-mobile">
        <img
          ngSrc="../../../assets/images/Guard_Tracker-Stacked-{{
            currentTheme
          }}.avif"
          alt="Guard Tracker"
          width="160"
          height="160"
          priority
          as="image"
        />
      </div>
    </div>
    <div class="col-xs-12 d-none d-md-block">
      <div class="gt-logo-desktop">
        <img
          ngSrc="../../../assets/images/Guard_Tracker-Lateral_Long_{{
            currentTheme
          }}.avif"
          alt="Guard Tracker"
          width="384"
          height="96"
          priority
          as="image"
        />
      </div>
    </div>
  </div>

  <ng-container *ngIf="message">
    <div class="alert alert-primary" role="alert">
      {{ message }}
    </div>
  </ng-container>

  <ng-container *ngIf="!userLinkedSubscribers?.length">
    <form [formGroup]="loginForm" (ngSubmit)="login()" aria-labelledby="title">
      <div class="d-flex flex-column gap-2">
        <div class="input-group">
          <div class="form-floating">
            <input
              class="form-control"
              type="email"
              [class.is-invalid]="
                loginForm.controls['username'].invalid &&
                loginForm.controls['username'].touched
              "
              formControlName="username"
              id="username"
              placeholder="Username"
              pattern=".+@.+\..+"
              autocorrect="off"
              autocapitalize="off"
              (input)="checkIfEmailChanged()"
            />
            <label for="username">Email Address</label>
          </div>

          <span
            *ngIf="loginForm.value?.username"
            class="input-group-text"
            (click)="loginForm.reset(); checkIfEmailChanged()"
          >
            <i class="fa-solid fa-xmark md-font-size"></i>
          </span>
        </div>
        <div
          class="text-danger small"
          *ngIf="
            loginForm.controls['username'].invalid &&
            loginForm.controls['username'].touched
          "
        >
          Invalid email address
        </div>

        <div *ngIf="showForm === 'userNameForm'">
          <button
            class="btn btn-lg btn-primary w-100"
            type="submit"
            [disabled]="!loginForm.valid"
          >
            Next
          </button>
        </div>

        <!-- If entered email is in db and this is the first time login of that email -->
        <div *ngIf="showForm === 'vCodeForm'">
          <div class="form-floating">
            <input
              class="form-control"
              type="tel"
              [class.is-invalid]="
                loginForm.controls['auth_code'].invalid &&
                loginForm.controls['auth_code'].touched
              "
              formControlName="auth_code"
              id="verCode"
              placeholder="Verification Code"
              [maxlength]="6"
              autofocus
              autocomplete="off"
              (input)="loginForm.valid ? login() : null"
            />
            <label for="verCode">Verification Code</label>
            <div
              class="invalid-feedback"
              *ngIf="
                loginForm.controls['auth_code'].invalid &&
                loginForm.controls['auth_code'].touched
              "
            >
              Verification code is required!
            </div>
          </div>

          <div class="mt-3">
            <button
              class="btn btn-lg btn-primary w-100"
              [disabled]="!loginForm.valid"
            >
              Verify
            </button>
          </div>
        </div>
        <ng-container
          *ngIf="showForm === 'passwordForm' && !isPasswordAlreadyset"
        >
          <div class="mt-3">
            <button
              class="btn btn-lg btn-primary w-100"
              type="button"
              (click)="sendVCode('oneTime')"
            >
              Get One Time Pin
            </button>
          </div>
        </ng-container>
        <!-- If entered email is in db and user has logged in at least once -->
        <div
          *ngIf="
            (showForm === 'passwordForm' && isPasswordAlreadyset) ||
            showForm === 'dummyPasswordForm'
          "
        >
          <div class="input-group">
            <div class="form-floating">
              <input
                id="passwordInputField"
                class="form-control"
                [type]="showPassword ? 'text' : 'password'"
                [class.is-invalid]="
                  loginForm.controls['password'].invalid &&
                  loginForm.controls['password'].touched
                "
                formControlName="password"
                id="inputPassword"
                placeholder="Password"
                [minlength]="8"
              />

              <label for="inputPassword">Password</label>
            </div>
            <span
              class="input-group-text"
              *ngIf="showPassword === true"
              (click)="hideShowPassword()"
            >
              <i class="fa-regular fa-eye"></i>
            </span>
            <span
              class="input-group-text"
              *ngIf="showPassword === false"
              (click)="hideShowPassword()"
            >
              <i class="fa-regular fa-eye-slash"></i>
            </span>
          </div>
          <div
            class="text-danger small"
            *ngIf="
              loginForm.controls['password'].invalid &&
              loginForm.controls['password'].touched
            "
          >
            Password is required!
          </div>
          <div class="form-check my-2">
            <input
              class="form-check-input"
              type="checkbox"
              id="remember_login"
              name="remember_login"
              formControlName="remember_login"
            />
            <label class="form-check-label small" for="remember_login">
              This is a trusted device.
            </label>

            <!--FIXME: Tooltip currently not available/configured-->
            <span
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              class="ms-2"
              [attr.title]="
                'Checking this box will allow you to perform additional functions including resetting your password and changing your email address'
              "
            >
              <i class="fa-solid fa-circle-info"></i>
            </span>
          </div>
          <div *ngIf="showForm === 'dummyPasswordForm'">
            <!-- No need to show magic link and webauthn option as the email is not in our db -->
            <button
              class="btn btn-lg btn-primary w-100"
              [disabled]="!loginForm.valid"
            >
              Sign In
            </button>
          </div>
          <ng-container *ngIf="isPasswordAlreadyset">
            <div *ngIf="showForm === 'passwordForm'" class="mt-4">
              <!-- Case when the email address is in the db and the user has logged in at least once -->
              <div
                [ngClass]="fidoRegistered ? 'row row-cols-2' : 'row row-cols-1'"
              >
                <div class="col mb-2">
                  <button
                    class="btn btn-lg btn-primary w-100"
                    [disabled]="!loginForm.valid"
                  >
                    Sign In
                  </button>
                </div>
                <div class="col mb-2" *ngIf="fidoRegistered">
                  <button
                    class="btn btn-lg btn-success w-100"
                    type="button"
                    (click)="authenticate()"
                  >
                    Sign In with Passkey
                  </button>
                </div>
              </div>

              <div *ngIf="!hideSignInLInkForgotPasswordBtn">
                <button
                  class="btn btn-lg btn-link text-dark-emphasis w-100 border border-1 border-dark-subtle"
                  type="button"
                  (click)="sendVCode()"
                >
                  Forgot Password
                </button>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </form>
    <div class="mt-2 text-center small">
      {{ currentAppVersion ? currentAppVersion : "" }}
    </div>
  </ng-container>

  <ng-container *ngIf="userLinkedSubscribers?.length">
    <div class="text-center mt-5">
      <h6><em>Select instance</em></h6>
      <div class="row row-cols-1 mt-2">
        <div *ngFor="let subscriber of userLinkedSubscribers" class="col mb-3">
          <div
            class="gt-card h-100 cursor-pointer"
            (click)="selectSubscriber(subscriber)"
          >
            <div class="card-body">
              <div class="fw-bold">{{ subscriber.name }}</div>
              <div>
                {{ subscriber?.address?.full_address }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
