<ng-container *ngIf="inputStringValue && blurCalled">
  <dl class="p-2">
    <div class="d-flex align-items-center">
      <div class="flex-grow-1">
        <dt>{{ dtValue }}</dt>
        <dd>
          {{ inputStringValue }}
        </dd>
      </div>
      <div>
        <span (click)="editField()">
          <i class="fa-solid fa-pen-to-square md-font-size text-secondary"></i
        ></span>
      </div>
    </div>
  </dl>
</ng-container>
<div class="input-group my-2" *ngIf="!blurCalled || !inputStringValue">
  <div class="form-floating">
    <input
      class="form-control"
      autocomplete="off"
      [id]="inputId"
      [type]="inputFieldType"
      [(ngModel)]="inputStringValue"
      [placeholder]="dtValue"
      [maxlength]="maxLength"
      (blur)="
        inputStringValue && inputStringValue.trim()
          ? onBlurEventTrigger()
          : blurEmit.emit(inputStringValue)
      "
    />
    <label>{{ dtValue }}</label>
  </div>

  <span
    class="input-group-text"
    *ngIf="inputStringValue"
    (click)="editField(true)"
  >
    <i class="fa-solid fa-xmark"></i>
  </span>
</div>
