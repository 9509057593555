<div class="row">
  <div class="col-xs-12 d-md-none d-sm-block">
    <div class="gt-logo-mobile">
      <img
        ngSrc="../../../assets/images/Guard_Tracker-Stacked-{{
          currentTheme
        }}.avif"
        alt="Guard Tracker"
        width="160"
        height="160"
        priority
        as="image"
      />
    </div>
  </div>
  <div class="col-xs-12 d-none d-md-block">
    <div class="gt-logo-desktop">
      <img
        ngSrc="../../../assets/images/Guard_Tracker-Lateral_Long_{{
          currentTheme
        }}.avif"
        alt="Guard Tracker"
        width="384"
        height="96"
        priority
        as="image"
      />
    </div>
  </div>
</div>

<div class="gt-p-1">
  <div class="container gt-auth-block">
    <div
      *ngIf="showErrorMsg"
      class="alert alert-warning alert-dismissible fade show text-center"
      role="alert"
    >
      <strong>{{ showErrorMsg }}</strong>
    </div>
    <div
      *ngIf="showSuccessMsg"
      class="alert alert-success alert-dismissible fade show text-center"
      role="alert"
    >
      <strong>{{ showSuccessMsg }}</strong>
    </div>
  </div>
</div>
