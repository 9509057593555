<div class="dialog-box card">
  <div class="row">
    <ng-container *ngIf="currentStatus === 'profileDetails'">
      <form [formGroup]="profileForm" appFormEnterAction>
        <ng-container *ngIf="currentStep === 1">
          <div class="card-header">Enter your details</div>
          <div class="d-flex flex-column gap-2">
            <div class="form-floating">
              <input
                class="form-control"
                type="text"
                id="first_name"
                formControlName="first_name"
                autocomplete="off"
                placeholder="First Name"
                maxlength="150"
                autofocus
              />
              <label>First Name</label>
            </div>
            <div class="form-floating">
              <input
                class="form-control"
                type="text"
                id="last_name"
                formControlName="last_name"
                autocomplete="off"
                placeholder="Last Name"
                maxlength="150"
                autofocus
              />
              <label>Last Name</label>
            </div>
            <div class="form-floating">
              <input
                class="form-control"
                id="mobile_number"
                formControlName="mobile_number"
                autocomplete="off"
                placeholder="Mobile (Optional)"
                autofocus
                type="tel"
                minlength="10"
                maxlength="10"
                pattern="\d*"
              />
              <label>Mobile (Optional)</label>
            </div>
            <div class="col-12 form-floating">
              <select
                class="form-control"
                formControlName="time_zone"
                id="timeZone"
                (change)="onTimeZoneChange($event)"
              >
                <option
                  *ngFor="let timeZone of stateTimeZones"
                  [value]="timeZone.value"
                >
                  {{ timeZone.value }} {{ timeZone.gmtOffset }}
                </option>
              </select>
              <label for="timeZone">Timezone</label>
            </div>
          </div>

          <div class="row row-col-2 mt-3">
            <div class="col col-6 offset-6">
              <button
                class="float-end"
                ngClass="btn btn-success btn-lg w-100"
                [disabled]="!profileForm.valid"
                *ngIf="isSuperUser === true"
                type="button"
              >
                Submit
              </button>
              <button
                type="button"
                *ngIf="!isSuperUser"
                class="btn btn-primary btn-lg w-100"
                [disabled]="!profileForm.valid"
                (click)="currentStep = 2"
              >
                Next
              </button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="currentStep === 2">
          <form [formGroup]="licenseForm" appFormEnterAction>
            <div class="card-header">Add a security licence(Optional)</div>
            <div class="d-flex flex-column gap-2">
              <div class="form-floating">
                <!-- <label for="issuer_state">State</label> -->
                <select
                  class="form-select form-select-lg"
                  id="issuer_state"
                  formControlName="issuer_state"
                  (change)="selectState($event)"
                  placeholder="Select State"
                >
                  <option *ngFor="let state of stateData" [value]="state.code">
                    {{ state.code }}
                  </option>
                </select>
                <label for="issuer_state">State</label>
              </div>
              <div>
                <gtapp-auto-complete
                  [data]="licenseClassData"
                  placeHolder="Licence Class / Category"
                  searchKeyword="license_class_name"
                  [initialValue]="licenseClassName"
                  [itemTemplate]="licenceTemplate"
                  [addInitialKey]="true"
                  (inputChanged)="onLicenceClassSearch($event)"
                  (selected)="selectClass($event)"
                >
                </gtapp-auto-complete>
              </div>

              <ng-template #licenceTemplate let-item>
                <div>
                  {{ item.license_class_name }}
                </div>
              </ng-template>
              <div class="form-floating">
                <input
                  class="form-control"
                  type="text"
                  id="license_number"
                  formControlName="license_number"
                  autocomplete="off"
                  placeholder="Security Licence ID Number"
                />
                <label for="license_number">Security Licence ID Number</label>
              </div>
              <div class="form-floating">
                <input
                  class="form-control"
                  type="date"
                  placeholder="Licence Expiry"
                  formControlName="valid_till"
                  [min]="validDate"
                />
                <label for="license_number">Licence Expiry</label>
              </div>
            </div>
          </form>

          <div class="row row-cols-2 mt-3">
            <div class="col-6">
              <button
                type="button"
                class="btn btn-dark btn-lg w-100"
                (click)="currentStep = 1"
              >
                Back
              </button>
            </div>
            <div class="col-6" *ngIf="!licenseForm.valid">
              <button
                type="button"
                (click)="updateProfileData()"
                class="btn btn-primary btn-lg w-100"
                [disabled]="licenseForm.valid"
              >
                Skip & Submit
              </button>
            </div>
            <div class="col-6" *ngIf="licenseForm.valid">
              <button
                type="button"
                (click)="updateProfileData()"
                class="btn btn-primary btn-lg w-100"
              >
                Submit
              </button>
            </div>
          </div>
        </ng-container>
      </form>
    </ng-container>
    <ng-container *ngIf="currentStatus === 'passwordSet'">
      <div class="card-header">
        {{ addCancelButton ? "Change Password" : "Set Password" }}
        <span
          *ngIf="addCancelButton"
          class="float-end"
          (click)="onCloseDialogue()"
        >
          <i class="fa-solid fa-xmark"></i>
        </span>
      </div>
      <form
        [formGroup]="passwordForm"
        (ngSubmit)="changePassword()"
        appFormEnterAction
      >
        <div class="row row-cols-1 g-3 mt-1">
          <div>
            <div class="input-group">
              <div class="form-floating">
                <input
                  class="form-control"
                  [type]="showPassword ? 'text' : 'password'"
                  name="password"
                  id="password"
                  formControlName="password"
                  placeholder="New Password"
                  minlength="8"
                  [ngClass]="{
                    'is-invalid':
                      passwordForm.controls['password'].dirty &&
                      passwordForm.controls['password'].invalid,
                    
                  }"
                />
                <label for="password">New Password</label>
              </div>
              <span
                class="input-group-text"
                *ngIf="showPassword === true"
                (click)="toggleShowPassword()"
              >
                <i class="fa-regular fa-eye"></i>
              </span>
              <span
                class="input-group-text"
                *ngIf="showPassword === false"
                (click)="toggleShowPassword()"
              >
                <i class="fa-regular fa-eye-slash"></i>
              </span>
            </div>

            <small
              *ngIf="passwordForm.controls['password'].hasError('minlength')"
              class="text-danger"
            >
              Must have at least 8 characters
            </small>
          </div>
          <div>
            <div class="form-floating">
              <input
                class="form-control"
                type="password"
                name="confirm_password"
                id="confirm_password"
                formControlName="confirm_password"
                placeholder="Confirm Password"
                minlength="8"
                [ngClass]="{
                  'is-invalid':
                    passwordForm.controls['confirm_password'].dirty &&
                    passwordForm.controls['confirm_password'].invalid,
                  
                }"
              />
              <label for="confirm_password">Confirm Password</label>
            </div>
            <small
              class="text-danger"
              *ngIf="
                passwordForm.controls['confirm_password'].value &&
                passwordForm.controls['confirm_password'].invalid
              "
            >
              Passwords do not match
            </small>
          </div>
        </div>

        <div class="row row-cols-2 mt-3">
          <div class="col" *ngIf="addCancelButton">
            <button
              class="btn btn-dark btn-lg w-100"
              (click)="onCloseDialogue()"
            >
              Cancel
            </button>
          </div>
          <div class="col" [ngClass]="addCancelButton ? '' : 'offset-6'">
            <button
              [disabled]="!passwordForm.valid"
              type="submit"
              class="btn btn-primary btn-lg w-100"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </ng-container>
  </div>
</div>
