import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  browserGpsInfo,
  checkScanDistanceGreater,
  getEventUpdateDetails,
  isLargeScreen,
} from '../../../../global.variable';
import { AppService } from '../../../app.service';
import { userUpdateTable } from '../../../pages/pages/tableDetails';
import { DataCheckService } from '../../services/data-check.service';
import { ImagePreviewComponent } from '../image-preview/image-preview.component';
import { ModelDialogueService } from '../modal-dialogue/model-dialogue.service';

@Component({
  selector: 'gtapp-selected-event-detail',
  templateUrl: './selected-event-detail.component.html',
  styleUrl: './selected-event-detail.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectedEventDetailComponent implements OnInit, OnChanges {
  @Input() selectedEvent: any;
  @Input() selectedEventFrom: string = 'job';
  @Input() extraCheck: boolean = false;
  @Input() userEventDetailsTableView: any;

  @Output() editUserComment = new EventEmitter();
  @Output() deleteRestoreUserUpdate = new EventEmitter();
  @Output() goBack = new EventEmitter();

  browserGpsInfo: any = browserGpsInfo;
  canEditEventUpdate: boolean = false;
  canDeleteRestoreCommentPhoto: boolean = false;
  lastCommentEditTime: any;

  subscriberUserId: any;
  isAdmin: boolean = false;
  isDispatchUser: boolean = false;
  scanDistanceInfo: any = {};

  isLargeScreen = isLargeScreen;

  restoredDetails: any;
  deletedDetails: any;
  editedDetails: any;
  constructor(
    private dataCheckService: DataCheckService,
    private appService: AppService,
    private dialogService: ModelDialogueService
  ) {}
  ngOnInit(): void {
    this.isAdmin = this.dataCheckService.isUserAdmin();
    this.isDispatchUser = this.dataCheckService.isDispatchUser();
    let userData = this.appService.getUserData();

    this.subscriberUserId = userData?.subscriber?.subscriber_user_id;

    if (!this.userEventDetailsTableView) {
      this.userEventDetailsTableView = userUpdateTable.desktopTable;
    }
    this.fetchResults();
    this.appService.initializeBootstrapTooltips();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (!this.isLargeScreen)
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 50);

    this.fetchResults();
  }
  fetchResults() {
    this.scanDistanceInfo = checkScanDistanceGreater(this.selectedEvent);
    if (this.selectedEvent.checkpoint_details) {
      this.selectedEvent.checkpoint_details.scanDistanceInfo =
        this.scanDistanceInfo;
    }
    this.canEditEventUpdate = this.canEditEventUpdateCheck();
    // this.getDetails();
    // this.restoredDetails = this.getRestoreDetails();
    // this.deletedDetails = this.getDeleteDetails();
    // this.editedDetails = this.getEditedDetails();
    const details = getEventUpdateDetails(
      this.selectedEvent,
      this.selectedEventFrom
    );

    this.restoredDetails = details?.restore;
    this.deletedDetails = details?.delete;
    this.editedDetails =
      details?.editedPatrol || details?.editedJob || details?.editedIncident;

    this.canDeleteRestoreCommentPhoto = this.canDeleteRestorUpdate();

    if (
      this.selectedEvent?.history_changes?.length &&
      (this.subscriberUserId == this.selectedEvent?.subs_user_id ||
        this.isAdmin ||
        this.isDispatchUser)
    ) {
      let sortedComments = this.selectedEvent?.history_changes?.sort(
        (a: any, b: any) =>
          new Date(a?.updated_at).getTime() - new Date(b?.updated_at).getTime()
      );
      this.lastCommentEditTime = sortedComments[sortedComments?.length - 1];
    }

    if (
      this.selectedEvent?.checkpoint_details?.id &&
      [0, 6].includes(this.selectedEvent?.event_action)
    ) {
      this.selectedEvent.gps.gpsToolTip = 'Scan Location';
    }
  }

  hideDetails() {
    this.goBack.emit();
  }
  canDeleteRestorUpdate() {
    switch (this.selectedEventFrom) {
      case 'job':
        return (
          (this.subscriberUserId == this.selectedEvent?.subs_user_id ||
            this.isAdmin ||
            this.isDispatchUser) &&
          [7, 8, 19].includes(this.selectedEvent?.event_action) &&
          this.extraCheck
        );

      case 'user_update_history':
        return (
          (this.subscriberUserId == this.selectedEvent?.subs_user_id ||
            this.isAdmin) &&
          this.selectedEvent?.event_type === 'Patrol Update' &&
          [1, 2, 7].includes(this.selectedEvent?.event_action) &&
          new Date().getTime() -
            new Date(this.selectedEvent?.updated_at).getTime() <
            3600 * 1000 &&
          this.extraCheck
        );
    }
    return false;
  }

  canEditEventUpdateCheck() {
    switch (this.selectedEventFrom) {
      case 'job':
        return (
          (this.subscriberUserId == this.selectedEvent?.subs_user_id ||
            this.isAdmin ||
            this.isDispatchUser) &&
          [7, 8, 19].includes(this.selectedEvent?.event_action) &&
          this.extraCheck
        );
      case 'patrol':
        return (
          this.subscriberUserId == this.selectedEvent?.subs_user_id &&
          this.selectedEvent?.company_id &&
          [1, 2, 7].includes(this.selectedEvent?.event_action) &&
          new Date().getTime() -
            new Date(this.selectedEvent?.updated_at).getTime() <
            72 * 3600 * 1000 &&
          this.extraCheck
        );
      case 'user_update_history':
        return (
          this.subscriberUserId == this.selectedEvent?.subs_user_id &&
          this.selectedEvent?.company_id &&
          this.selectedEvent?.event_type === 'Patrol Update' &&
          [1, 2, 7].includes(this.selectedEvent?.event_action) &&
          new Date().getTime() -
            new Date(this.selectedEvent?.updated_at).getTime() <
            72 * 3600 * 1000 &&
          this.extraCheck
        );
    }
    return false;
  }

  getLastCommentEditedDateTime(history: any) {
    let sortedComments = history?.sort(
      (a: any, b: any) =>
        new Date(a?.updated_at).getTime() - new Date(b?.updated_at).getTime()
    );
    return sortedComments[sortedComments?.length - 1];
  }
  onOpenFile(file: any) {
    this.dialogService.open(ImagePreviewComponent, {
      data: { imageSrc: file?.file, timeStamp: file.updated_at },
    });
  }
}
