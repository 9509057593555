import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { AppService } from './app.service';
import { DeviceInfoService } from './shared/services/device-info.service';
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  userId: any;
  constructor(
    private appService: AppService,
    private deviceInfoService: DeviceInfoService
  ) {
    const userData = JSON.parse(localStorage.getItem('userData') || '{}');
    this.userId = userData?.profile?.id;
  }

  async handleError(error: any) {
    let stackDetails: any = {};
    let errorMessage: string = '{}';
    if (error instanceof HttpErrorResponse) {
      if (environment.devMode) console.log(error);
      stackDetails = await this.getStackDetails(error);
      stackDetails = {
        url: error.url,
        status: error.status,
        ...stackDetails,
      };
      errorMessage = error.message;
    } else if (error instanceof Error) {
      if (environment.devMode) console.log(error);

      stackDetails = await this.getStackDetails(error);

      errorMessage = error.message;
    } else {
      if (environment.devMode) console.log(error);
      errorMessage = error;
    }
    try {
      errorMessage =
        typeof errorMessage === 'string'
          ? errorMessage
          : JSON.stringify(errorMessage);
    } catch (error) {
      errorMessage = JSON.stringify(errorMessage) || '';
    }

    if (errorMessage) {
      this.appService
        .postErrorToBackend({
          error_detail: stackDetails,
          error_message: errorMessage,
          user_id: this.userId,
        })
        .subscribe((resp: any) => {});
    }
  }
  async getStackDetails(error: Error): Promise<object> {
    let returnDict = {
      deviceInfo: await this.deviceInfoService.getDeviceInfo(),
    };

    const stack = error.stack || '';
    const match =
      stack.match(/at\s+(.*)\s+\((.*):(\d+):(\d+)\)/) ||
      stack.match(/at\s+([^ ]+):(\d+):(\d+)/);
    if (match) {
      return {
        methodName: match[1],
        fileName: match[2],
        lineNumber: match[3],
        columnNumber: match[4],
        ...returnDict,
      };
    }
    return returnDict;
  }
}
