import {
  Component,
  Inject,
  OnInit,
  Optional,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DIALOG_DATA } from '../../../../global.variable';
import { ClientsService } from '../../../pages/pages/clients/clients.service';
import { DataCheckService } from '../../services/data-check.service';
import { LoadingSpinnerService } from '../../services/loading-spinner.service';
import { ToasterService } from '../../services/toaster.service';
import { ContactComponent } from '../contact/contact.component';
import { DialogRef } from '../modal-dialogue/model-dialogue.ref';
import { ModelDialogueService } from '../modal-dialogue/model-dialogue.service';

@Component({
  selector: 'gtapp-add-edit-monitoring-company',
  templateUrl: './add-edit-monitoring-company.component.html',
  styleUrl: './add-edit-monitoring-company.component.scss',
})
export class AddEditMonitoringCompanyComponent implements OnInit {
  companyForm: UntypedFormGroup = new UntypedFormGroup({
    company_name: new UntypedFormControl('', [Validators.required]),
    information: new UntypedFormControl(''),
  });
  addressForm: UntypedFormGroup = new UntypedFormGroup({
    address1: new UntypedFormControl(''),
    address2: new UntypedFormControl('', [Validators.required]),
    address3: new UntypedFormControl(''),
    city_name: new UntypedFormControl('', [Validators.required]),
    postcode: new UntypedFormControl(
      '',
      Validators.compose([Validators.required, Validators.max(99999999)])
    ),
    longitude: new UntypedFormControl('', [Validators.required]),
    latitude: new UntypedFormControl('', [Validators.required]),
    country_name: new UntypedFormControl('Australia'),
    state_code: new UntypedFormControl(''),
  });
  contactForm: UntypedFormGroup = new UntypedFormGroup({
    first_name: new UntypedFormControl('', [Validators.required]),
    last_name: new UntypedFormControl('', [Validators.required]),
    title: new UntypedFormControl(),
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    mobile_number: new UntypedFormControl(null, [
      Validators.minLength(10),
      Validators.maxLength(10),
      Validators.pattern('^[0-9]*$'),
    ]),
  });
  mCompanyDetailData: any;
  blurCalledDict: any = {};
  submitButtonPressed: boolean = false;
  isAdmin = this.dataCheckService.isUserAdmin();
  mcId: string = '';
  currentStep: number = 1;
  contactDataList: any = [];
  openContactForm: boolean = false;
  constructor(
    private clientService: ClientsService,
    private spinnerService: LoadingSpinnerService,
    private dataCheckService: DataCheckService,
    @Optional()
    protected dialogRef: DialogRef,
    @Optional() @Inject(DIALOG_DATA) public dialogueData: any,

    private toasterService: ToasterService,
    private route: ActivatedRoute,
    private dialogService: ModelDialogueService,
    private viewContainerRef: ViewContainerRef,
    private router: Router
  ) {
    this.route.params.subscribe({
      next: (params) => {
        if (params?.['cKey']) {
          this.mcId = params?.['cKey'];
        }
      },
    });
  }

  ngOnInit(): void {
    if (this.dialogueData?.id) {
      // this is so that in case we want to open up dailog box to open/see the data especially from the job detail page
      this.mCompanyDetailData = this.dialogueData;
    } else {
      this.mCompanyDetailData = JSON.parse(
        sessionStorage.getItem('mcDetailData') || '{}'
      );

      if (this.mcId && !this.mCompanyDetailData?.id) {
        this.spinnerService.show();
        this.clientService
          .fetchMonitoringCompanyById(this.mcId)
          .subscribe((res: any) => {
            if (res['status'] == 'success') {
              this.mCompanyDetailData = res?.data;
            }
            this.spinnerService.hide();
          });
      }

      sessionStorage.removeItem('mcDetailData');
    }
  }

  onBlurEventTrigger(field: string, inputId: any) {
    setTimeout(() => {
      const inputElement = document.getElementById(inputId) as HTMLElement;
      if (document.activeElement !== inputElement)
        // adding a timeout and checking the input field is still on focus so that close icon will work
        this.blurCalledDict[field] = true;
    }, 200);
  }

  editField(field: string) {
    if (field === 'mc') {
      this.companyForm.controls['company_name'].setValue(null);
      this.shiftFocus('mcNameField');
      this.blurCalledDict['mc'] = false;
    } else {
      this.companyForm.controls['information'].setValue(null);
      this.shiftFocus('mcInfoField');
      this.blurCalledDict['information'] = false;
    }
  }
  shiftFocus(elementId: string) {
    setTimeout(() => {
      var element = <HTMLInputElement>document.getElementById(elementId);
      element?.focus();
    });
  }
  redirectToList() {
    this.router.navigate(['/monitoring-company']);
  }
  createMC() {
    this.submitButtonPressed = true;
    this.spinnerService.show();
    const requestData = {
      ...this.companyForm.value,
      ...this.addressForm.value,
      contacts: this.contactDataList || [],
    };
    this.clientService
      .createMonitoringCompany(requestData)
      .then((response: any) => {
        if (response['status'] == 'success') {
          this.spinnerService.hide();
          this.toasterService.setMessage({
            successMessage: response['message'],
            errorMessage: '',
          });
          this.redirectToList();

          this.companyForm.reset();
          this.addressForm.reset();
        } else {
          this.submitButtonPressed = false;
          this.spinnerService.hide();
          this.toasterService.setMessage({
            errorMessage: response['message'],
            successMessage: '',
          });
        }
      });
  }
  createMCDisabling() {
    if (!this.companyForm.valid) return true;
    else if (this.submitButtonPressed) return true;
    else if (
      Object.values(this.addressForm.value)?.some(
        (value: any) => value?.length && value?.toLowerCase() !== 'australia'
      )
    ) {
      return !this.addressForm.valid;
    }
    return false;
  }
  updateMC(field: string) {
    let requestData: any = { id: this.mCompanyDetailData.id };
    let params: any = { [`update_${field}`]: 1 };
    switch (field) {
      case 'company_name':
        requestData[field] = this.companyForm.value[field];
        break;
      case 'information':
        requestData[field] = this.companyForm.value[field];
        break;
      case 'address':
        requestData[field] = this.addressForm.value;
        break;
      case 'contacts':
        requestData[field] = this.contactDataList;
        break;
    }
    this.spinnerService.show();

    this.clientService
      .updateMonitoringCompanyDetailById(requestData, params)
      .then((response: any) => {
        if (response['status'] == 'success') {
          this.spinnerService.hide();
          this.toasterService.setMessage({
            successMessage: response['message'],
            errorMessage: '',
          });
          this.mCompanyDetailData = response?.data;
        } else {
          this.spinnerService.hide();
          this.toasterService.setMessage({
            errorMessage: response['message'],
            successMessage: '',
          });
        }
      });
  }
  removeContact(index: any) {
    this.mCompanyDetailData?.contacts?.splice(index, 1);
    this.contactDataList = this.mCompanyDetailData?.contacts;
    this.updateMC('contacts');
  }
  openContact(contactData: any) {
    this.dialogService.open(ContactComponent, {
      data: {
        contactData: contactData,
        mcContacts: true,
        editContact: false,
      },
    });
  }
  openTemplate(template: TemplateRef<any>, field: string) {
    if (field === 'contacts') {
      this.contactDataList = this.mCompanyDetailData?.contacts;
    } else {
      this.companyForm.patchValue(this.mCompanyDetailData);
      this.addressForm.patchValue(this.mCompanyDetailData?.address);
    }
    this.dialogService.open(
      template,
      { data: { field: field } },
      this.viewContainerRef
    );
  }

  onCloseDialogue(value?: any) {
    this.dialogRef.close(value);
  }
  mobileNumberCheck() {
    if (!this.contactForm.value?.mobile_number) {
      this.contactForm.controls['mobile_number'].setValue(null);
      this.contactForm.controls['mobile_number'].removeValidators([
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$'),
      ]);
    } else {
      this.contactForm.controls['mobile_number'].setValidators([
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$'),
      ]);
    }
  }
  dupContactCheck() {
    const dupContactExists = this.contactDataList?.some(
      (contact: any) => contact?.email === this.contactForm.value?.email
    );
    return dupContactExists;
  }
}
