import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../../../../../../app.service';
import { ImagePreviewComponent } from '../../../../../../shared/components/image-preview/image-preview.component';
import { ModelDialogueService } from '../../../../../../shared/components/modal-dialogue/model-dialogue.service';
import { LoadingSpinnerService } from '../../../../../../shared/services/loading-spinner.service';
import { ToasterService } from '../../../../../../shared/services/toaster.service';
import { ProfileService } from '../../../../../profile/profile.service';
import { JobsService } from '../../../../jobs/jobs.service';
import { getDateTimeRange } from '../../../../tableDetails';
import { UsersService } from '../../../../users/users.service';
import { HolAndChatService } from '../../../hol-and-chat.service';
import { DataCheckService } from '../../../../../../shared/services/data-check.service';

@Component({
  selector: 'gtapp-add-edit-hand-over-log',
  templateUrl: './add-edit-hand-over-log.component.html',
  styleUrl: './add-edit-hand-over-log.component.scss',
})
export class AddEditHandOverLogComponent implements OnInit, OnDestroy {
  eventsDataList: any = [];

  selectedEventCount: number = 0;

  userEventDetailsMobileView: any = {
    individualDetails: [
      {
        name: 'updated_at',
        datetimeObj: true,
        dateRangeKey: 'updated_at',
        header: 'Event Time',
        ...getDateTimeRange(),
        sortKey: 'updated_at',
        cardRowStyle: {
          'font-size': 'small',
          'float': 'right',
        },
      },
      {
        name: 'action',
        cardRowStyle: {
          'font-size': 'small',
          'font-weight': 'bold',
          'text-transform': 'uppercase',
        },
      },
      {
        name: 'company_name',
        cardRowStyle: {
          'font-size': 'small',
        },
      },

      {
        name: 'detail',
        cardRowStyle: {
          'font-size': 'small',
        },
      },
    ],
  };
  tableStyle = {
    'overflow': 'auto',
    'max-height': '100%',
  };
  userEventDetailsTableView: any = [
    {
      header: 'Event Time',
      name: 'updated_at',
      datetimeObj: true,
      dateRangeKey: 'updated_at',
      sortKey: 'updated_at',
      ...getDateTimeRange(),
    },

    {
      header: 'Event Type',
      name: 'action',
    },
    {
      header: 'Company',
      name: 'company_name',
    },
    {
      header: 'Checkpoint',
      columnDataStyle: {
        'white-space': 'pre-line',
      },
      nestedValue: (row: any) => {
        return [0, 6].includes(row?.event_action)
          ? [row?.detail, row?.address].join('\n')
          : '';
      },
    },
  ];
  currentStep: number = 1;
  handOverLogDescription: string = '';

  recipientList: any = [];
  selectedRecipients: any = [];
  recipientSearchList: any = [];

  handOverLogRecipientsRows: number = 25;
  handOverLogRecipientsPrevious: number = 0;
  handOverLogRecipientsPageNum: number = 1;
  handOverLogRecipientsTotalPages: number = 0;
  handOverLogRecipientsTotalCount: number = 0;

  selectedHandOverLogId: string = '';
  selectedGuardId: string = '';
  selectedHandOverLogData: any;

  subscriberUserId: any;

  handOverLogCreated: boolean = false;
  previouslySelectedEventIds: any = [];

  alreadySentEventIds: any = [];

  isAdmin: boolean = false;

  constructor(
    private spinnerService: LoadingSpinnerService,
    private toasterService: ToasterService,
    private userService: UsersService,
    private dialogService: ModelDialogueService,
    private jobService: JobsService,
    private handOverLogService: HolAndChatService,
    private profileService: ProfileService,
    private router: Router,
    private route: ActivatedRoute,
    private appService: AppService,
    private _location: Location,
    private dataCheckService: DataCheckService
  ) {
    this.route.params.subscribe({
      next: (params) => {
        if (params?.['hKey']) {
          this.selectedHandOverLogId = atob(params?.['hKey']);
        }
        if (params?.['gKey']) {
          this.selectedGuardId = atob(params?.['gKey']);
        }
      },
    });
    this.isAdmin = this.dataCheckService?.isUserAdmin();
  }
  ngOnInit(): void {
    if (this.selectedHandOverLogId) {
      this.getHandOverLogDetailData();
    } else {
      this.getUserUpdateLog();
    }

    const userData = this.appService.getUserData();

    this.subscriberUserId = userData?.subscriber?.subscriber_user_id;
  }
  ngOnDestroy(): void {
    if (!this.handOverLogCreated) this.updateDrafts();
  }

  getHandOverLogDetailData() {
    this.handOverLogService
      .retrieveHandOverLog(this.selectedHandOverLogId, {
        userId: this.selectedGuardId,
      })
      .subscribe((resp: any) => {
        if (resp?.status === 'success') {
          this.selectedHandOverLogData = resp?.data;

          this.selectedHandOverLogData.received_by =
            this.selectedHandOverLogData?.recipients?.find(
              (guard: any) => guard?.opened
            );
        } else {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: resp['message'],
          });
        }
      });
  }
  updateDrafts() {
    const allItemIds = [
      ...new Set(this.eventsDataList?.map((item: any) => item?.id)),
    ];

    const selectedItemsIds = [
      ...new Set(
        this.eventsDataList
          ?.filter((event: any) => event?.selected)
          ?.map((item: any) => item?.id)
      ),
    ];
    const recipient_ids = [
      ...new Set(this.selectedRecipients?.map((user: any) => user?.id)),
    ];
    if (recipient_ids?.length)
      sessionStorage.setItem(
        'handOverLogDraftSelectedAssignees',
        JSON.stringify(recipient_ids)
      );

    if (this.areArraysSame(selectedItemsIds, this.previouslySelectedEventIds))
      return;

    this.profileService
      .updateHandOverLogDraft({
        all_event_ids: allItemIds,
        selected_event_ids: selectedItemsIds,
      })
      .subscribe((response: any) => {
        if (selectedItemsIds?.length)
          sessionStorage.setItem('handOverLogUpdate', '1');
      });
  }
  areArraysSame(arr1: any[], arr2: any[]): boolean {
    if (arr1?.length !== arr2?.length) {
      return false;
    }

    arr1?.sort();
    arr2?.sort();

    for (let i = 0; i < arr1?.length; i++) {
      if (arr1?.[i] !== arr2?.[i]) {
        return false;
      }
    }

    return true;
  }

  getUserUpdateLog() {
    this.spinnerService.show();
    let body: any = {
      event_type: 'patrol',
      search_filters: {},
      hand_over_log: 1,
    };

    this.userService.getUserUpdateLog(body).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.eventsDataList = response?.data;
        this.alreadySentEventIds = response?.hand_over_log_event_ids;

        if (!this.eventsDataList?.length) {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: 'Add comments/files to create log',
          });
          this.router.navigate(['/hand-over-log']);
        }
        this.formatEventList();
        this.spinnerService.hide();
      } else {
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: response['message'],
        });
        this.spinnerService.hide();
      }
    });
  }

  onOpenFile(file: any) {
    this.dialogService.open(ImagePreviewComponent, {
      data: { imageSrc: file?.file, timeStamp: file.updated_at },
    });
  }
  getRecipients() {
    let params: any = {};
    if (this.handOverLogRecipientsRows) {
      params['rows'] = this.handOverLogRecipientsRows;
    }
    if (this.handOverLogRecipientsPrevious) {
      params['previous'] = this.handOverLogRecipientsPrevious;
    }

    this.spinnerService.show();
    this.jobService.getAssignees(params).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.recipientList.push(...response?.data);

        this.handOverLogRecipientsTotalCount = response?.total_count;
        this.handOverLogRecipientsTotalPages = Math.ceil(
          this.handOverLogRecipientsTotalCount / this.handOverLogRecipientsRows
        );
        const savedAssigneeIds: any = JSON.parse(
          sessionStorage.getItem('handOverLogDraftSelectedAssignees') || '[]'
        );
        sessionStorage.removeItem('handOverLogDraftSelectedAssignees');
        if (savedAssigneeIds?.length) {
          this.recipientList?.forEach((user: any) => {
            if (savedAssigneeIds?.includes(user?.id)) {
              this.addSelectedAssignee(user);
            }
          });
        } else {
          this.makeAssigneeSelectionChanges();
        }
      } else {
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: response?.message,
        });
      }
      this.spinnerService.hide();
    });
  }
  removeSelectedAssignee(deleteAssignee: any) {
    this.selectedRecipients = this.selectedRecipients?.filter(
      (assignee: any) => assignee?.id !== deleteAssignee?.id
    );
    this.makeAssigneeSelectionChanges();
  }
  addSelectedAssignee(addAssignee: any) {
    if (addAssignee?.id) {
      if (
        this.selectedRecipients?.some(
          (assignee: any) => assignee?.id === addAssignee?.id
        )
      ) {
        this.toasterService.setMessage({
          errorMessage: 'assignee already added',
          successMessage: '',
        });
      } else {
        this.selectedRecipients.push(addAssignee);
        if (
          !this.recipientList?.some(
            (assignee: any) => assignee?.id === addAssignee?.id
          )
        ) {
          this.recipientList.push(addAssignee);
        }
      }

      this.makeAssigneeSelectionChanges();
    }
  }
  selectUnselectEvents(event: any) {
    event.selected = !event?.selected;

    const selectedItems = this.eventsDataList?.filter(
      (event: any) => event?.selected
    );
    this.selectedEventCount = selectedItems?.length;
  }
  formatEventList() {
    this.eventsDataList?.forEach(
      (item: any) => (item.selected = item?.hand_over_log_draft)
    );
    const selectedItems = this.eventsDataList?.filter(
      (event: any) => event?.selected
    );

    this.previouslySelectedEventIds = [
      ...new Set(selectedItems?.map((item: any) => item?.id)),
    ];

    this.alreadySentEventIds = this.alreadySentEventIds?.filter(
      (_id: any) => !this.previouslySelectedEventIds?.includes(_id)
    );

    this.selectedEventCount = selectedItems?.length;
  }
  addRemovessignee(assignee: any) {
    assignee.selected = !assignee?.selected;
    if (assignee.selected) {
      this.addSelectedAssignee(assignee);
    } else {
      this.removeSelectedAssignee(assignee);
    }
  }
  makeAssigneeSelectionChanges() {
    const savedAssigneeIds = (this.recipientList = this.recipientList?.filter(
      (user: any) => user?.id != this.subscriberUserId
    ));
    this.recipientList.forEach((assignee: any) => {
      assignee.selected = this.selectedRecipients?.some(
        (selectedAssignee: any) => selectedAssignee?.id === assignee?.id
      );
    });
  }

  searchAssignee(event?: any) {
    if (event.target.value?.length > 2) {
      let params: any = { full_name: event.target.value };
      this.jobService.getAssignees(params).subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.recipientSearchList = response?.data?.filter(
            (value1: any) =>
              !this.selectedRecipients?.some(
                (value2: any) => value1?.id === value2?.id
              )
          );
        } else {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
      });
    }
  }

  onLoadMoreAssignees() {
    this.handOverLogRecipientsPrevious =
      this.handOverLogRecipientsPrevious + this.handOverLogRecipientsRows;

    this.getRecipients();
    setTimeout(() => {
      const table = document.querySelector('#assigneeList');
      if (table) {
        table.scrollTo({ top: table.scrollHeight, behavior: 'smooth' });
      }
    }, 100);
  }

  createhandOverLog() {
    this.handOverLogCreated = true;
    let body = {
      description: this.handOverLogDescription,
      recipient_ids: [
        ...new Set(this.selectedRecipients?.map((user: any) => user?.id)),
      ],
      user_update_ids: [
        ...new Set(
          this.eventsDataList
            ?.filter((event: any) => event?.selected)
            ?.map((event: any) => event?.id)
        ),
      ],
    };
    this.handOverLogService.createHandOverLog(body).subscribe((resp: any) => {
      if (resp?.status === 'success') {
        sessionStorage.removeItem('handOverLogDraftCount');
        this.router.navigate(['/']);
        this.toasterService.setMessage({
          successMessage: resp['message'],
          errorMessage: '',
        });
      } else {
        this.handOverLogCreated = false;
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: resp['message'],
        });
      }
    });
  }

  commentOnLog() {
    sessionStorage.setItem(
      'commentOnHOLData',
      JSON.stringify({
        id: this.selectedHandOverLogId,
        description: this.selectedHandOverLogData?.description,
      })
    );
    let openedUser = this.selectedHandOverLogData?.recipients?.find(
      (user: any) => user?.opened
    );
    if (this.subscriberUserId === openedUser?.id) {
      openedUser = this.selectedHandOverLogData?.created_by;
    }

    this.router.navigate(['/message'], { fragment: openedUser?.id });
  }

  sendLater() {
    this._location.back();
  }
}
