import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { SwPush } from '@angular/service-worker';
import { Html5Qrcode } from 'html5-qrcode';
import {
  DIALOG_DATA,
  getDistanceUsingHaversine,
  showGeoFenceAlert,
  sortArrayOfObject,
} from '../../../../global.variable';
import { AppService } from '../../../app.service';
import { CheckpointService } from '../../../pages/pages/checkpoints/checkpoint.service';
import { ClientsService } from '../../../pages/pages/clients/clients.service';
import { JobsService } from '../../../pages/pages/jobs/jobs.service';
import { PagesService } from '../../../pages/pages/pages.service';
import { ProfileService } from '../../../pages/profile/profile.service';
import { DeviceInfoService } from '../../../shared/services/device-info.service';
import { DataCheckService } from '../../services/data-check.service';
import { gTDB } from '../../services/db';
import { LoadingSpinnerService } from '../../services/loading-spinner.service';
import { ToasterService } from '../../services/toaster.service';
import { AddEditSiteComponent } from '../add-edit-site/add-edit-site.component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { DialogRef } from '../modal-dialogue/model-dialogue.ref';
import { ModelDialogueService } from '../modal-dialogue/model-dialogue.service';
import { PrompterComponent } from '../prompter/prompter.component';
declare var bootstrap: any;
@Component({
  selector: 'gtapp-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrl: './qr-code.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QrCodeComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('qrScanWarningTemplate') qrScanWarningTemplate: any =
    TemplateRef<any>;
  html5QrCode!: Html5Qrcode;

  checkPointForm: UntypedFormGroup = new UntypedFormGroup({
    qr_text: new UntypedFormControl('', Validators.required),
    name: new UntypedFormControl('', [
      Validators.required,
      Validators.max(100),
    ]),
    company: new UntypedFormControl('', Validators.required),
    description: new UntypedFormControl(''),
    is_locked: new UntypedFormControl(false),
    company_id: new UntypedFormControl('', Validators.required),
    site: new UntypedFormControl(''),
    site_id: new UntypedFormControl(''),

    min_distance: new UntypedFormControl(100, [
      Validators.max(1000),
      Validators.required,
    ]),

    address1: new UntypedFormControl(''),
    address2: new UntypedFormControl(),
    address3: new UntypedFormControl(),
    city_name: new UntypedFormControl(''),
    state_code: new UntypedFormControl(''),
    country_name: new UntypedFormControl(''),
    postcode: new UntypedFormControl(''),
    longitude: new UntypedFormControl(null),
    latitude: new UntypedFormControl(null),
  });

  registerScan: boolean = true;
  scanInProgress: boolean = false;

  isAdmin: boolean = false;
  isDispatchUser: boolean = false;

  showForm: boolean = false;
  cameraDevices: any = [];

  html5torch: boolean = false;
  devieTorchOn: boolean = false;

  zoomRange: any = {};

  minKm: number = 5;
  maxKm: number = 1000;
  nearestKmValue = 100;

  patrolRouteId: any;
  offlineMode = !navigator.onLine;

  cachedQRCheckpoints: any;
  cameraFeedDetected: boolean = true;
  checkpointGps: any;

  userLocation: any = {};

  currentStep: number = 1;

  selectedCameraIndex: any;

  videoElementHidden: boolean = false;

  searchSiteValue: any;
  siteListData: any = [];
  initialSiteDataList: any = [];

  cameraConfig = {
    fps: 29,
    qrbox: { width: 250, height: 250 },
    rememberLastUsedCamera: true,
    showTorchButtonIfSupported: true,
  };
  isIphonePwa: boolean = false;

  constructor(
    @Optional() protected dialogRef: DialogRef,
    private dialogService: ModelDialogueService,
    private jobService: JobsService,
    private checkpointService: CheckpointService,
    private spinnerService: LoadingSpinnerService,
    private dataCheckService: DataCheckService,
    private toasterService: ToasterService,
    @Inject(DIALOG_DATA) public dialogueData: any,
    private swPush: SwPush,
    private pageService: PagesService,
    private profileService: ProfileService,
    private route: Router,
    private cdr: ChangeDetectorRef,
    private deviceInfoService: DeviceInfoService,
    private clientService: ClientsService,
    private appService: AppService,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.isAdmin = this.dataCheckService.isUserAdmin();
    this.isDispatchUser = this.dataCheckService.isDispatchUser();
    if (this.dialogueData) {
      this.patrolRouteId = this.dialogueData?.patrolRouteId || null;
      this.cachedQRCheckpoints = this.dialogueData?.cachedQRCheckpoints || [];
    }

    this.deviceInfoService.deviceInfoSubject.subscribe((value: any) => {
      if ('appStatus' in value) {
        this.offlineMode = !value?.appStatus;
        this.siteListData = [...this.siteListData];

        if (this.offlineMode) {
          this.checkPointForm.controls['is_locked'].setValue(false);
          if (this.currentStep === 2) {
            this.backPreviousStep();
          }
        }
        this.cdr.detectChanges();
      }
    });
    const storedCameraIndex = sessionStorage.getItem('selectedCameraIndex');
    if (storedCameraIndex) {
      this.selectedCameraIndex = parseInt(storedCameraIndex, 0);
    }
  }
  toggleCollapse() {
    var myCollapse = document.getElementById('collapseId');
    var collapse = new bootstrap.Collapse(myCollapse, {
      toggle: false,
    });
    collapse.toggle();
  }

  async ngAfterViewInit(): Promise<void> {
    this.pageService.miscSubjectParam.next({
      isOnEvent: true,
    });
    this.isIphonePwa =
      Boolean(/iPad|iPhone|iPod/.test(navigator.userAgent)) &&
      Boolean(window.matchMedia('(display-mode: standalone)').matches);
    this.cdr.detectChanges();

    this.html5QRReader();

    if (!this.cachedQRCheckpoints?.length) {
      this.cachedQRCheckpoints = await this.getQRCheckpointList();
    }
  }

  onCloseDialogue() {
    try {
      if ([2, 3].includes(this.html5QrCode?.getState())) {
        this.html5QrCode?.stop();
      }
    } catch (error) {}

    this.dialogRef.close(false);
  }
  ngOnDestroy(): void {
    try {
      if ([2, 3].includes(this.html5QrCode?.getState())) {
        this.html5QrCode?.stop();
      }
    } catch (error) {}

    this.pageService.miscSubjectParam.next({
      isOnEvent: false,
    });
  }

  html5QRReader() {
    this.spinnerService.show();
    this.html5QrCode = new Html5Qrcode('html5-qr-reader');

    // if we want to default camera to back may need to use this in iphones
    //  this.html5QrCode.start(
    //    { facingMode: 'environment' },
    //    config,
    //    this.qrCodeSuccessCallback,
    //    this.qrCodeErrorCallback
    //  );

    Html5Qrcode.getCameras() // Call the static method on the class
      .then((devices: any) => {
        setTimeout(() => {
          this.cameraDevices = devices;

          this.deviceInfoService
            .getGpsCoordinates()
            .then((gps: any) => (this.userLocation = gps));

          if (devices && devices.length) {
            let devcieID = devices[devices.length - 1]?.id;
            if (
              this.selectedCameraIndex >= 0 &&
              this.selectedCameraIndex < devices?.length
            ) {
              devcieID = devices[this.selectedCameraIndex]?.id;
            } else {
              const backCameras = this.cameraDevices.filter(
                (device: any) =>
                  device.facingMode === 'environment' ||
                  device.label.toLowerCase().includes('back')
              );
              if (backCameras?.length) {
                devcieID = backCameras[backCameras?.length - 1]?.id;
              }
            }

            this.startCamera(devcieID);
          }
          // setTimeout is for firefox as it is not able to get devices fast
        }, 500);
      })
      .catch((err) => {
        this.checkCameraFeed();
        this.spinnerService.hide();
      });
  }
  checkCameraFeed() {
    try {
      const videoElement = document.querySelector('video');

      if (videoElement) {
        videoElement.addEventListener('playing', () => {
          setTimeout(() => {
            // this is for firefox as height is 0 without a timeout even if the feed is there

            this.cameraFeedDetected = isVideoPlaying(videoElement);

            if (this.cameraFeedDetected) {
              this.isTorchSupported(this.html5QrCode);
              this.isZoomSupported(this.html5QrCode);
            }
            this.spinnerService.hide();
          }, 500);
        });
        setTimeout(() => {
          // this is for iphones as some devices are not listening to 'playing' eventListner
          if (this.videoElementHidden === false) {
            this.spinnerService.hide();
          }

          this.cameraFeedDetected = isVideoPlaying(videoElement);
          if (this.cameraFeedDetected) {
            this.isTorchSupported(this.html5QrCode);
            this.isZoomSupported(this.html5QrCode);
          }
          this.spinnerService.hide();
        }, 1000);
      } else {
        this.spinnerService.hide();

        setTimeout(() => {
          if (this.videoElementHidden === false) {
            this.cameraFeedDetected = false;
            this.cdr.detectChanges();
          }
        }, 500);
      }

      function isVideoPlaying(video: any) {
        return (
          video.currentTime > 0 &&
          !video.paused &&
          !video.ended &&
          video.readyState > 2 &&
          video.videoWidth > 0 &&
          video.videoHeight > 0
        );
      }
    } catch (error) {
      this.spinnerService.hide();
      this.cameraFeedDetected = false;
    }
  }
  checkAndCallAfterFuntions() {
    if (this.html5QrCode.getState() === 2) {
      this.checkCameraFeed();
    } else {
      setTimeout(() => {
        this.checkAndCallAfterFuntions();
      }, 500);
    }
  }
  isZoomSupported(html5Qrcode: Html5Qrcode) {
    let settings: any = html5Qrcode.getRunningTrackCapabilities();
    if ('zoom' in settings) {
      this.zoomRange = { ...settings?.zoom, value: 1 };
      this.applyZoom();
    }
    this.cdr.detectChanges();
  }
  async applyZoom() {
    try {
      let constraints: any = {
        advanced: [{ zoom: this.zoomRange?.value }],
      };
      await this.html5QrCode?.applyVideoConstraints(constraints);
    } catch (error) {
      throw error;
    }
  }

  isTorchSupported(html5Qrcode: Html5Qrcode) {
    let settings = html5Qrcode.getRunningTrackSettings();
    this.html5torch = 'torch' in settings;
    this.cdr.detectChanges();
  }
  async turnOffOnTorchHTML5(action: string) {
    if (action == 'on') {
      let constraints: any = {
        torch: true,
        advanced: [{ torch: true }],
      };
      await this.html5QrCode?.applyVideoConstraints(constraints);
      let settings: any = this.html5QrCode?.getRunningTrackSettings();

      if (Boolean(/iPad|iPhone|iPod/.test(navigator.userAgent))) {
        this.devieTorchOn = true;
      } else {
        // Because this part doesn't work on iPhone for some reason...
        if (settings?.torch === true) {
          this.devieTorchOn = true;
        } else {
          this.devieTorchOn = false;
        }
      }

      this.cdr.detectChanges();
    } else {
      let constraints: any = {
        torch: false,
        advanced: [{ torch: false }],
      };
      this.devieTorchOn = false;
      await this.html5QrCode?.applyVideoConstraints(constraints);
    }
  }
  qrLookup() {
    if (this.swPush.isEnabled) {
      const scannedQRData: any = this.cachedQRCheckpoints?.find(
        (qrCp: any) => qrCp?.qr_code === this.checkPointForm.value?.qr_text
      );
      if (scannedQRData) {
        let data: any = {
          qr_text: this.checkPointForm.value?.qr_text,
          check_point_id: scannedQRData?.id,
          user_prompts: scannedQRData?.user_prompts,
          user_prompt_count: scannedQRData?.user_prompt_count,
          minDistance: scannedQRData?.min_distance,
          gps: scannedQRData?.gps,
          checkpointDetail: {
            name: scannedQRData?.name,
            company: scannedQRData?.company,
            site_name: scannedQRData?.site_name,
          },
        };
        if (this.patrolRouteId) {
          data.patrol_route_id = this.patrolRouteId;
        }

        this.openUserPrompt(data);
      } else {
        this.alreadyRegistered();
      }
    } else {
      this.alreadyRegistered();
    }
  }
  async alreadyRegistered() {
    this.spinnerService.show();
    if (this.offlineMode) {
      this.registerQRCode();
    } else {
      this.checkpointService
        .getCheckpoints({
          qr_lookup: 1,
          qr_text: this.checkPointForm.value?.qr_text,
        })
        .subscribe(async (response: any) => {
          if (response?.status == 'failure' && response?.id) {
            this.spinnerService.hide();

            let data: any = {
              qr_text: this.checkPointForm.value?.qr_text,
              check_point_id: response?.id,
              user_prompts: response?.user_prompts,
              user_prompt_count: (response?.user_prompts || [])?.length,
              minDistance: response?.min_distance,
              gps: response?.gps,
              checkpointDetail: {
                name: response?.name,
                company: response?.company,
                site_name: response?.site_name,
              },
            };
            if (this.patrolRouteId) {
              data.patrol_route_id = this.patrolRouteId;
            }
            this.openUserPrompt(data);
          } else {
            this.registerQRCode();
          }
        });
    }
  }
  async registerQRCode() {
    this.spinnerService.hide();

    const qrInQueues = await this.checkQrinQueue(
      this.checkPointForm.value?.qr_text
    );
    if (qrInQueues) {
      this.onCloseDialogue();
      setTimeout(() => {
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: 'Already in Queue',
        });
      }, 500);
    } else {
      if (!this.offlineMode) {
        this.jobService.getAllSiteList().subscribe((response: any) => {});
      }
      const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
        data: {
          title: 'QR Code Not Recognized',
          message: `Press confirm to add new QR Checkpoint`,
        },
      });
      dialogRef.afterClosed().subscribe((value: any) => {
        if (value === true) {
          this.showForm = true;
          this.initializeToolTip();

          this.onSiteSearch();
          this.cdr.detectChanges();
        } else {
          this.onCloseDialogue();
        }
      });
    }
  }
  async checkGeoFence(checkpointData: any) {
    return new Promise(async (resolve) => {
      if (checkpointData?.minDistance && checkpointData?.gps) {
        if (!this.userLocation?.lat) {
          this.userLocation = await this.deviceInfoService.getGpsCoordinates();
        }

        resolve(
          showGeoFenceAlert(
            checkpointData?.minDistance,
            checkpointData?.gps?.lat,
            this.userLocation?.lat,
            checkpointData?.gps?.lon,
            this.userLocation?.lon
          )
        );
      } else {
        resolve(false);
      }
    });
  }
  async scanQRCheckpoint(checkpointData: any) {
    const geoFenceRadiusAlert: any = await this.checkGeoFence(checkpointData);
    if (geoFenceRadiusAlert) {
      const checkpointGps = {
        scan_detail: checkpointData?.gps,
        min_distance: checkpointData?.minDistance,
        name: checkpointData?.checkpointDetail?.name,
        //passing tooltip value to show in the map marker
        gps: { markerTooltip: 'Your Location', ...this.userLocation },
      };

      const dialogRef = this.dialogService.open(
        this.qrScanWarningTemplate,
        {
          data: checkpointGps,
        },
        this.viewContainerRef
      );
      dialogRef.afterClosed().subscribe((value: any) => {
        if (value === true) {
          this.onScanQR(checkpointData);
        } else {
          this.onCloseDialogue();
        }
      });
    } else {
      this.onScanQR(checkpointData);
    }
  }

  onScanQR(body: any) {
    if (!this.scanInProgress) {
      this.scanInProgress = true;
      this.spinnerService.show();

      this.jobService.scanCheckPoint(body).then((response: any) => {
        if (response['status'] === 'success') {
          this.route.navigate(['/']);
          this.dialogRef.close(response);
          this.toasterService.setMessage({
            successMessage: response['message'],
            errorMessage: '',
            status: 'info',
          });
        } else {
          this.dialogRef.close(true);
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
            status: 'danger',
          });
        }

        this.spinnerService.hide();
      });
    }
  }
  getSortedPromtList(arrayList: any) {
    return sortArrayOfObject(arrayList, 'order', 'desc');
  }
  openUserPrompt(checkPointData: any) {
    if (checkPointData?.user_prompt_count > 0) {
      let promptResults: any = [];
      let counter = 0;
      const dialogRefs: any = [];
      this.getSortedPromtList(checkPointData?.user_prompts)?.forEach(
        (prompt: any) => {
          const dialogref = this.dialogService.open(PrompterComponent, {
            data: { promptData: prompt?.prompts },
          });
          dialogRefs.push(dialogref);
          dialogref.afterClosed().subscribe((value: any) => {
            if (value) {
              counter += 1;
              if (value?.length) {
                promptResults.push({
                  data: value,
                  prompt_data: { id: prompt?.id, name: prompt?.name },
                });
              }
              if (counter === checkPointData?.user_prompt_count) {
                checkPointData.prompt_data = JSON.stringify(promptResults);
                this.scanQRCheckpoint(checkPointData);
              }
            } else {
              if (value === false) {
                dialogRefs?.forEach((dialogRef: any) => dialogRef?.close());
                this.toasterService.setMessage({
                  successMessage: '',
                  errorMessage: 'Checkpoint was not scanned',
                  status: 'warning',
                });
                this.onCloseDialogue();
              }
            }
          });
        }
      );
    } else {
      this.scanQRCheckpoint(checkPointData);
    }
  }
  registerQR(body: any) {
    this.spinnerService.show();
    if (this.registerScan) body.scan_after_register = true;
    let reqBody = { form_data: JSON.stringify(body) };

    this.checkpointService.registerQRCode(reqBody).then((response) => {
      if (response?.status == 'success') {
        this.spinnerService.hide();
        this.toasterService.setMessage({
          successMessage: response?.message,
          errorMessage: '',
        });
        this.dialogRef.close(true);
      } else {
        this.spinnerService.hide();

        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: response?.message,
        });
      }
    });
  }

  onClickSubmit() {
    let body: any = this.checkPointForm.value;
    if (body.site_id == null || body.site_id == undefined) {
      delete body.site_id;
    }
    if (body) {
      this.registerQR(body);
    }
  }
  shiftFocus(elementId: string, time?: number) {
    setTimeout(() => {
      var element = <HTMLInputElement>document.getElementById(elementId);
      element?.focus();
    }, time);
  }

  qrValueAction(qrCodeValue: string) {
    this.checkPointForm.controls['qr_text'].setValue(qrCodeValue);
    this.qrLookup();
  }
  showMapMethod() {
    this.spinnerService.show();
    this.checkPointForm.controls['address1'].setValue('');
    this.checkPointForm.controls['address2'].setValue('');
    this.checkPointForm.controls['address3'].setValue('');
    this.checkPointForm.controls['city_name'].setValue('');
    this.checkPointForm.controls['postcode'].setValue('');
    this.checkPointForm.controls['state_code'].setValue('');

    setTimeout(async () => {
      if (!this.userLocation?.lat) {
        this.userLocation = await this.deviceInfoService.getGpsCoordinates();
      }

      this.checkpointGps = { ...this.userLocation };
      this.cdr.detectChanges();
      this.spinnerService.hide();
    }, 100);
  }
  backPreviousStep() {
    this.checkpointGps = null;
    this.checkPointForm.controls['is_locked'].setValue(false);
    this.checkPointForm.controls['longitude'].removeValidators(
      Validators.required
    );
    this.checkPointForm.controls['latitude'].removeValidators(
      Validators.required
    );
  }
  showHideAddressFields(event: any) {
    const selected = event.target.checked;
    this.checkPointForm.controls['is_locked'].setValue(selected);
    if (selected) {
      this.checkPointForm.controls['longitude'].setValidators(
        Validators.required
      );
      this.checkPointForm.controls['latitude'].setValidators(
        Validators.required
      );
    } else {
      this.backPreviousStep();
    }
  }

  addressSelected(address: any) {
    if (address && typeof address === 'object') {
      this.checkpointGps = { lat: address?.latitude, lon: address?.longitude };
      this.checkPointForm.controls['address1'].setValue(address?.address1);
      this.checkPointForm.controls['address2'].setValue(address?.address2);
      this.checkPointForm.controls['address3'].setValue(address?.address3);
      this.checkPointForm.controls['city_name'].setValue(address?.city_name);
      this.checkPointForm.controls['postcode'].setValue(address?.postcode);
      this.checkPointForm.controls['state_code'].setValue(address?.state_code);
      this.checkPointForm.controls['country_name'].setValue(
        address?.country_name || 'AUSTRALIA'
      );
      this.checkPointForm.controls['latitude'].setValue(address?.latitude);
      this.checkPointForm.controls['longitude'].setValue(address?.longitude);

      this.disableId('addressSearch');
    }
  }
  disableId(idVal: string = 'addressSearch') {
    var element = <HTMLInputElement>document.getElementById(idVal);
    if (element) {
      element.disabled = false;
      element.blur();
    }
  }
  emitData(event: any, addCheck = false) {
    this.checkPointForm.controls['latitude'].setValue(event.lat);
    this.checkPointForm.controls['longitude'].setValue(event.lng);
    this.checkpointGps.lat = event.lat;
    this.checkpointGps.lon = event.lng == undefined ? event.lon : event.lng;
  }
  updateMinDistance(event: any) {
    // method to update the min distance emitted from the map component
    this.checkPointForm.controls['min_distance'].setValue(event);
  }

  async getQRCheckpointList() {
    return new Promise(async (resolve) => {
      try {
        if (this.swPush.isEnabled) {
          const result: any = await gTDB.cachedData
            .where({ shortPath: '/api/check_point/list_qr_checkpoints/' })
            .toArray();

          resolve(result?.[0]?.data);
        }
      } catch (error) {
        resolve([]);
      }
    });
  }
  async checkQrinQueue(qrValue: string) {
    return new Promise(async (resolve) => {
      try {
        if (this.swPush.isEnabled) {
          const result = await gTDB.gtSavedData.toArray();

          resolve(
            result?.some(
              (item: any) =>
                item?.pathName === '/api/check_point/register_qr/' &&
                !item?.apiCallSuccess &&
                !item?.retry_count &&
                JSON.parse(item?.form_data)?.qr_text === qrValue
            )
          );
        } else {
          resolve(false);
        }
      } catch (error) {
        resolve(false);
      }
    });
  }
  startCamera(deviceID: any) {
    this.html5QrCode
      .start(
        deviceID,
        this.cameraConfig,
        (decodedText, decodedResult) => {
          this.html5torch = false;
          this.qrValueAction(decodedText);
          this.html5QrCode.stop();
        },
        (errorMessage) => {
          // parse error, ignore it.
        }
      )
      .then(() => {
        setTimeout(() => {
          this.checkAndCallAfterFuntions();
          this.videoElementHidden = false;
        }, 1);
      })
      .catch((error) => {
        this.spinnerService.hide();
        this.cameraFeedDetected = false;
        this.videoElementHidden = false;
        this.cdr.detectChanges();

        throw error;
      });
  }
  initializeToolTip() {
    this.appService.initializeBootstrapTooltips();
  }

  cycleCamera() {
    if (this.cameraDevices.length > 1) {
      this.videoElementHidden = true;
      this.spinnerService.show();
      this.selectedCameraIndex =
        ((this.selectedCameraIndex || 0) + 1) % this.cameraDevices.length;
      sessionStorage.setItem(
        'selectedCameraIndex',
        this.selectedCameraIndex.toString()
      );
      let deviceID = this.cameraDevices[this.selectedCameraIndex]?.id;

      if (this.html5QrCode.getState() == 2) {
        this.html5QrCode.stop().then(() => {
          setTimeout(() => {
            this.startCamera(deviceID);
          }, 100);
        });
      } else {
        // already stopped
        setTimeout(() => {
          this.startCamera(deviceID);
        }, 100);
      }
    }
  }
  onSiteSearch(event?: any) {
    const searchString = event ? event.target.value : '';
    this.clientService
      .searchSites({
        search_str: searchString,
        is_active: 1,
      })
      .subscribe((res: any) => {
        if (res['status'] == 'success') {
          this.siteListData = res?.data;
          this.sortSiteListData();
        }
        if (!searchString) {
          this.initialSiteDataList = JSON.parse(
            JSON.stringify(this.siteListData)
          );
        }
      });
  }

  async sortSiteListData() {
    if (!this.userLocation?.lat) {
      this.userLocation = await this.deviceInfoService.getGpsCoordinates();
    }
    this.siteListData?.forEach((item: any) => {
      item.hDistance = Number(
        getDistanceUsingHaversine(
          this.userLocation?.lat,
          item?.gps?.lat,
          this.userLocation?.lon,
          item?.gps?.lon
        )
      );
    });
    await Promise.all(
      this.siteListData?.map((item: any) => {
        const distance =
          getDistanceUsingHaversine(
            this.userLocation?.lat,
            item?.gps?.lat,
            this.userLocation?.lon,
            item?.gps?.lon
          ) || 0;
        item.hDistance = Number(distance);
      })
    );

    this.siteListData = [
      ...sortArrayOfObject(this.siteListData, 'hDistance', 'asc'),
    ]?.splice(0, 10);

    this.cdr.detectChanges();
  }
  onSiteSelect(data: any) {
    if (data?.key === 0 && !this.offlineMode) {
      this.openAddSiteForm();
      this.checkPointForm.controls['site_id'].setValue(null);
      this.checkPointForm.controls['site'].setValue(null);
    }
    if (data?.id) {
      this.checkPointForm.controls['site_id'].setValue(data.id);
      this.checkPointForm.controls['site'].setValue(data.company_name);

      if (data?.company) {
        this.checkPointForm.controls['company_id'].setValue(data?.company?.id);
        this.checkPointForm.controls['company'].setValue(
          data?.company?.company_name
        );
      }
    }
  }
  openAddSiteForm() {
    const dialogRef = this.dialogService.open(AddEditSiteComponent, {
      data: {
        detailData: { company_name: this.searchSiteValue },
      },
    });
    dialogRef.afterClosed().subscribe((data: any) => {
      if (data !== 'close') {
        this.onSiteSelect(data);
      }
    });
  }
  editField(field: string) {
    if (field == 'name') {
      this.checkPointForm.controls['name'].setValue(null);
      this.shiftFocus('cpName');
    } else if (field == 'site') {
      this.checkPointForm.controls['site_id'].setValue(null);
      this.checkPointForm.controls['site'].setValue(null);
      this.checkPointForm.controls['company_id'].setValue(null);
      this.checkPointForm.controls['company'].setValue(null);
      this.siteListData = JSON.parse(JSON.stringify(this.initialSiteDataList));
      this.sortSiteListData();
      this.shiftFocus('siteId');
    }
  }
}
