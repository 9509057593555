import { isValidDomain } from './isValidDomain.js';
import { WebAuthnError } from './webAuthnError.js';
/**
 * Attempt to intuit _why_ an error was raised after calling `navigator.credentials.create()`
 */
export function identifyRegistrationError({
  error,
  options
}) {
  const {
    publicKey
  } = options;
  if (!publicKey) {
    throw Error('options was missing required publicKey property');
  }
  if (error.name === 'AbortError') {
    if (options.signal instanceof AbortSignal) {
      // https://www.w3.org/TR/webauthn-2/#sctn-createCredential (Step 16)
      return new WebAuthnError({
        message: 'Registration ceremony was sent an abort signal',
        code: 'ERROR_CEREMONY_ABORTED',
        cause: error
      });
    }
  } else if (error.name === 'ConstraintError') {
    if (publicKey.authenticatorSelection?.requireResidentKey === true) {
      // https://www.w3.org/TR/webauthn-2/#sctn-op-make-cred (Step 4)
      return new WebAuthnError({
        message: 'Discoverable credentials were required but no available authenticator supported it',
        code: 'ERROR_AUTHENTICATOR_MISSING_DISCOVERABLE_CREDENTIAL_SUPPORT',
        cause: error
      });
    } else if (
    // @ts-ignore: `mediation` doesn't yet exist on CredentialCreationOptions but it's possible as of Sept 2024
    options.mediation === 'conditional' && publicKey.authenticatorSelection?.userVerification === 'required') {
      // https://w3c.github.io/webauthn/#sctn-createCredential (Step 22.4)
      return new WebAuthnError({
        message: 'User verification was required during automatic registration but it could not be performed',
        code: 'ERROR_AUTO_REGISTER_USER_VERIFICATION_FAILURE',
        cause: error
      });
    } else if (publicKey.authenticatorSelection?.userVerification === 'required') {
      // https://www.w3.org/TR/webauthn-2/#sctn-op-make-cred (Step 5)
      return new WebAuthnError({
        message: 'User verification was required but no available authenticator supported it',
        code: 'ERROR_AUTHENTICATOR_MISSING_USER_VERIFICATION_SUPPORT',
        cause: error
      });
    }
  } else if (error.name === 'InvalidStateError') {
    // https://www.w3.org/TR/webauthn-2/#sctn-createCredential (Step 20)
    // https://www.w3.org/TR/webauthn-2/#sctn-op-make-cred (Step 3)
    return new WebAuthnError({
      message: 'The authenticator was previously registered',
      code: 'ERROR_AUTHENTICATOR_PREVIOUSLY_REGISTERED',
      cause: error
    });
  } else if (error.name === 'NotAllowedError') {
    /**
     * Pass the error directly through. Platforms are overloading this error beyond what the spec
     * defines and we don't want to overwrite potentially useful error messages.
     */
    return new WebAuthnError({
      message: error.message,
      code: 'ERROR_PASSTHROUGH_SEE_CAUSE_PROPERTY',
      cause: error
    });
  } else if (error.name === 'NotSupportedError') {
    const validPubKeyCredParams = publicKey.pubKeyCredParams.filter(param => param.type === 'public-key');
    if (validPubKeyCredParams.length === 0) {
      // https://www.w3.org/TR/webauthn-2/#sctn-createCredential (Step 10)
      return new WebAuthnError({
        message: 'No entry in pubKeyCredParams was of type "public-key"',
        code: 'ERROR_MALFORMED_PUBKEYCREDPARAMS',
        cause: error
      });
    }
    // https://www.w3.org/TR/webauthn-2/#sctn-op-make-cred (Step 2)
    return new WebAuthnError({
      message: 'No available authenticator supported any of the specified pubKeyCredParams algorithms',
      code: 'ERROR_AUTHENTICATOR_NO_SUPPORTED_PUBKEYCREDPARAMS_ALG',
      cause: error
    });
  } else if (error.name === 'SecurityError') {
    const effectiveDomain = globalThis.location.hostname;
    if (!isValidDomain(effectiveDomain)) {
      // https://www.w3.org/TR/webauthn-2/#sctn-createCredential (Step 7)
      return new WebAuthnError({
        message: `${globalThis.location.hostname} is an invalid domain`,
        code: 'ERROR_INVALID_DOMAIN',
        cause: error
      });
    } else if (publicKey.rp.id !== effectiveDomain) {
      // https://www.w3.org/TR/webauthn-2/#sctn-createCredential (Step 8)
      return new WebAuthnError({
        message: `The RP ID "${publicKey.rp.id}" is invalid for this domain`,
        code: 'ERROR_INVALID_RP_ID',
        cause: error
      });
    }
  } else if (error.name === 'TypeError') {
    if (publicKey.user.id.byteLength < 1 || publicKey.user.id.byteLength > 64) {
      // https://www.w3.org/TR/webauthn-2/#sctn-createCredential (Step 5)
      return new WebAuthnError({
        message: 'User ID was not between 1 and 64 characters',
        code: 'ERROR_INVALID_USER_ID_LENGTH',
        cause: error
      });
    }
  } else if (error.name === 'UnknownError') {
    // https://www.w3.org/TR/webauthn-2/#sctn-op-make-cred (Step 1)
    // https://www.w3.org/TR/webauthn-2/#sctn-op-make-cred (Step 8)
    return new WebAuthnError({
      message: 'The authenticator was unable to process the specified options, or could not create a new credential',
      code: 'ERROR_AUTHENTICATOR_GENERAL_ERROR',
      cause: error
    });
  }
  return error;
}