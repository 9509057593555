import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from '../shared/guards/auth.guard';
import { DeleteAccountComponent } from './delete-account/delete-account.component';
import { ErrorPage503Component } from './error-page503/error-page503.component';
import { ExternalAppComponent } from './external-app/external-app.component';
import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { RegisterComponent } from './register/register.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
    canActivate: [authGuard],
  },
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [authGuard],
  },
  {
    path: 'gt/:key',
    component: ExternalAppComponent,
  },

  {
    path: 'delete-account',
    component: DeleteAccountComponent,
  },

  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: '503',
    component: ErrorPage503Component,
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
