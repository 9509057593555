<div>
  <ul class="nav nav-tabs mb-1">
    <ng-container *ngFor="let tab of tabNames | keyvalue">
      <ng-container
        *ngIf="tab.value !== this.tabNames.tab3 || siteKeyListData?.length"
      >
        <li
          class="nav-item"
          (click)="changeTab(tab.value)"
          [id]="tabIds[tab.value]"
        >
          <a
            class="nav-link"
            [ngClass]="selectedTab === tab.value ? 'active' : ''"
            >{{ tab.value }}</a
          >
        </li>
      </ng-container>
    </ng-container>
  </ul>
</div>

@if(selectedTab === tabNames.tab1){
<gtapp-sites></gtapp-sites>

} @else if(selectedTab === tabNames.tab2){
<div>
  <div
    *ngIf="fileImportInProgress"
    class="alert alert-warning alert-dismissible fade show"
    role="alert"
  >
    <div class="fw-bold">
      File(s) import in progress ({{ fileImportInProgress }})
    </div>
  </div>
  <div class="me-lg-2 my-2 text-end" *ngIf="archivedCount">
    <button
      class="btn btn-secondary btn-sm"
      (click)="getArchivedUnarchivedList()"
      id="clientDeleteBtn"
    >
      {{ showArchivedList ? "Show Active" : "Show Deleted" }}
    </button>
  </div>
  <div *ngIf="showArchivedList" class="fw-bold text-center my-3" role="alert">
    Showing Deleted Clients
  </div>

  <div class="d-flex flex-column flex-sm-row input-btn-group mb-2">
    <div class="mb-2 me-sm-2 col-lg-2" *ngIf="!showArchivedList">
      <button
        id="addClient"
        class="btn btn-primary btn-lg w-100"
        (click)="openAddClientForm()"
      >
        + Add Client
      </button>
    </div>
    <div
      class="mb-2 flex-sm-grow-1"
      *ngIf="totalClientCount > 5"
      id="filterClient"
    >
      <gtapp-auto-complete
        [data]="clientDropDownList"
        placeHolder="Search Clients"
        searchKeyword="company_name"
        [itemTemplate]="clientLookupTemplate"
        (inputChanged)="onClientLookup($event)"
        (selected)="openCustomerView($event)"
        (submitted)="
          globalFilter = $event; clientPaginationData = {}; fetchCompanyList()
        "
        [enableSearchCloseOption]="true"
        (fieldCleared)="
          globalFilter = null; clientPaginationData = {}; fetchCompanyList()
        "
      >
      </gtapp-auto-complete>
      <ng-template #clientLookupTemplate let-item>
        <div>{{ item?.company_name }}</div>
      </ng-template>
    </div>

  </div>

  <div class="mt-3" id="clientList" *ngIf="totalClientCount > 0">
    <gtapp-gt-table
      [desktopTableBackendData]="clientPaginationData[pageNum]"
      [desktopTableDetails]="clientDetailsTableView"
      [tableStyle]="tableStyle"
      [otherParams]="otherParams"
      (sortColumn)="sortColumn($event)"
      (rowClick)="openCustomerView($event)"
      (searchColumn)="searchColumn($event)"
      (paginationChange)="onChangeTablePagination($event)"
    >
    </gtapp-gt-table>
  </div>
</div>
} @else if (selectedTab === tabNames.tab3) {
<div class="col-12 col-lg-6">
  <div class="d-flex my-4">
    <span>
      <span *ngIf="siteKeyTree.client?.id" (click)="siteKeyTree = {}">
        <span class="cursor-pointer text-decoration-underline">
          {{ siteKeyTree.client?.name }}</span
        >
      </span>
      <span *ngIf="siteKeyTree.site?.id" (click)="siteKeyTree.site = {}">
        >
        <span class="cursor-pointer text-decoration-underline">
          {{ siteKeyTree.site?.name }}</span
        >
      </span>
    </span>
  </div>

  <gtapp-gt-table
    [desktopTableUIData]="
      siteKeyTree.client?.id ? siteKeyTree.client?.site_info : siteKeyListData
    "
    [desktopTableDetails]="[
      {
        header: siteKeyTree.client?.id ? 'Site' : 'Client',
        name: 'name',
        searchKey: 'name'
      },
      { header: 'Key Count', name: 'key_count' }
    ]"
    [otherParams]="{ paginationData: true }"
    (rowClick)="
      $event?.key_count > 0
        ? (siteKeyTree[siteKeyTree.client?.id ? 'site' : 'client'] = $event)
        : null
    "
  >
  </gtapp-gt-table>
  @if(siteKeyTree?.site?.id){
  <ng-container>
    <dl>
      <div class="d-flex align-items-center">
        <div class="flex-grow-1">
          <dt>Site Keys</dt>
        </div>
      </div>
      <dd class="row p-1">
        <div
          *ngFor="let siteKey of siteKeyTree?.site?.keys"
          class="col-12 d-flex align-items-center py-2"
        >
          <i class="fa-solid fa-key text-warning"></i>
          <span class="ms-2">
            {{ siteKey?.value ? siteKey?.value : siteKey }}
            <span *ngIf="siteKey?.description"
              >[{{ siteKey?.description }}]</span
            >
          </span>
        </div>
      </dd>
    </dl>
  </ng-container>

  }
</div>
}
