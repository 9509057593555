import {
  Component,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  globalUserFeedbackStatusColor,
  isLargeScreen,
  isLargeScreenFun,
} from '../../../../global.variable';
import { AppService } from '../../../app.service';
import { ModelDialogueService } from '../../../shared/components/modal-dialogue/model-dialogue.service';
import { LoadingSpinnerService } from '../../../shared/services/loading-spinner.service';
import { OrientationLockService } from '../../../shared/services/orientationlock.service';
import { ToasterService } from '../../../shared/services/toaster.service';
import { ProfileService } from '../../profile/profile.service';

@Component({
  selector: 'gtapp-app-user-feedback',
  templateUrl: './app-user-feedback.component.html',
  styleUrl: './app-user-feedback.component.scss',
})
export class AppUserFeedbackComponent implements OnInit {
  previous: number = 0;
  pageSize: number = 10;
  pageNum: number = 1;
  totalPages: number = 0;
  totalRows: number = 0;
  fullCount: number = 0;
  initialCount: number = 0;
  selectedEvent: any;
  feedbacks: any = [];
  deskTopTableDetail = [
    {
      header: 'Date',
      name: 'created_at',
      datetimeObj: true,
      sortKey: 'created_at',
      tableColumnStyle: {
        'white-space': 'pre-line',
        'font-size': 'small',
      },
      // suffixNestedValue: (row: any) => {
      //   return row?.new_updates ? `\n[NEW UPDATE]` : null;
      // },
      conditionalPrefixStyle: (row: any) => {
        if (row?.new_updates) {
          return {
            'height': '8px',
            'width': '8px',
            'background-color': 'var(--bs-danger)',
            'border-radius': '50%',
            'display': 'inline-block',
          };
        }
        return {};
      },
    },

    {
      header: 'Description',
      name: 'feedback',
      tableColumnStyle: {
        'white-space': 'pre-line',
        'font-size': 'small',
      },
      nestedValue: (row: any) => {
        const wordSliceAt = this.largeView ? 150 : 50;
        return row?.feedback?.length > wordSliceAt
          ? `${row?.feedback?.slice(0, wordSliceAt)}...`
          : row?.feedback;
      },
      // suffixNestedValue: (row: any) => {
      //   return row?.status ? `\n${row?.status}` : null;
      // },
      // conditionalSuffixStyle: (row: any) => {
      //   if (row?.status) {
      //     return {
      //       'background-color': `${this.feedbackStatusColor?.[row?.status]}`,
      //       'color': 'var(--color-basic-800)',
      //       'font-size': 'small',
      //     };
      //   }
      //   return {};
      // },
    },

    {
      header: 'Status',
      name: 'status',
      conditionalColumnStyle: (row: any) => {
        if (row?.status) {
          return {
            'background-color': `${this.feedbackStatusColor?.[row?.status]}`,
            'color': 'var(--color-basic-800)',
            'font-size': 'small',
            'text-align': 'center',
          };
        }
        return { 'font-size': 'small' };
      },
    },
  ];

  sortKey: any;
  globalFilter: any;
  showTypeAhead: boolean = true;
  showSearchBox: boolean = false;
  scrollPosition: any;
  newComment: any;
  uploadedImage: any;
  fullImgSrc: any;
  dialogref: any;
  deleteReason: any;
  feedbackStatusColor: any = globalUserFeedbackStatusColor;

  showDefaultFeedbacks: boolean = true;
  feedbackForm = new FormGroup({
    feedback: new FormControl('', [
      Validators.required,
      Validators.maxLength(340),
    ]),
  });
  fileData: any = [];
  feedbackScreenShot: any;
  feedbackDropDownList: any = [];
  largeView: Boolean = isLargeScreen;
  isLargeScreenSubscription: Subscription = new Subscription();
  otherParams: any = {};
  constructor(
    private profileService: ProfileService,
    private spinnerService: LoadingSpinnerService,
    private dialogService: ModelDialogueService,
    private viewContainerRef: ViewContainerRef,
    private toasterService: ToasterService,
    private orientationService: OrientationLockService,
    private appService: AppService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.queryParams.subscribe({
      next: (params) => {
        if (params?.['sId']) {
          try {
            const uuidString = params?.['sId'];
            const hexString = uuidString?.replace(/-/g, '');
            const parsedUUID = BigInt('0x' + hexString)?.toString();
            const pkId = Number(parsedUUID);
            if (pkId) this.getFeedBackDetails(pkId);
          } catch (error) {}
        }
      },
    });
  }

  ngOnInit(): void {
    this.isLargeScreenSubscription =
      this.orientationService.isLargeScreen.subscribe(async (event: any) => {
        if (event) {
          this.largeView = await isLargeScreenFun();
        }
      });
    this.getFeedbacks();
  }
  onClickPagination(event: any) {
    this.previous = event.previous;
    this.pageNum = event.pageNum;
    this.pageSize = event.pageSize;
    this.getFeedbacks();
  }
  onChangePagination(event: any) {
    this.previous = event?.paginationData?.previousRows;
    this.pageNum = event?.paginationData?.pageNum;
    this.pageSize = event?.paginationData?.rows;
    this.getFeedbacks();

    this.otherParams.paginationData = { ...event?.paginationData };
  }
  showAllFeedbacks() {
    this.showDefaultFeedbacks = !this.showDefaultFeedbacks;
    this.getFeedbacks();
  }
  onFeedBackLookup(event: any) {
    if (
      event.target.value.length === 3 ||
      (event.target.value.length > 3 && this.feedbackDropDownList?.length)
    ) {
      this.profileService
        .getFeedbacks({ search_str: event.target.value })
        .subscribe((res: any) => {
          if (res['status'] == 'success') {
            this.feedbackDropDownList = res?.data;
          }
        });
    }
  }
  getFeedbacks() {
    this.spinnerService.show();
    let params: any = {};
    if (this.pageSize) {
      params['rows'] = this.pageSize;
    }
    if (this.previous) {
      params['previous'] = this.previous;
    }

    if (this.globalFilter) {
      params['search_str'] = this.globalFilter;
    }
    if (this.sortKey) {
      params['sort_key'] = this.sortKey;
    }
    if (this.showDefaultFeedbacks) {
      params['show_default'] = 1;
    }

    this.profileService.getFeedbacks(params).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.feedbacks = response['data'];

        this.totalRows = response['total_size'];
        this.fullCount = response?.full_count;
        this.initialCount = this.initialCount || this.totalRows;

        this.totalPages = Math.ceil(this.totalRows / this.pageSize);
        this.otherParams.paginationData = {
          totalRows: this.totalRows,
          previousRows: this.previous,
          rows: this.pageSize,
          pageNum: this.pageNum,
          totalPages: this.totalPages,
        };
      } else {
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: response['message'],
        });
      }

      this.spinnerService.hide();
    });
  }
  sortColumn(body: any) {
    this.sortKey = body?.sortList;

    this.getFeedbacks();
  }
  onFilterApply(event: any) {
    this.showSearchBox = true;
    if (event?.target?.value?.length === 0) {
      this.showTypeAhead = true;

      this.globalFilter = '';
      this.getFeedbacks();
    }
    if (event?.target?.value && event?.target?.value?.length >= 3) {
      this.getFeedbacks();

      this.showTypeAhead = false;
    }
  }

  onClearFilter(filterType?: string) {
    this.showSearchBox = false;
    this.globalFilter = '';
    this.showTypeAhead = true;

    this.getFeedbacks();
  }
  getFeedBackDetails(id: number) {
    this.spinnerService.show();
    this.profileService.getFeedbackById(id).subscribe((response: any) => {
      this.formatData(response);
      this.getFeedbacks();
      this.spinnerService.hide();
    });
  }
  formatData(response: any) {
    this.newComment = null;
    this.selectedEvent = response;
    this.uploadedImage = this.selectedEvent?.file_url;
    this.router.navigate([`/support`], {
      queryParams: {},
      fragment: this.selectedEvent?.feedback_key,
      replaceUrl: true, // Replaces the current URL without adding a new history entry
    });

    setTimeout(() => {
      var messageBody: any = document.querySelector('.overflow-45vh');
      if (messageBody) {
        messageBody.scrollTop =
          messageBody?.scrollHeight - messageBody?.clientHeight;
      }
    }, 100);
  }
  onRowClick(event: any) {
    this.scrollPosition = window.scrollY;
    this.getFeedBackDetails(event?.id);
  }
  hideDetails() {
    this.selectedEvent = null;

    // let scrollPosition: any = window.localStorage.getItem('scrollToView');
    setTimeout(() => {
      window.scrollTo(0, this.scrollPosition);
    }, 100);
  }
  addComment() {
    if (this.newComment) {
      this.spinnerService.show();
      this.profileService
        .updateFeedback(this.selectedEvent?.id, { comment: this.newComment })
        .subscribe((response: any) => {
          this.formatData(response?.data);

          this.spinnerService.hide();
        });
    }
  }

  openTemplate(template: TemplateRef<any>) {
    this.newComment = null;
    this.deleteReason = null;
    {
      this.dialogref = this.dialogService.open(
        template,
        {
          data: {},
        },
        this.viewContainerRef
      );
    }
  }
  deleteFeedBack() {
    this.spinnerService.show();
    this.profileService
      .updateFeedback(
        this.selectedEvent?.id,
        { comment: this.deleteReason },
        { delete: 1 }
      )
      .subscribe((response: any) => {
        this.selectedEvent = null;
        this.getFeedbacks();
        this.dialogref.close();
        this.spinnerService.hide();
      });
  }
  openAddFeedbackForm(requestRef: TemplateRef<any>) {
    this.spinnerService.show();
    this.showHideDraggableFeedbackBtn(true);
    this.feedbackForm.controls['feedback'].setValue(null);

    setTimeout(() => {
      this.spinnerService.hide();
      this.dialogref = this.dialogService.open(
        requestRef,
        {
          data: {},
        },
        this.viewContainerRef
      );
    }, 100);
    this.appService
      .getCurrentVersionInfo()
      .then((data: any) => sessionStorage.setItem('appVersion', data?.version));

    this.dialogref?.afterClosed()?.subscribe((value: any) => {
      if (value === 'close') {
        this.feedbackForm.controls['feedback'].setValue(null);
      }
    });
  }

  addFeedback() {
    this.spinnerService.show();
    const feedbackValue: any = this.feedbackForm.get('feedback')?.value;
    let fileData: FormData = new FormData();

    fileData.append('feedback', feedbackValue);
    fileData.append(
      'app_version',
      String(sessionStorage.getItem('appVersion') || '')
    );
    this.appService
      .formDataApi(`feedback/submit_feedback`, fileData)
      .then((response: any) => {
        if (response['status'] == 'success') {
          this.toasterService.setMessage({
            successMessage: response['message'],
            errorMessage: '',
          });
          this.feedbackForm.reset();
          this.dialogref.close();
          this.getFeedbacks();
          this.spinnerService.hide();
        } else {
          this.spinnerService.hide();
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
        this.spinnerService.hide();
      });
  }
  showHideDraggableFeedbackBtn(value: any) {
    if (value === true) {
      let element: any = document.getElementById('globalFeedbackbtn');
      element.style.visibility = 'hidden';
    } else {
      let element: any = document.getElementById('globalFeedbackbtn');
      element.style.visibility = 'visible';
    }
  }
}
