import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import {
  dateRangeValueConverter,
  formatDateTimeStamp,
  sortArrayOfObject,
} from '../../../../global.variable';
import { AppService } from '../../../app.service';
import { JobsService } from '../../../pages/pages/jobs/jobs.service';
import { DataCheckService } from '../../services/data-check.service';
import { DatetimePickerComponent } from '../datetime-picker/datetime-picker.component';
import { ModelDialogueService } from '../modal-dialogue/model-dialogue.service';

@Component({
  selector: 'gtapp-card-list',
  templateUrl: './card-list.component.html',
  styleUrl: './card-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardListComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() showTableFrom: any;

  @Output() actionClick = new EventEmitter();
  @Output() rowClick = new EventEmitter();
  @Output() searchColumn = new EventEmitter();
  @Output() sortColumn = new EventEmitter();
  @Output() paginationChange = new EventEmitter();

  @Input() mobileCardUIData: any;
  @Input() mobileCardBackendData: any;
  @Input() mobileCardDetails: any = [];
  @Input() cardStyle: any;
  @Input() otherParams: any;
  @Input() mobileActionIcons: any;

  apiFiltering: boolean = false;
  openSearchBox: boolean = false;
  selectedIndex: any;

  mobileDataTemp: any;
  cardData: any;
  appliedFilters: any = [];

  sortKeys: any;
  dialogeRef: any;

  //pagination params

  totalRows: number = 0;
  previousRows: number = 0;
  rows: number = 10;
  pageNum: number = 1;
  totalPages: number = 0;

  // filter and sort params for UI
  selectedCardDetail: any = {};
  filterSortData: any = [];
  currentFilterSortIndex: number = 0;
  dialogRef: any;
  showHeader: boolean = false;
  isSuperUser: boolean = false;

  autoCompleteSearchResultList: any = [];
  constructor(
    private dialogService: ModelDialogueService,
    private viewContainerRef: ViewContainerRef,
    private cdr: ChangeDetectorRef,
    private dateaCheckService: DataCheckService,
    private jobService: JobsService,
    private appService: AppService
  ) {
    this.isSuperUser = this.dateaCheckService.isSuperUser();
  }

  ngOnInit(): void {
    // card data
  }
  ngAfterViewInit(): void {
    const div = document.querySelector('.multiline-input');

    if (div) {
      div.innerHTML = div.innerHTML.replace('-', '<br>-');
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.applyPagination();

    this.apiFiltering = this.mobileCardBackendData ? true : false;

    this.cardData = this.mobileCardUIData || this.mobileCardBackendData;

    if (!this.apiFiltering) {
      this.applyFilters();
    }

    if (changes?.['mobileCardDetails']) {
      const initialDateRangeFilterCol =
        this.mobileCardDetails?.individualDetails?.find(
          (col: any) => col?.dateRange
        );

      if (initialDateRangeFilterCol) {
        this.appliedFilters = [];
        this.addDateRangeToList(
          initialDateRangeFilterCol.dateRange,
          initialDateRangeFilterCol
        );
      }
    }
    this.findFilterSortKeys();
    this.appService.initializeBootstrapTooltips();
  }

  applyPagination() {
    if (this.otherParams?.paginationData?.rows >= 0) {
      this.totalRows = this.otherParams?.paginationData?.totalRows;
      this.previousRows = this.otherParams?.paginationData?.previousRows;
      this.rows = this.otherParams?.paginationData?.rows;
      this.pageNum = this.otherParams?.paginationData?.pageNum;
      this.totalPages = this.otherParams?.paginationData?.totalPages;
    }
  }
  addDateRangeToList(event: any, column?: any) {
    let dateKey: any;
    let rangeField: any;
    if (column) {
      dateKey = column?.header ? column?.header : column?.name;
      rangeField = column?.dateRangeKey ? column?.dateRangeKey : column?.name;
      column.dateRange = event;
      column.dateRangeValue = `${dateRangeValueConverter(
        event?.start
      )} - \n ${dateRangeValueConverter(event?.end)}`;
    } else {
      let filteredItems = this.mobileCardDetails?.individualDetails?.filter(
        (item: any) => item?.datetimeObj == true
      );
      let keyValue =
        filteredItems?.find((item: any) => item?.default) || filteredItems[0];
      dateKey = keyValue?.header ? keyValue?.header : keyValue?.name;
      rangeField = keyValue?.dateRangeKey
        ? keyValue?.dateRangeKey
        : keyValue?.name;
    }

    if (dateKey) {
      this.appliedFilters = this.appliedFilters?.filter(
        (item: any) => item?.key != dateKey
      );
      this.appliedFilters.push({
        key: dateKey,
        value: `[${String(
          formatDateTimeStamp(event?.start, 'd MMM y HH:mm', 'en_US')
        )}] to [${String(
          formatDateTimeStamp(event?.end, 'd MMM y HH:mm', 'en_US')
        )}]`,
        date: event,
        datetimeObj: true,
        field: rangeField,
      });
    }
  }
  onActionClick(event: any, type: any) {
    this.actionClick.emit({ event, type });
  }

  onRowClick(rowData: any, index: number) {
    this.rowClick.emit(rowData);
    this.selectedIndex = index;
  }

  getSearchParams() {
    let searchParams: any = {};
    let body: any = {};
    this.appliedFilters.forEach((element: any) => {
      if (element?.datetimeObj) {
        searchParams[
          `${element?.field ? element.field : 'updated_at'}__range`
        ] = [element?.date?.start, element?.date?.end];
        body.date = element?.date;
      } else if (element?.column?.dropdownKey) {
        searchParams[element.column.dropdownKey] = element?.valueId;
      } else {
        searchParams[
          `${
            element?.column?.searchKey
              ? element.column.searchKey
              : element?.column?.name
          }__icontains`
        ] = element?.value;
      }
      body = { ...body, searchParams: searchParams };
    });
    return body;
  }

  sortField(column: any) {
    // Simplify the sortOrder assignment
    column['sortOrder'] = column?.sortOrder === 'asc' ? 'desc' : 'asc';

    // Reset sortOrder for other columns
    this.mobileCardDetails?.individualDetails?.forEach((item: any) => {
      if (item?.name !== column?.name) {
        item.sortOrder = null;
      }
    });

    // Check if API filtering is enabled
    if (this.apiFiltering) {
      let sortKeyValue: any = column?.sortKey || column?.name;
      let sortKey: any =
        column?.sortOrder == 'desc' ? `-${sortKeyValue}` : sortKeyValue;
      let body = this.getSearchParams();

      body.sortColumn = column;
      if (sortKey) body.sortList = [sortKey];
      this.sortColumn.emit(body);
    } else {
      this.applyFilters();
      if (column?.sortOrder) {
        let sortKey = column?.sortKey || column?.name;
        if (column?.datetimeObj) {
          this.mobileCardUIData.sort((a: any, b: any) => {
            let dateA = new Date(a[sortKey]).getTime();
            let dateB = new Date(b[sortKey]).getTime();
            return column?.sortOrder === 'desc' ? dateB - dateA : dateA - dateB;
          });
        } else {
          this.mobileCardUIData = sortArrayOfObject(
            this.mobileCardUIData,
            sortKey,
            column?.sortOrder
          );
        }
        this.resetTableData();
      }
    }
  }
  searchField(column: any) {
    let colKey = column?.header ? column?.header : column?.name;

    this.appliedFilters = this.appliedFilters?.filter(
      (item: any) => item?.key != colKey
    );
    this.appliedFilters.push({
      key: colKey,
      value: column?.searchValue,
      column: column,
    });
    this.applyFilters();

    setTimeout(() => {
      column.searchValue = '';
      column.openSearchBox = false;
      this.cdr.detectChanges();
    }, 100);
  }

  onTableRowClick(rowData: any) {
    this.rowClick.emit(rowData);
  }
  handleDateRange(event: any, column?: any) {
    if (event?.end) {
      this.addDateRangeToList(event, column);
      this.applyFilters();
    }
  }

  removeFilter(keyValue: any) {
    this.appliedFilters = this.appliedFilters?.filter(
      (item: any) => item?.key !== keyValue
    );
    this.applyFilters();
  }
  applyFilters() {
    this.dialogRef?.close();
    if (this.apiFiltering) {
      this.searchColumn.emit(
        this.appliedFilters?.length ? this.getSearchParams() : {}
      );
    } else {
      if (this.appliedFilters?.length) {
        this.filterTableData();
        this.mobileDataTemp = this.cardData;
        if (this.otherParams?.paginationData) {
          this.totalRows = this.mobileDataTemp?.length;
          this.totalPages = Math.ceil(this.totalRows / this.rows);
          this.cardData = this.mobileDataTemp?.slice(0, this.rows);
        }
      } else {
        this.resetTableData();
      }
    }
  }

  resetTableData() {
    this.cardData = this.mobileCardUIData;
    if (this.otherParams?.paginationData) {
      this.totalRows = this.cardData?.length;
      this.totalPages = Math.ceil(this.totalRows / this.rows);
      this.cardData = this.cardData?.slice(0, this.rows);
    }
  }

  filterTableData() {
    this.appliedFilters.forEach((element: any) => {
      this.cardData = this.mobileCardUIData?.filter((item: any) =>
        element?.datetimeObj
          ? this.filterByDate(item, element)
          : this.filterByValue(item, element)
      );
    });
  }

  filterByDate(item: any, element: any) {
    let elementField = element?.field ? element?.field : 'updated_at';
    let itemTime = new Date(item?.[elementField]).getTime();
    return (
      itemTime >= element?.date?.start.getTime() &&
      itemTime <= element?.date?.end.getTime()
    );
  }

  filterByValue(item: any, element: any) {
    const columnValue = element?.column?.nestedValue
      ? element?.column?.nestedValue(item) || item?.[element?.column?.name]
      : item?.[element?.column?.name];

    return columnValue?.toLowerCase().includes(element?.value?.toLowerCase());
  }

  getCardItemStyle(data: any, i: number) {
    if (i === this.selectedIndex) {
      return {
        'background-color': 'var(--highlight-bg)',
        'color': 'var(--highlight-color)',
      };
    }
    if (this.mobileCardDetails?.groupDetails) {
      return this.mobileCardDetails?.groupDetails(data);
    }
    if (this.otherParams?.tableRowStyle) {
      return this.otherParams?.tableRowStyle(data);
    }
    return {};
  }
  selectStatus(event: any, column: any) {
    this.appliedFilters = this.appliedFilters?.filter(
      (item: any) => item?.key != column?.name
    );

    this.appliedFilters.push({
      key: column?.name,
      value: event?.name,
      valueId: event?.id,
      column: column,
    });
    this.applyFilters();

    column.opendropDownBox = false;
  }
  openRangePicker(column: any) {
    this.dialogRef?.close();
    this.dialogeRef = this.dialogService.open(DatetimePickerComponent, {
      data: { dateRange: column?.dateRange },
    });
    this.dialogeRef.afterClosed().subscribe((value: any) => {
      if (value !== 'close') {
        this.handleDateRange(value, column);
      }
    });
  }

  onChangePagination(event: any) {
    this.previousRows = event.previous;
    this.pageNum = event.pageNum;
    this.rows = event.pageSize;
    if (this.apiFiltering) {
      this.paginationChange.emit({
        paginationData: {
          totalRows: this.totalRows,
          previousRows: this.previousRows,
          rows: this.rows,
          pageNum: this.pageNum,
          totalPages: this.totalPages,
        },
      });
    } else {
      if (this.appliedFilters?.length) {
        this.cardData = this.mobileDataTemp?.slice(
          this.previousRows,
          this.rows * this.pageNum
        );
      } else {
        this.cardData = this.mobileCardUIData?.slice(
          this.previousRows,
          this.rows * this.pageNum
        );
      }
    }
  }
  findFilterSortKeys() {
    if (this.otherParams?.singleEvent) {
      this.filterSortData = [];
      this.selectedCardDetail = null;
    } else {
      this.filterSortData = this.mobileCardDetails?.individualDetails?.filter(
        (item: any) =>
          item?.searchKey ||
          item?.dropdownKey ||
          item?.dateRangeKey ||
          item?.sortKey
      );

      if (this.filterSortData?.length) {
        this.selectedCardDetail =
          this.filterSortData[this.currentFilterSortIndex];
      }
    }
  }
  showNextCardDetail() {
    this.currentFilterSortIndex =
      (this.currentFilterSortIndex + 1) % this.filterSortData?.length;
    this.selectedCardDetail = this.filterSortData[this.currentFilterSortIndex];
  }
  openTemplate(template: TemplateRef<any>) {
    this.dialogRef = this.dialogService.open(
      template,
      {
        data: {},
      },
      this.viewContainerRef
    );
  }
  showHideHeader(event: any) {
    this.showHeader = event.target.checked;
    this.cdr.detectChanges();
  }
  onAutoCompleteInputSearch(event: any, selectedFilter: any) {
    this.onAssigneeSearch(event);
  }
  onAutoCompleteSearch(event: any, selectedFilter: any) {
    selectedFilter.searchValue = event;
    this.searchField(selectedFilter);
  }
  onAutoCompleteItemSelect(event: any, selectedFilter: any) {
    selectedFilter.searchValue =
      typeof event === 'string' ? event : event?.[selectedFilter?.autoComplete];
    this.searchField(selectedFilter);
  }
  onAssigneeSearch(event: any) {
    if (event.target.value?.length > 2) {
      this.getAssignees({ full_name: event?.target?.value });
    }
  }
  getAssignees(params?: any) {
    this.jobService.getAssignees(params).subscribe(async (response: any) => {
      if (response['status'] == 'success') {
        this.autoCompleteSearchResultList = response['data'];
        this.cdr.detectChanges();
      }
    });
  }
  hideTooltip() {
    this.appService.removeToolTips();
    this.appService.initializeBootstrapTooltips();
  }
}
