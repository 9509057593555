import {
  Component,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  formatDateTimeStamp,
  isLargeScreen,
  isLargeScreenFun,
} from '../../../../global.variable';
import { AppService } from '../../../app.service';
import { ModelDialogueService } from '../../../shared/components/modal-dialogue/model-dialogue.service';
import { DataCheckService } from '../../../shared/services/data-check.service';
import { LoadingSpinnerService } from '../../../shared/services/loading-spinner.service';
import { OrientationLockService } from '../../../shared/services/orientationlock.service';
import { ToasterService } from '../../../shared/services/toaster.service';
import { AddEditUserComponent } from './add-edit-user/add-edit-user.component';
import { UsersService } from './users.service';

@Component({
  selector: 'gtapp-users',
  templateUrl: './users.component.html',
  styleUrl: './users.component.scss',
})
export class UsersComponent implements OnInit {
  globalFilter: any;

  previous: number = 0;
  pageSize: number = 10;
  pageNum: number = 1;
  totalPages: number = 0;
  userPaginationData: any = {};
  totalUserCount: any;

  userDetailsTableView: any = [
    {
      header: 'Name',
      name: 'full_name',
      sortKey: 'full_name',
    },
    {
      header: 'Email',
      name: 'email',
    },
    {
      header: 'Group(s)',
      name: 'user_group',
      nestedValue: (row: any) => {
        let userGroups = [
          ...row?.['user_group']?.map((item: any) => {
            return item?.name;
          }),
        ].join(', ');

        return userGroups;
      },
      sortKey: 'user_group',
      cardRowStyle: {
        'font-weight': 'bold',
      },
    },
    {
      header: 'Last Update',
      name: 'last_activity_time',
      datetimeObj: true,
      sortKey: 'last_activity_time',
    },
    {
      header: 'Last Clock In',
      name: 'clocked_in_time',
      sortKey: 'clocked_in_time',
      nestedValue: (row: any) => {
        if (row?.user_status === 'pending' && !row?.clocked_in_time) {
          return `[USER NOT SIGNED-IN YET]`;
        } else if (row?.clocked_in_time !== null) {
          return String(
            formatDateTimeStamp(row?.clocked_in_time, 'd MMM y hh:mm', 'en_US')
          );
        }
        return '';
      },
      conditionalColumnStyle: (row: any) => {
        if (row?.['user_status'] === 'pending' && !row?.clocked_in_time) {
          return {
            color: 'var(--color-danger-300)',
          };
        } else {
          return {};
        }
      },
    },
  ];
  tableStyle = {
    'overflow': 'auto',
    'max-height': '100%',
  };
  userCardDetails = {
    individualDetails: [
      {
        nestedValue: (row: any) => {
          if (row?.['user_status'] == 'pending')
            return '[USER NOT SIGNED-IN YET]';
          else return;
        },
        cardRowStyle: {
          color: 'var(--color-danger-300)',
        },
      },

      {
        name: 'full_name',
        cardRowStyle: {
          'font-weight': 'bolder',
          'word-wrap': 'break-word',
        },
      },
      {
        name: 'email',
        cardRowStyle: {
          'font-size': 'small',
          'word-wrap': 'break-word',
          'white-space': 'nowrap',
          'overflow': 'hidden',
          'text-overflow': 'ellipsis',
          'display': 'block',
          'width': '85%',
        },
      },
      {
        nestedValue: (row: any) => {
          let userGroups = [
            ...row?.['user_group']?.map((item: any) => {
              return item?.name;
            }),
          ].join(', ');

          return userGroups;
        },
        cardRowStyle: {
          'font-size': '0.75rem',
          'white-space': 'pre-line',
          'font-style': 'oblique',
        },
      },
      {
        prefixNestedValue: (row: any) => {
          return row?.last_activity_time ? 'Last update at ' : '';
        },
        name: 'last_activity_time',
        dateTimeFormat: 'd MMM y HH:mm',
        datetimeObj: true,
        cardRowStyle: {
          'font-size': '0.75rem',
          'word-wrap': 'break-word',
          'margin-top': '0.5rem',
          'padding-top': '0',
        },
        conditionalRowDataStyle: (row: any) => {
          if (row?.last_activity_time) {
            return { 'font-weight': 600 };
          }
          return {};
        },
      },
      {
        prefixNestedValue: (row: any) => {
          return row?.clocked_in_time ? 'Clocked In at ' : '';
        },
        name: 'clocked_in_time',
        dateTimeFormat: 'd MMM y HH:mm',
        datetimeObj: true,
        cardRowStyle: {
          'font-size': '0.75rem',
          'word-wrap': 'break-word',
          'margin': '0',
          'padding-top': '0',
        },
        conditionalRowDataStyle: (row: any) => {
          if (row?.clocked_in_time) {
            return { 'font-weight': 600 };
          }
          return {};
        },
      },
    ],
  };

  userCount: number = 0;

  dialogref: any;

  sortKey: any;
  searchFilters: any;
  allotedUsers: any;
  totalAddedUsers: any;
  isSubscriberAdmin: boolean = false;
  isTestAccount: boolean = this.dataCheckService.isTestAccount();
  tabsUsers = {
    tab1: 'USERS',
    tab2: 'LICENCING',
  };
  selectedTab: string = this.tabsUsers.tab1;
  userDropDownList: any = [];
  otherParams: any = {};
  largeView: Boolean = isLargeScreen;
  isLargeScreenSubscription: Subscription = new Subscription();
  recommendedPlan: any;
  constructor(
    private route: ActivatedRoute,
    private userService: UsersService,
    private dialogService: ModelDialogueService,
    private router: Router,
    private appService: AppService,
    private spinnerService: LoadingSpinnerService,
    private dataCheckService: DataCheckService,
    private viewContainerRef: ViewContainerRef,
    private toasterService: ToasterService,
    private orientationService: OrientationLockService
  ) {
    // WHEN USER CLICK THE BACK BUTTON
    router.events.subscribe((event: any) => {
      if (event.navigationTrigger === 'popstate') {
        this.dialogref?.close();
      }
    });

    this.isSubscriberAdmin = this.dataCheckService.isSubscriberAdmin();
  }
  ngOnDestroy() {
    this.isLargeScreenSubscription.unsubscribe();
  }
  ngOnInit(): void {
    this.isLargeScreenSubscription =
      this.orientationService.isLargeScreen.subscribe(async (event: any) => {
        if (event) {
          this.largeView = await isLargeScreenFun();
        }
      });
    let userData = this.appService.getUserData();
    this.pageSize = userData?.preferences?.user_page_size || 10;
    this.getUsers();
  }

  onUserLookup(event: any) {
    if (
      event.target.value.length === 3 ||
      (event.target.value.length > 3 && this.userDropDownList?.length)
    ) {
      this.userService
        .getUserData({ globalFilter: event?.target.value })
        .subscribe((response: any) => {
          if (response['status'] == 'success') {
            this.userDropDownList = response?.data;
          }
        });
    }
  }
  getUsers() {
    let params: any = {};
    if (this.pageSize) {
      params['rows'] = this.pageSize;
      let userData = this.appService.getUserData();
      if (userData?.preferences) {
        userData.preferences.user_page_size = this.pageSize;
        this.appService.setUserData(userData);
      }
    }

    if (this.previous) {
      params['previous'] = this.previous;
    }
    if (this.globalFilter) {
      params['globalFilter'] = this.globalFilter;
    }

    if (this.searchFilters) {
      Object.entries(this.searchFilters).forEach(
        ([key, value]) => (params[key] = value)
      );
    }

    if (this.sortKey) {
      params['sort_key'] = this.sortKey;
    }

    if (!this.userPaginationData?.[this.pageNum]?.length || this.globalFilter) {
      this.spinnerService.show();
      this.userService.getUserData(params).subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.userCount = response['total_size'];
          this.otherParams.paginationData = {
            totalRows: this.userCount,
            previousRows: this.previous,
            rows: this.pageSize,
            pageNum: this.pageNum,
            totalPages: this.totalPages,
          };
          this.userPaginationData[this.pageNum] = [...response['data']];
          this.recommendedPlan = response?.recommended_plan;
          this.totalUserCount =
            response['registered_users_count'] +
            response['pending_users_count'];

          this.totalAddedUsers = response['total_user_count'];
          this.allotedUsers = response['allotted_slots'];
          this.route.params.subscribe({
            next: (params: any) => {
              if (this.route.snapshot.fragment) {
                if (this.route.snapshot.fragment === 'userAdded') {
                  this.router.navigate(['/users']);
                  this.openAddUserForm();
                }
              }
            },
          });
          if (this.totalUserCount === 0) {
            this.openAddUserForm();
          }

          localStorage.setItem(
            'appChecks',
            JSON.stringify({
              userCount: response?.['registered_users_count'] || 0,
            })
          );
          this.totalPages = Math.ceil(this.totalUserCount / this.pageSize);
        }
        this.spinnerService.hide();
      });
    }
  }

  rowClick(event: any) {
    this.dialogref = this.dialogService.open(AddEditUserComponent, {
      data: {
        editUser: true,
        detailData: event,
        fromUserPage: true,
      },
    });
    this.dialogref.afterClosed().subscribe((value: any) => {
      this.userPaginationData = {};
      this.getUsers();
    });
  }
  openAddUserForm() {
    if (this.totalAddedUsers >= this.allotedUsers && !this.isTestAccount) {
      if (!this.isSubscriberAdmin) {
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage:
            'Warning: Users exceeded than allowed slots, Contact your Admin to extend more slots!',
        });
      } else {
        this.router.navigate(['/subscription'], {
          fragment: 'addUser',
        });
      }
    } else {
      this.dialogref = this.dialogService.open(AddEditUserComponent, {
        data: { fromUserPage: true },
      });
      this.dialogref.afterClosed().subscribe((value: any) => {
        if (value !== 'close') {
          this.userPaginationData = {};
          this.getUsers();
        }
      });
    }
  }

  onChangePagination(event: any) {
    this.previous = event?.paginationData?.previousRows;
    this.pageNum = event?.paginationData?.pageNum;
    if (event?.paginationData?.rows != this.pageSize) {
      this.userPaginationData = {};
    }
    this.pageSize = event?.paginationData?.rows;
    this.getUsers();

    this.otherParams.paginationData = { ...event?.paginationData };
  }

  openTemplate(template: TemplateRef<any>, eventData: any) {
    this.dialogref = this.dialogService.open(
      template,
      {
        data: eventData,
      },
      this.viewContainerRef
    );
  }

  changeTab(event: any) {
    this.selectedTab = event.value;
  }

  sortColumn(body: any) {
    this.sortKey = body?.sortList || [];
    this.clearValues();
    this.getUsers();
  }
  searchColumn(event: any) {
    this.searchFilters = event?.searchParams;
    this.clearValues();
    this.getUsers();
  }
  clearValues() {
    this.previous = 0;
    this.previous = 0;
    this.pageNum = 1;
    this.pageNum = 1;
    this.globalFilter = '';
    this.userPaginationData = {};
  }
  changePlan() {
    if (this.isSubscriberAdmin) {
      this.router.navigate(['/subscription'], {
        fragment: 'changePlan',
      });
    }
  }
}
