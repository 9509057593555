@if(selectedStatus?.name){
<ng-container>
  <!-- Other Job Type Section -->
  <ng-container *ngIf="![0, 4].includes(selectedStatus?.type_identity)">
    <gtapp-other [selectedStatus]="selectedStatus"></gtapp-other
  ></ng-container>
  <!-- Response Job Type Section -->
  <ng-container *ngIf="selectedStatus?.type_identity === 0">
    <gtapp-response [selectedStatus]="selectedStatus"> </gtapp-response>
  </ng-container>
  <!-- Patrol Job Type Section -->
  <ng-container *ngIf="selectedStatus?.type_identity === 4">
    <gtapp-patrol [selectedStatus]="selectedStatus">
    </gtapp-patrol> </ng-container
></ng-container>
}@else {
<ng-container>
  <ng-container *ngIf="jobTypes?.length">
    <div class="p-1 fw-semibold mb-2">Select job type</div>
  </ng-container>
  <div class="row row-cols-md-2 row-cols-xl-4 g-4">
    <div *ngFor="let jobType of jobTypes">
      <button
        class="btn-lg btn btn-primary w-100"
        (click)="changeJobType(jobType)"
      >
        {{ jobType?.name }}
      </button>
    </div>
  </div>
</ng-container>
}
