<ul class="nav nav-tabs">
  <div id="reportTabs" class="d-flex">
    <li class="nav-item" (click)="changeTab(uiTabs.tab1)">
      <a class="nav-link" [class.active]="activeTab === uiTabs.tab1">{{
        uiTabs.tab1
      }}</a>
    </li>
    <li class="nav-item" (click)="changeTab(uiTabs.tab2)">
      <a class="nav-link" [class.active]="activeTab === uiTabs.tab2">{{
        uiTabs.tab2
      }}</a>
    </li>
  </div>
  <li class="nav-item" (click)="changeTab(uiTabs.tab3)">
    <a class="nav-link" [class.active]="activeTab === uiTabs.tab3">{{
      uiTabs.tab3
    }}</a>
  </li>
  <li
    class="nav-item"
    (click)="changeTab(uiTabs.tab4)"
    *ngIf="userPrompts?.length"
  >
    <a class="nav-link" [class.active]="activeTab === uiTabs.tab4">{{
      uiTabs.tab4
    }}</a>
  </li>
  <div id="configuredReportsTab" class="d-flex">
    <li
      class="nav-item"
      (click)="changeTab(uiTabs.tab5)"
      id="configuredReportTab"
    >
      <a class="nav-link" [class.active]="activeTab === uiTabs.tab5">{{
        uiTabs.tab5
      }}</a>
    </li>
  </div>
</ul>

<div class="tab-content">
  <div
    class="tab-pane"
    [ngClass]="activeTab === uiTabs.tab1 ? 'active' : ''"
    *ngIf="activeTab === uiTabs.tab1"
  >
    <ng-container *ngIf="!selectedEvent || largeView">
      <ng-template *ngTemplateOutlet="siteClientLookup"></ng-template>
    </ng-container>

    <ng-container
      *ngIf="
        reportSiteClientLookup?.client?.data?.id ||
        reportSiteClientLookup?.site?.data?.id
      "
    >
      @if(largeView){
      <gtapp-gt-table
        [desktopTableBackendData]="userEvents"
        [desktopTableDetails]="userEventDetailsTableView"
        (rowClick)="onTableRowClick($event)"
        (sortColumn)="sortColumn($event)"
        (searchColumn)="searchColumn($event)"
        [otherParams]="otherParams"
        (paginationChange)="onChangePagination($event)"
      ></gtapp-gt-table
      >}@else {
      <gtapp-card-list
        *ngIf="!selectedEvent"
        [mobileCardBackendData]="userEvents"
        [mobileCardDetails]="userEventDetailsMobileView"
        (rowClick)="onTableRowClick($event)"
        (sortColumn)="sortColumn($event)"
        (searchColumn)="searchColumn($event)"
        [otherParams]="otherParams"
        (paginationChange)="onChangePagination($event)"
      ></gtapp-card-list
      >}
    </ng-container>
  </div>
  <div
    class="tab-pane"
    [ngClass]="activeTab === uiTabs.tab2 ? 'active' : ''"
    *ngIf="activeTab === uiTabs.tab2"
  >
    <div class="mt-2">
      <div
        class="row row-cols-1 row-cols-md-2 mt-2 gy-2 mb-2"
        *ngIf="!selectedEvent"
      >
        <div class="col col-md-10">
          <div class="d-flex justify-content-evenly align-items-center">
            <div *ngFor="let option of jobReportTypes">
              <div class="form-check">
                <input
                  id="{{ option }}"
                  class="form-check-input"
                  type="radio"
                  [value]="option"
                  (change)="jobReportSelectRadio($event)"
                  [checked]="option === selectedJobReportType"
                />
                <label class="form-check-label" for="{{ option }}">
                  {{ option }}
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="col col-md-2">
          <button
            (click)="mailBillingReport()"
            [disabled]="!(userEvents?.length && allowBillingReportExport)"
            class="btn btn-primary btn-lg w-100"
          >
            Email Report (*.xlsx)
          </button>
        </div>
      </div>

      <div>
        @if(largeView){
        <gtapp-gt-table
          [desktopTableBackendData]="userEvents"
          [desktopTableDetails]="userEventDetailsTableView"
          [otherParams]="{ paginationData: true }"
          (rowClick)="onJobTableRowClick($event)"
          (sortColumn)="sortColumn($event)"
          (searchColumn)="searchColumn($event)"
        ></gtapp-gt-table
        >}@else {
        <gtapp-card-list
          [mobileCardBackendData]="userEvents"
          [mobileCardDetails]="userEventDetailsMobileView"
          (rowClick)="onJobTableRowClick($event)"
          (sortColumn)="sortColumn($event)"
          (searchColumn)="searchColumn($event)"
        ></gtapp-card-list
        >}
      </div>
    </div>
  </div>
  <div
    class="tab-pane"
    [ngClass]="activeTab === uiTabs.tab3 ? 'active' : ''"
    *ngIf="activeTab === uiTabs.tab3"
  >
    <ng-container *ngIf="!selectedEvent || largeView">
      <ng-template *ngTemplateOutlet="siteClientLookup"></ng-template>
    </ng-container>

    <div>
      @if(largeView){
      <gtapp-gt-table
        [desktopTableBackendData]="userEvents"
        [desktopTableDetails]="userEventDetailsTableView"
        (rowClick)="onTableRowClick($event)"
        (sortColumn)="sortColumn($event)"
        (searchColumn)="searchColumn($event)"
        [otherParams]="otherParams"
        (paginationChange)="onChangePagination($event)"
      ></gtapp-gt-table
      >}@else {
      <gtapp-card-list
        *ngIf="!selectedEvent"
        [mobileCardBackendData]="userEvents"
        [mobileCardDetails]="userEventDetailsMobileView"
        (rowClick)="onTableRowClick($event)"
        (sortColumn)="sortColumn($event)"
        (searchColumn)="searchColumn($event)"
        [otherParams]="otherParams"
        (paginationChange)="onChangePagination($event)"
      ></gtapp-card-list
      >}
    </div>
  </div>
  <div
    class="tab-pane"
    [ngClass]="activeTab === uiTabs.tab4 ? 'active' : ''"
    *ngIf="activeTab === uiTabs.tab4 && userPrompts?.length"
  >
    <ng-container *ngIf="!selectedEvent || largeView">
      <ng-template *ngTemplateOutlet="siteClientLookup"></ng-template>
    </ng-container>
    <ng-container
      *ngIf="
        (reportSiteClientLookup?.client?.data?.id ||
          reportSiteClientLookup?.site?.data?.id) &&
        selectedPromptIds?.length
      "
    >
      @if(largeView){
      <gtapp-gt-table
        [desktopTableBackendData]="userEvents"
        [desktopTableDetails]="userEventDetailsTableView"
        (rowClick)="onTableRowClick($event)"
        (sortColumn)="sortColumn($event)"
        (searchColumn)="searchColumn($event)"
        [otherParams]="otherParams"
        (paginationChange)="onChangePagination($event)"
      ></gtapp-gt-table
      >}@else {
      <gtapp-card-list
        *ngIf="!selectedEvent"
        [mobileCardBackendData]="userEvents"
        [mobileCardDetails]="userEventDetailsMobileView"
        (rowClick)="onTableRowClick($event)"
        (sortColumn)="sortColumn($event)"
        (searchColumn)="searchColumn($event)"
        [otherParams]="otherParams"
        (paginationChange)="onChangePagination($event)"
      ></gtapp-card-list
      >}
    </ng-container>
  </div>
  <div
    class="tab-pane"
    [ngClass]="activeTab === uiTabs.tab5 ? 'active' : ''"
    *ngIf="activeTab === uiTabs.tab5"
  >
    <div
      class="row col-12 col-lg-6 py-2"
      *ngIf="reportContactDataListTemp?.length"
    >
      <div class="col-12 col-lg-6">
        <div class="input-group">
          <div class="form-floating">
            <input
              class="form-control"
              type="text"
              placeholder="Filter Contacts"
              (input)="filterReportContacts('contact')"
              [(ngModel)]="filterValue.contact"
            />
            <label>Filter By Contact</label>
          </div>

          <span
            class="input-group-text"
            *ngIf="filterValue.contact"
            (click)="filterValue.contact = ''; filterReportContacts('contact')"
          >
            <i class="fa-solid fa-xmark"></i>
          </span>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="input-group">
          <div class="form-floating">
            <input
              class="form-control"
              type="text"
              placeholder="Filter Site"
              (input)="filterReportContacts('site')"
              [(ngModel)]="filterValue.site"
            />
            <label>Filter By Site</label>
          </div>

          <span
            class="input-group-text"
            *ngIf="filterValue.site"
            (click)="filterValue.site = ''; filterReportContacts('site')"
          >
            <i class="fa-solid fa-xmark"></i>
          </span>
        </div>
      </div>
    </div>

    <div class="col-12 col-lg-6">
      @for(contact of reportContactDataList; track contact){
      <div class="mb-2">
        <div
          class="d-flex align-items-center border shadow-sm p-2 rounded-2"
          (click)="expandCollapseContactTable(contact)"
          role="button"
        >
          <div class="d-flex me-3">
            <i class="fa-solid fa-address-book fa-2x"></i>
          </div>
          <div class="flex-grow-1">
            {{ contact?.contact_data?.full_name }}
            <div class="small-font text-lowercase">
              {{ contact?.contact_data?.email }}
            </div>
          </div>
          <div class="d-flex mx-3" *ngIf="contact?.reports?.length">
            @if(contact?.contact_data?.expanded){
            <span>
              <i class="fa-solid fa-chevron-up text-secondary"></i>
            </span>
            }@else{
            <span>
              <i class="fa-solid fa-chevron-down text-secondary"></i>
            </span>
            }
          </div>
        </div>

        <ng-container
          *ngIf="contact?.contact_data?.expanded && contact?.reports?.length"
        >
          <div class="ps-lg-3">
            <gtapp-gt-table
              [desktopTableUIData]="contact?.reports"
              [desktopTableDetails]="reportContactDataDetail"
              [tableActionIcons]="reportContactDataDetailActionColumn"
              (actionClick)="onReportContactActionIconClick($event)"
              [otherParams]="{ paginationData: true }"
            >
            </gtapp-gt-table>
          </div>
        </ng-container>
      </div>
      }
    </div>
  </div>
</div>

<ng-container *ngIf="selectedEvent">
  <gtapp-selected-event-detail
    [selectedEvent]="selectedEvent"
    selectedEventFrom="patrol"
    (goBack)="hideDetails()"
  ></gtapp-selected-event-detail>
</ng-container>

<!-- Report users dialog box -->
<ng-template #emailUserDialog let-uDRef="dialogRef">
  <div class="dialog-box card">
    <div class="card-header">
      Email Recipients
      <span class="float-end" (click)="uDRef.close()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>

    <div class="row row-cols-auto gy-2 mb-3">
      <div *ngFor="let user of selectedUsers">
        <button class="btn btn-dark rounded-5 small btn-sm">
          {{ user?.name }}
          <span class="ms-4" (click)="removeUser(user)">
            <i class="fa-solid fa-xmark"></i>
          </span>
        </button>
      </div>
    </div>
    <div class="mb-3">
      <gtapp-auto-complete
        [data]="usersList"
        placeHolder="Email Recipients"
        searchKeyword="user_full_name"
        [itemTemplate]="reportContactTemplate"
        [addInitialKey]="true"
        (inputChanged)="getUsers($event)"
        (selected)="addUser($event?.pk, $event)"
      >
      </gtapp-auto-complete>
    </div>

    <ng-template #reportContactTemplate let-item>
      @if (item?.key === 0) {
      <div>Add New Contact</div>
      } @else {
      <div>
        {{ item?.user_full_name ? item.user_full_name : item.user_email }}
        <div>
          <small>
            {{ item?.user_full_name ? item.user_email : "" }}
            {{
              item?.user_mobile_number ? " , " + item?.user_mobile_number : ""
            }}
          </small>
        </div>
      </div>
      }
    </ng-template>
    <div class="col" *ngIf="activeTab === uiTabs.tab1">
      <label for="user">
        <input
          class="form-check-input"
          type="checkbox"
          id="user"
          [(ngModel)]="showSummaryOnly"
        />
        Show Summary Only</label
      >
    </div>

    <div class="row row-cols-2 mt-3">
      <div class="col">
        <button class="btn btn-lg w-100 btn-dark" (click)="uDRef.close()">
          Cancel
        </button>
      </div>
      <div class="col">
        <button
          class="btn btn-lg w-100 btn-primary"
          [disabled]="!selectedUsers?.length"
          (click)="
            exportData(selectedPromptIds?.length ? 'csv' : 'pdf'); uDRef.close()
          "
        >
          Send
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #filterPromptDialog let-filterPromptDialog="dialogRef">
  <div class="dialog-box card">
    <div class="card-header">
      Prompts
      <span class="float-end" (click)="filterPromptDialog.close()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>

    <ng-container>
      <table class="table">
        <tbody>
          <tr
            *ngFor="let prompt of userPrompts; let i = index"
            class="cursor-pointer border-bottom"
          >
            <td>
              <div
                class="d-flex cursor-pointer"
                (click)="selectUserPrompt(prompt)"
              >
                <div>
                  <input
                    type="checkbox"
                    class="form-check-input"
                    [checked]="prompt?.selected"
                  />
                </div>
                <div class="ms-2">
                  {{ prompt?.name }}
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="row row-cols-2 mt-3">
        <div class="col">
          <button
            class="btn btn-dark w-100 btn-lg"
            (click)="filterPromptDialog.close()"
          >
            Cancel
          </button>
        </div>

        <div class="col">
          <button
            type="button"
            [disabled]="!selectedPromptIds?.length"
            class="btn btn-primary w-100 btn-lg"
            (click)="getEventLogData(); filterPromptDialog.close()"
          >
            Search
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #siteClientLookup let-siteClientLookupTempRef="dialogRef">
  <!-- <div class="me-lg-2 my-2 text-end">
    <button
      class="btn btn-secondary btn-sm"
      (click)="
        searchSite = !searchSite; editField(searchSite ? 'site' : 'client')
      "
    >
      {{ searchSite ? "Search By Client" : "Search By Site" }}
    </button>
  </div> -->
  <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 mt-4">
    <div class="col-12 col-md-4 col-lg-4">
      <div *ngIf="reportSiteClientLookup?.site?.data?.id">
        <dl class="px-2 px-md-4">
          <div class="d-flex align-items-center">
            <div class="flex-grow-1">
              <dt>Site</dt>
              <dd>
                {{ reportSiteClientLookup?.site?.value }}
                <span
                  *ngIf="
                    reportSiteClientLookup?.site?.data?.company?.company_name
                  "
                >
                  [{{
                    reportSiteClientLookup?.site?.data?.company?.company_name
                  }}]
                </span>
              </dd>
            </div>
            <div>
              <span (click)="editField('site')">
                <i
                  class="fa-solid fa-pen-to-square md-font-size text-secondary"
                ></i
              ></span>
            </div>
          </div>
        </dl>
      </div>
      <div
        *ngIf="!reportSiteClientLookup?.site?.data?.id"
        [ngClass]="reportSiteClientLookup?.site?.disabled ? 'opacity-50' : ''"
      >
        <gtapp-auto-complete
          *ngIf="!reportSiteClientLookup?.site?.hide"
          [data]="siteListData"
          placeHolder="Select Site"
          searchKeyword="company_name"
          [initialValue]="reportSiteClientLookup?.site?.value"
          [itemTemplate]="siteTemplate"
          (inputChanged)="onSiteSearch($event)"
          (selected)="onSelectSite($event)"
          inputId="siteId"
          [enableCloseOption]="true"
          (fieldCleared)="editField('site')"
          (inputFocused)="inputFocused('site')"
        >
        </gtapp-auto-complete>
        <ng-template #siteTemplate let-item>
          @if (item?.key === 0) {
          <div>Add New Site</div>
          } @else {
          <div>
            <div class="fw-medium">
              {{ item?.company_name }} [{{ item?.company?.company_name }}]
            </div>

            <div class="small-font text-secondary">
              {{ item?.address?.full_address }}
            </div>
          </div>
          }
        </ng-template>
      </div>
    </div>
    <div class="col-12 col-md-4 col-lg-4">
      <div *ngIf="reportSiteClientLookup?.client?.data?.id">
        <dl class="px-2 px-md-4">
          <div class="d-flex align-items-center">
            <div class="flex-grow-1">
              <dt>Client</dt>
              <dd>
                {{ reportSiteClientLookup?.client?.value }}
              </dd>
            </div>
            <div>
              <span (click)="editField('client')">
                <i
                  class="fa-solid fa-pen-to-square md-font-size text-secondary"
                ></i
              ></span>
            </div>
          </div>
        </dl>
      </div>
      <div
        *ngIf="!reportSiteClientLookup?.client?.data?.id"
        [ngClass]="reportSiteClientLookup?.client?.disabled ? 'opacity-50' : ''"
      >
        <gtapp-auto-complete
          *ngIf="!reportSiteClientLookup?.client?.hide"
          [data]="clientDropDownList"
          placeHolder="Select Client"
          searchKeyword="company_name"
          [itemTemplate]="clientLookupTemplate"
          (inputChanged)="onClientSearch($event)"
          [initialValue]="reportSiteClientLookup?.client?.value"
          (selected)="onClientSelect($event)"
          [enableCloseOption]="true"
          inputId="clientLookupId"
          (fieldCleared)="editField('client')"
          (inputFocused)="inputFocused('client')"
        >
        </gtapp-auto-complete>
        <ng-template #clientLookupTemplate let-item>
          <div>
            {{ item?.company_name }}
          </div>
        </ng-template>
      </div>
    </div>
    <div class="col-12 col-md-4 col-lg-4 row row-cols-1 m-0">
      <ng-container [ngSwitch]="activeTab">
        <ng-container *ngSwitchCase="uiTabs.tab1">
          <div class="col-12 col-lg-6 p-0">
            <button
              [disabled]="!(userEvents?.length && allowPDFExport)"
              class="btn btn-primary w-100 btn-lg"
              (click)="openTemplate(emailUserDialog)"
            >
              Email Report (.pdf)
            </button>
          </div></ng-container
        >
        <ng-container *ngSwitchCase="uiTabs.tab3">
          <div class="col-12 col-lg-6 p-0">
            <button
              [disabled]="!(userEvents?.length && allowPDFExport)"
              class="btn btn-primary w-100 btn-lg"
              (click)="exportData('pdf')"
            >
              Email Report (.pdf)
            </button>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="uiTabs.tab4">
          <div class="col-12 col-md-6 p-0">
            <button
              [disabled]="
                !reportSiteClientLookup?.client?.data?.id &&
                !reportSiteClientLookup?.site?.data?.id
              "
              class="btn btn-primary w-100 btn-lg mb-2"
              (click)="openTemplate(filterPromptDialog)"
            >
              User Prompt(s)
            </button>
          </div>
          <div class="col-12 col-md-6 p-0 px-md-2">
            <button
              [disabled]="!(userEvents?.length && allowPDFExport)"
              class="btn btn-primary w-100 btn-lg"
              (click)="openTemplate(emailUserDialog)"
            >
              Email Report (.pdf)
            </button>
          </div></ng-container
        >
      </ng-container>
    </div>
  </div>
</ng-template>
