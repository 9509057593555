<div class="card dialog-box">
  <div class="card-header">
    {{ updateView ? "Edit Client Details" : "Add New Client" }}
    <span class="float-end" (click)="onCloseDialogue()">
      <i class="fa-solid fa-xmark"></i>
    </span>
  </div>

  <form [formGroup]="clientForm" appFormEnterAction>
    <gtapp-input-field
      dtValue="Client Name"
      inputId="clientField"
      [maxLength]="256"
      [inputStringValue]="clientForm.value.company_name"
      (blurEmit)="clientForm.controls['company_name'].setValue($event)"
    >
    </gtapp-input-field>

    <gtapp-address-lookup [addressForm]="clientForm"> </gtapp-address-lookup>
    <div class="row row-cols-2 mt-4">
      <div>
        <button
          type="button"
          class="btn btn-dark w-100 btn-lg"
          (click)="onCloseDialogue()"
        >
          Cancel
        </button>
      </div>
      <div>
        <button
          type="button"
          class="btn btn-primary w-100 btn-lg"
          (click)="updateData()"
          [disabled]="!clientForm.valid"
        >
          Submit
        </button>
      </div>
    </div>
  </form>
</div>
