import { isValidDomain } from './isValidDomain.js';
import { WebAuthnError } from './webAuthnError.js';
/**
 * Attempt to intuit _why_ an error was raised after calling `navigator.credentials.get()`
 */
export function identifyAuthenticationError({
  error,
  options
}) {
  const {
    publicKey
  } = options;
  if (!publicKey) {
    throw Error('options was missing required publicKey property');
  }
  if (error.name === 'AbortError') {
    if (options.signal instanceof AbortSignal) {
      // https://www.w3.org/TR/webauthn-2/#sctn-createCredential (Step 16)
      return new WebAuthnError({
        message: 'Authentication ceremony was sent an abort signal',
        code: 'ERROR_CEREMONY_ABORTED',
        cause: error
      });
    }
  } else if (error.name === 'NotAllowedError') {
    /**
     * Pass the error directly through. Platforms are overloading this error beyond what the spec
     * defines and we don't want to overwrite potentially useful error messages.
     */
    return new WebAuthnError({
      message: error.message,
      code: 'ERROR_PASSTHROUGH_SEE_CAUSE_PROPERTY',
      cause: error
    });
  } else if (error.name === 'SecurityError') {
    const effectiveDomain = globalThis.location.hostname;
    if (!isValidDomain(effectiveDomain)) {
      // https://www.w3.org/TR/webauthn-2/#sctn-discover-from-external-source (Step 5)
      return new WebAuthnError({
        message: `${globalThis.location.hostname} is an invalid domain`,
        code: 'ERROR_INVALID_DOMAIN',
        cause: error
      });
    } else if (publicKey.rpId !== effectiveDomain) {
      // https://www.w3.org/TR/webauthn-2/#sctn-discover-from-external-source (Step 6)
      return new WebAuthnError({
        message: `The RP ID "${publicKey.rpId}" is invalid for this domain`,
        code: 'ERROR_INVALID_RP_ID',
        cause: error
      });
    }
  } else if (error.name === 'UnknownError') {
    // https://www.w3.org/TR/webauthn-2/#sctn-op-get-assertion (Step 1)
    // https://www.w3.org/TR/webauthn-2/#sctn-op-get-assertion (Step 12)
    return new WebAuthnError({
      message: 'The authenticator was unable to process the specified options, or could not create a new assertion signature',
      code: 'ERROR_AUTHENTICATOR_GENERAL_ERROR',
      cause: error
    });
  }
  return error;
}