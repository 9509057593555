import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
@Component({
  selector: 'gtapp-input-field',
  templateUrl: './input-field.component.html',
  styleUrl: './input-field.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputFieldComponent implements OnInit {
  @Output() blurEmit = new EventEmitter();
  @Input() dtValue: string = '';
  @Input() maxLength: number = 60;
  @Input() inputFieldType: string = 'text';
  @Input() inputId: string = uuidv4();
  @Input() inputStringValue: string = '';
  blurCalled: boolean = false;

  ngOnInit(): void {
    if (this.inputStringValue) {
      this.blurEmit.emit(this.inputStringValue);
      this.blurCalled = true;
    }
  }
  editField(clearString = false) {
    this.shiftFocus();
    this.blurCalled = false;
    if (clearString) {
      this.inputStringValue = '';
      this.blurEmit.emit(this.inputStringValue);
    }
  }
  onBlurEventTrigger() {
    setTimeout(() => {
      const inputElement = document.getElementById(this.inputId) as HTMLElement;

      if (document.activeElement !== inputElement) {
        // adding a timeout and checking the input field is still on focus so that close icon will work
        this.blurEmit.emit(this.inputStringValue);
        this.blurCalled = true;
      }
    }, 200);
  }

  shiftFocus() {
    setTimeout(() => {
      var element = <HTMLInputElement>document.getElementById(this.inputId);
      element?.focus();
    });
  }
}
