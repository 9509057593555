import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  getInitalDateRangeForListData,
  isLargeScreen,
  isLargeScreenFun,
} from '../../../../global.variable';
import { AppService } from '../../../app.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { ModelDialogueService } from '../../../shared/components/modal-dialogue/model-dialogue.service';
import { DataCheckService } from '../../../shared/services/data-check.service';
import { LoadingSpinnerService } from '../../../shared/services/loading-spinner.service';
import { OrientationLockService } from '../../../shared/services/orientationlock.service';
import { ToasterService } from '../../../shared/services/toaster.service';
import { ProfileService } from '../../profile/profile.service';
import { getDateTimeRange } from '../tableDetails';
import { CheckpointService } from './checkpoint.service';

@Component({
  selector: 'gtapp-checkpoints',
  templateUrl: './checkpoints.component.html',
  styleUrl: './checkpoints.component.scss',
})
export class CheckpointsComponent implements OnInit {
  viewType: string = 'tableView';
  checkPointList: any;

  totalRows: number = 0;
  previous: number = 0;
  rows: number = 5;
  pageNum: number = 1;
  totalPages: number = 0;
  totalCheckpointCount: number = 0;

  globalFilter: any;
  showTypeAhead: boolean = true;
  showSearchBox: boolean = false;

  tabNames = {
    tab1: 'Checkpoints',
    tab2: 'QR Codes',
    tab3: 'Missed Scans',
  };
  qrCodePage: boolean = false;
  // pagination variables for CP events

  tableStyle = {
    'overflow': 'auto',
    'max-height': '600px',
  };

  checkPointDetailsTableView: any = [
    {
      header: 'Checkpoint',
      name: 'name',
      sortKey: 'name',
      searchKey: 'name',
      toolTipData: (row: any) => {
        return row?.scanned_count
          ? `${row?.scanned_count} scans till now.`
          : 'No scans yet.';
      },
      suffixNestedValue: (row: any) => {
        return row?.qr_code ? '[QR]' : '';
      },
      suffixStyle: {
        'font-weight': '600',
      },
    },
    {
      header: 'Site / Client',
      name: 'site_name',
      sortKey: 'site__company_name',
      searchKey: 'site__company_name',
      nestedValue: (row: any) => {
        return row?.site_name ? row.site_name : row?.company;
      },
      suffixNestedValue: (row: any) => {
        return row?.site_name ? `\n${row?.company}` : null;
      },
      suffixStyle: {
        'font-size': 'small',
      },
      tableColumnStyle: {
        'white-space': 'pre-line',
      },
    },
    {
      header: 'Location',
      name: 'partial_address',
      searchKey: 'partial_address',
    },
  ];

  clientId: any;
  siteId: any;

  mapLatLong: any;
  selectedCp: any;
  sortKey: any;
  searchParams: any;
  userData: any;

  validateQR: boolean = false;

  dialogRef: any;

  qrToBeValidatedCount: number = 0;
  selectedTab: string = this.tabNames.tab1;
  checkPointCardsDetails: any = {
    individualDetails: [
      {
        imageSrc: 'assets/images/qr-code-logo.avif',
        imageObj: true,
        nestedValue: (row: any) => {
          return Boolean(row?.qr_code);
        },
        imageStyle: { width: '26px', float: 'right' },
      },
      {
        name: 'name',
        cardRowStyle: { 'font-weight': 'bold' },
      },
      {
        nestedValue: (row: any) => {
          if (row?.site_name) {
            return `${row?.site_name} [${row?.company}]`;
          }
          return row?.company;
        },
        cardRowStyle: { 'font-size': 'small' },
      },
      {
        cardRowStyle: { 'font-size': 'small' },
        nestedValue: (row: any) => {
          return row?.address?.full_address;
        },
      },
    ],
  };
  checkpointDropDownList: any = [];
  otherParams: any = {};
  largeView: Boolean = isLargeScreen;
  isAdmin = this.dataCheckService.isUserAdmin();
  isDispatchUser = this.dataCheckService.isDispatchUser();
  isLargeScreenSubscription: Subscription = new Subscription();

  infiniteDataList: any = [];

  //missed checkpoint data variables
  totalRowsMissedCheckpoint: number = 0;
  previousMissedCheckpoint: number = 0;
  pageSizeMissedCheckpoint: number = 10;
  pageNumMissedCheckpoint: number = 1;
  totalPagesMissedCheckpoint: number = 0;
  searchParamsMissedCheckpoint: any;
  sortKeysMissedCheckpoint: any = [];
  dateRangeMissedCheckpoint: any;

  otherParamsMissedCheckpoint: any = {};
  missedCheckpointData: any;
  tableDetailMissedCheckpoint = [
    // Date and Time Range
    {
      header: 'Shift',
      name: 'missed_at',
      datetimeObj: true,
      dateTimeFormat: 'd MMM y',
      dateRangeKey: 'missed_at',
      sortKey: 'missed_at',
      default: true,
      ...getDateTimeRange(),
      tableColumnStyle: {
        'white-space': 'pre-line',
        'font-size': 'small',
      },
      prefixNestedValue: (row: any) => {
        return `${row?.time_range} \n`;
      },
      prefixStyle: { 'font-size': '0.95rem' },
    },

    // Patrol Route and [Assigned Guard(s)]
    {
      header: 'Route',
      name: 'patrol_route_name',
      searchKey: 'patrol_route__name',
      sortKey: 'patrol_route__name',
      tableColumnStyle: {
        'white-space': 'pre-line',
      },
      suffixStyle: {
        'font-size': 'small',
      },
      suffixNestedValue: (row: any) => {
        return `\n ${row?.guards?.join(', ')}`;
      },
    },

    //Should include Checkpoint name, site name and/or [client name]
    {
      header: 'Checkpoint',
      name: 'checkpoint_name',
      searchKey: 'check_point__name',
      sortKey: 'check_point__name',
      tableColumnStyle: {
        'white-space': 'pre-line',
        'font-weight': '500',
      },
      suffixNestedValue: (row: any) => {
        return row?.site_name
          ? `\n ${row?.site_name} [${row?.company_name}]`
          : `\n ${row?.company_name}`;
      },
      suffixStyle: {
        'font-size': 'small',
        'font-weight': 'normal',
      },
    },
  ];

  mobileDetailMissedCheckpoint = {
    individualDetails: [
      {
        name: 'checkpoint_name',
        cardRowStyle: {
          'font-weight': 600,
        },
      },
      {
        name: 'site_name',
        cardRowStyle: { 'font-size': 'small' },
        suffixNestedValue: (row: any) => {
          return row?.site_name ? ` [${row?.company_name}]` : row?.company_name;
        },
      },

      {
        prefix: 'Route: ',
        name: 'patrol_route_name',
        suffixNestedValue: (row: any) => {
          return ' [' + row?.guards?.join(', ') + ']';
        },
      },

      {
        name: 'missed_at',
        dateTimeFormat: 'd MMM y',
        datetimeObj: true,
        dateRangeKey: 'missed_at',
        default: true,
        ...getDateTimeRange(),
        suffixNestedValue: (row: any) => {
          return `${row?.time_range}`;
        },
        suffixStyle: {
          'font-weight': '700',
          'font-size': '0.85rem',
        },
      },
    ],
  };

  constructor(
    private checkpointService: CheckpointService,
    private spinnerService: LoadingSpinnerService,
    private route: ActivatedRoute,
    private appService: AppService,
    private router: Router,
    private profileService: ProfileService,
    private dialogService: ModelDialogueService,
    private dataCheckService: DataCheckService,
    private toasterService: ToasterService,
    private orientationService: OrientationLockService
  ) {
    this.route.params.subscribe({
      next: (params: any) => {
        if (this.route.snapshot.fragment) {
          if (this.route.snapshot.fragment === 'validateQr') {
            this.validateQR = true;
          }
        }
      },
    });
  }
  ngOnDestroy() {
    this.isLargeScreenSubscription.unsubscribe();
  }

  ngOnInit(): void {
    const savedTab = sessionStorage.getItem('selectedTab');
    if (savedTab) {
      this.changeTab(savedTab);
    }
    this.rows = this.largeView ? 10 : 4;
    this.isLargeScreenSubscription =
      this.orientationService.isLargeScreen.subscribe(async (event: any) => {
        if (event) {
          this.largeView = await isLargeScreenFun();
          if (this.largeView) {
            this.viewType = cpListViewType?.desktopView || 'tableView';
          } else {
            this.viewType = cpListViewType?.mobileView || 'cardView';
          }
        }
      });
    this.userData = this.appService.getUserData();
    let cpListViewType = this.userData?.preferences?.cpListViewType;
    if (this.largeView) {
      this.viewType = cpListViewType?.desktopView || 'tableView';
    } else {
      this.viewType = cpListViewType?.mobileView || 'cardView';
    }
    this.clientId = this.route.snapshot.paramMap.get('clientId');
    this.siteId = this.route.snapshot.paramMap.get('siteId');
    this.dateRangeMissedCheckpoint = getInitalDateRangeForListData(
      this.mobileDetailMissedCheckpoint?.individualDetails || []
    );
    this.getCheckPoints();
    this.getMissedCheckpointData();
  }
  onCheckpointLookup(event: any) {
    if (
      event.target.value.length === 3 ||
      (event.target.value.length > 3 && this.checkpointDropDownList?.length)
    ) {
      this.checkpointService
        .getCheckpoints({ name: event.target.value })
        .subscribe((res: any) => {
          if (res['status'] == 'success') {
            this.checkpointDropDownList = res?.data;
          }
        });
    }
  }

  getCheckPoints() {
    let params: any = {};
    if (this.rows) {
      params['rows'] = this.rows;
    }
    if (this.previous) {
      params['previous'] = this.previous;
    }

    if (this.globalFilter) {
      params['name'] = this.globalFilter;
    }
    if (this.sortKey) {
      params['sort_key'] = this.sortKey;
    }
    if (this.searchParams) {
      Object.entries(this.searchParams).forEach(
        ([key, value]) => (params[key] = value)
      );
    }

    if (this.validateQR) {
      params['is_invalid'] = 1;
      this.viewType = this.largeView ? 'tableView' : 'cardView';
    }
    if (this.clientId) params['company_id'] = this.clientId;
    if (this.siteId) params['site_id'] = this.siteId;
    this.checkpointService.getCheckpoints(params).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.checkPointList = response?.data;
        // let newList = response?.data;
        // this.infiniteDataList = this.infiniteDataList.concat(newList);
        this.totalCheckpointCount = response?.cp_count;

        this.totalRows = response['total_size'];

        this.otherParams.paginationData = {
          totalRows: this.totalRows,
          previousRows: this.previous,
          rows: this.rows,
          pageNum: this.pageNum,
          totalPages: this.totalPages,
        };
        this.checkpointDropDownList = [];
        this.qrToBeValidatedCount = response?.validate_qr_count;
        this.totalPages = Math.ceil(this.totalRows / this.rows);
      } else {
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: response['message'],
        });
      }
    });
  }

  addCheckpoints() {
    this.router.navigate(['/add-checkpoint'], { relativeTo: this.route });
  }
  deleteCheckPoint(event: any) {
    let dialogMsg = `WARNING: This action cannot be reversed.`;
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        title: `Delete Checkpoint ${event?.name}`,
        message: dialogMsg,
        alertDanger: true,
      },
    });
    dialogRef.afterClosed().subscribe((value: any) => {
      if (value === true) {
        this.checkpointService
          .deleteCheckpointById(event.id)
          .then((response: any) => {
            this.toasterService.setMessage({
              errorMessage: response['message'],
              successMessage: '',
            });
            let subscriberStats = JSON.parse(
              localStorage.getItem('subscriberStats') || '{}'
            );
            subscriberStats.checkpoint_count =
              (subscriberStats?.checkpoint_count || 0) - 1;
            localStorage.setItem(
              'subscriberStats',
              JSON.stringify(subscriberStats)
            );
            this.selectedCp = undefined;
            this.mapLatLong = undefined;
            this.getCheckPoints();
          });
      }
    });
  }

  redirectCompanyORSite(cp: any) {
    if (cp?.site_id) {
      this.router.navigate(['/view-site', { sKey: String(cp?.site_id) }]);
    } else {
      this.router.navigate(['/view-client', { cKey: String(cp?.company_id) }]);
    }
  }
  onCheckpointRedirect(event: any) {
    this.router.navigate(['/view-checkpoints', { cpKey: String(event?.id) }]);
  }

  onCheckPointClick(event: any) {
    this.mapLatLong = {
      lat: event?.latitude,
      lon: event?.longitude,
    };
    if (event?.id === this.selectedCp?.id) {
      this.onCheckpointRedirect(event);
    }
    this.selectedCp = event;
  }

  onChangePagination(event: any) {
    this.previous = event?.paginationData?.previousRows;
    this.pageNum = event?.paginationData?.pageNum;
    this.rows = event?.paginationData?.rows;
    this.getCheckPoints();

    this.otherParams.paginationData = { ...event?.paginationData };
  }
  onMapChangePagination(event: any) {
    this.checkPointList = [];
    this.previous = event.previous;
    this.pageNum = event.pageNum;
    this.rows = event.pageSize;
    this.getCheckPoints();
  }
  clearPagination() {
    this.previous = 0;
    this.rows = this.largeView ? 10 : 4;
    this.pageNum = 1;
    this.totalPages = 0;
    this.totalRows = 0;
  }

  sortColumn(body: any) {
    this.clearPagination();
    this.sortKey = body?.sortList;
    this.searchParams = body?.searchParams || {};
    this.getCheckPoints();
  }
  searchColumn(event: any) {
    this.clearPagination();
    this.searchParams = event?.searchParams || {};
    this.getCheckPoints();
  }

  updatePreferences() {
    this.spinnerService.show();
    let body: any = {};
    let cpListViewType = this.userData?.preferences?.cpListViewType;

    if (this.largeView) {
      body = {
        desktopView: this.viewType,
        mobileView: cpListViewType?.mobileView || 'cardView',
      };
    } else {
      body = {
        mobileView: this.viewType,
        desktopView: cpListViewType?.desktopView || 'tableView',
      };
    }
    this.profileService
      .updateUser({ data: { cpListViewType: body } }, { save_preference: 1 })
      .then((response: any) => {
        this.spinnerService.hide();
      });

    let userData = this.appService.getUserData();
    if (userData?.preferences) {
      userData.preferences.cpListViewType = body;
      this.appService.setUserData(userData);
    }
  }
  changeTab(event: any) {
    if (!Object.values(this.tabNames)?.includes(event)) {
      event = this.tabNames.tab1;
    }
    this.qrCodePage = false;
    if (event === this.tabNames.tab2) {
      this.qrCodePage = true;
    }
    this.selectedTab = event;
    sessionStorage.setItem('selectedTab', this.selectedTab);
  }

  validateCheckpoints() {
    this.validateQR = true;
    this.router.navigate(['/checkpoints']);
    this.getCheckPoints();
  }
  switchView() {
    if (this.largeView) {
      this.viewType == 'tableView'
        ? (this.viewType = 'mapView')
        : (this.viewType = 'tableView');
      this.updatePreferences();
    } else {
      this.viewType == 'cardView'
        ? (this.viewType = 'mapView')
        : (this.viewType = 'cardView');
      this.updatePreferences();
    }
  }
  getMissedCheckpointData() {
    let body: any = {};
    this.spinnerService.show();

    if (this.dateRangeMissedCheckpoint)
      body.date_range = this.dateRangeMissedCheckpoint;

    if (this.sortKeysMissedCheckpoint)
      body.sort_key = this.sortKeysMissedCheckpoint;
    if (this.searchParamsMissedCheckpoint)
      body.search_filters = this.searchParamsMissedCheckpoint;

    if (this.pageSizeMissedCheckpoint) {
      body['rows'] = this.pageSizeMissedCheckpoint;
    }
    if (this.previousMissedCheckpoint) {
      body['previous'] = this.previousMissedCheckpoint;
    }

    this.checkpointService
      .missedCheckpoints(body)
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.totalRowsMissedCheckpoint = response['total_size'];

          this.totalPagesMissedCheckpoint = Math.ceil(
            this.totalRowsMissedCheckpoint / this.pageSizeMissedCheckpoint
          );
          this.otherParamsMissedCheckpoint = {
            paginationData: {
              totalRows: this.totalRowsMissedCheckpoint,
              previousRows: this.previousMissedCheckpoint,
              rows: this.pageSizeMissedCheckpoint,
              pageNum: this.pageNumMissedCheckpoint,
              totalPages: this.totalPagesMissedCheckpoint,
            },
          };
          this.missedCheckpointData = response['data'];
          let newList = response?.data;
          this.infiniteDataList = this.infiniteDataList.concat(newList);
          this.spinnerService.hide();
        } else {
          this.spinnerService.hide();
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
      });
  }

  redirectToPatrol(event: any) {
    if (event?.patrol_route_id) {
      this.router.navigate([
        '/view-route',
        { rKey: String(event.patrol_route_id) },
      ]);
    }
  }

  onChangePaginationMissedCheckpoint(data: any) {
    this.previousMissedCheckpoint = data?.paginationData?.previousRows;
    this.pageNumMissedCheckpoint = data?.paginationData?.pageNum;
    this.pageSizeMissedCheckpoint = data?.paginationData?.rows;

    this.getMissedCheckpointData();

    this.otherParamsMissedCheckpoint.paginationData = {
      ...data?.paginationData,
    };
  }
  sortColumnMissedCheckpoint(sortData: any) {
    this.resetPageMissedCheckPoint();
    this.sortKeysMissedCheckpoint = sortData?.sortList || [];
    this.getMissedCheckpointData();
  }
  searchColumnMissedCheckpoint(searchData: any) {
    this.resetPageMissedCheckPoint();
    this.dateRangeMissedCheckpoint = null;
    this.searchParamsMissedCheckpoint = searchData?.searchParams || {};
    this.getMissedCheckpointData();
  }
  resetPageMissedCheckPoint() {
    this.infiniteDataList = [];
    this.totalRowsMissedCheckpoint = 0;
    this.previousMissedCheckpoint = 0;
    this.pageSizeMissedCheckpoint = 10;
    this.pageNumMissedCheckpoint = 1;
    this.totalPagesMissedCheckpoint = 0;
    this.otherParamsMissedCheckpoint.paginationData = {
      totalRows: this.totalRowsMissedCheckpoint,
      previousRows: this.previousMissedCheckpoint,
      rows: this.pageSizeMissedCheckpoint,
      pageNum: this.pageNumMissedCheckpoint,
      totalPages: this.totalPagesMissedCheckpoint,
    };
  }
}
