<div
  id="clientDetail"
  class="pt-2"
  [ngStyle]="{
    'padding-bottom': '80px'
  }"
>
  <div class="me-lg-2 mb-2 text-end" *ngIf="isAdmin">
    <button
      class="btn btn-sm btn-danger"
      (click)="archiveUnarchive()"
      id="clientDeleteBtn"
    >
      Delete Client
    </button>
  </div>

  <div class="details-card mb-4">
    <div class="row row-cols-1 row-cols-lg-3">
      <div class="col-12 col-lg-5">
        <dl id="clientName">
          <div class="d-flex align-items-center">
            <div class="flex-grow-1">
              <dt>Client Name</dt>
              <dd>{{ detailData?.company_name }}</dd>
            </div>
            <div *ngIf="isAdmin || isDispatchUser">
              <span (click)="openEditTemplate()" id="editClientName">
                <i
                  class="fa-solid fa-pen-to-square md-font-size text-secondary"
                ></i
              ></span>
            </div>
          </div>
        </dl>

        <div>
          <dl id="clientAddress">
            <dt>Client Address</dt>

            <dd>
              {{ detailData?.address?.full_address }}
            </dd>
          </dl>
        </div>
        <ng-container
          *ngIf="isAdmin || isDispatchUser || detailData?.information"
        >
          <div>
            <!--the outer div is necessary for correct padding and matching other div dls-->
            <dl id="clientInformation">
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <dt>Client Information</dt>
                </div>
                <div class="d-flex align-items-center">
                  <span
                    id="editClientInformation"
                    (click)="openInformationTemplate(requestRef)"
                    *ngIf="isAdmin || isDispatchUser"
                  >
                    <i
                      class="fa-solid fa-pen-to-square md-font-size text-secondary"
                    ></i>
                  </span>
                </div>
              </div>
              <dd class="gt-multi-line">{{ detailData?.information }}</dd>
            </dl>
          </div>
        </ng-container>
        <ng-container *ngIf="isAdmin || isDispatchUser || clientFiles?.length">
          <div>
            <!--the outer div is necessary for correct padding and matching other div dls-->
            <dl id="clientFiles">
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <dt>
                    {{
                      isAdmin || isDispatchUser
                        ? "Client Files [" + clientFiles.length + " / 10 Max]"
                        : "Client Files"
                    }}
                  </dt>
                </div>
                <div class="d-flex align-items-center">
                  <span
                    id="editClientFiles"
                    (click)="openFileUploadTemplate(uploadRef)"
                    *ngIf="isAdmin || isDispatchUser"
                  >
                    <i
                      class="fa-solid fa-plus-circle md-font-size text-secondary"
                    ></i>
                  </span>
                </div>
              </div>
              <dd class="row p-1 pt-4">
                <div *ngFor="let file of clientFiles; let i = index">
                  <div class="d-flex mb-2">
                    <div class="flex-grow-1" (click)="onOpenFile(file)">
                      <span class="badge bg-primary me-2">{{
                        file?.extension
                      }}</span>
                      {{ file.description }}
                    </div>
                    <div
                      class="d-flex align-items-center me-2"
                      *ngIf="isAdmin || isDispatchUser"
                      (click)="deleteFile(file)"
                    >
                      <i
                        class="fa-regular fa-trash-can md-font-size text-secondary"
                      ></i>
                    </div>
                  </div>
                </div>
              </dd>
            </dl>
          </div>
        </ng-container>
      </div>
      <div class="col-12 col-lg-5">
        <dl id="clientSiteList">
          <div class="d-flex align-items-center mb-2">
            <div class="flex-grow-1">
              <dt>Sites</dt>
            </div>
            <div
              class="d-flex align-items-center"
              *ngIf="isAdmin || isDispatchUser"
            >
              <span (click)="addNewSite()" role="button" id="addNewClientSite">
                <i
                  class="fa-solid fa-plus-circle md-font-size text-secondary"
                ></i>
              </span>
            </div>
          </div>

          <dd>
            @if(!siteData?.length){
            <div class="alert alert-warning mt-2">No Sites Available</div>
            }@else {
            <table class="table table-hover">
              <tbody>
                <tr
                  *ngFor="let site of siteData; let i = index"
                  class="cursor-pointer"
                  (click)="openSiteView(site)"
                >
                  <td>
                    <div class="d-flex">
                      <!-- <div class="d-flex align-items-center me-3">
                      <i class="fa-solid fa-sitemap fa-2x"></i>
                    </div> -->
                      <div class="flex-grow-1">
                        {{ site?.company_name }}
                        <div class="small-font text-secondary">
                          {{ site?.address?.full_address }}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="mb-2">
              <button
                *ngIf="siteCount > 3"
                class="btn btn-primary btn-sm"
                (click)="showAllSites()"
              >
                View All Sites
              </button>
            </div>

            }
          </dd>
        </dl>
      </div>
      <div class="col-12 col-lg-2 d-none d-lg-block" *ngIf="isAdmin">
        <div class="row row-cols-1 gy-2">
          <div class="col">
            <button
              *ngIf="detailData?.company_stats?.job_count > 0"
              class="btn btn-primary w-100 btn-md"
              (click)="openJobView()"
            >
              Jobs
            </button>
          </div>
          <div class="col">
            <button
              *ngIf="detailData?.company_stats?.checkpoint_count > 0"
              class="btn btn-primary w-100 btn-md"
              (click)="openCPView()"
            >
              Checkpoints
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row row-cols-1 row-cols-md-3 mt-4" *ngIf="isAdmin" #pc id="pc">
      <div>
        <dl id="clientPatrolReports">
          <div class="d-flex align-items-center">
            <div class="flex-grow-1">
              <dt>Patrol Report Recipients</dt>
            </div>
            <div class="d-flex align-items-center">
              <span
                (click)="openSchedulerTemplate()"
                role="button"
                id="editClientPatrolReports"
              >
                <i
                  class="fa-solid fa-pen-to-square md-font-size text-secondary"
                ></i>
              </span>
            </div>
          </div>

          <dd>
            <div
              class="alert alert-warning mt-2"
              *ngIf="patrolReportSchedules?.length < 1"
            >
              No one is receiving patrol reports for checkpoint scans at the
              client level.
            </div>
            <div
              *ngIf="patrolReportSchedules.length > 0"
              class="small text-secondary"
            >
              <em>Reports for client updates will be sent to:</em>
            </div>

            <table class="table table-hover">
              <tbody>
                <tr
                  *ngFor="let schedule of patrolReportSchedules"
                  class="cursor-pointer"
                  (click)="openSchedulerTemplate(schedule)"
                >
                  <td>
                    <div class="d-flex">
                      <div class="d-flex align-items-center me-3">
                        <i class="fa-solid fa-calendar-days fa-2x"></i>
                      </div>
                      <div class="flex-grow-1">
                        <div>{{ schedule?.name }}</div>

                        <div class="small-font">
                          {{ schedule?.schedule?.repeat_type | titlecase }}
                          &#64;
                          {{ schedule?.mail_time }}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </dd>
        </dl>
      </div>
      <div>
        <dl id="clientJobReports">
          <div class="d-flex align-items-center">
            <div class="flex-grow-1">
              <dt>Job Report Recipients</dt>
            </div>
            <div class="d-flex align-items-center">
              <span
                (click)="openJobReportContactTemplate()"
                role="button"
                id="editClientJobReports"
              >
                <i
                  class="fa-solid fa-pen-to-square md-font-size text-secondary"
                ></i>
              </span>
            </div>
          </div>

          <dd #jc id="jc">
            <div
              class="alert alert-warning mt-2"
              *ngIf="userListData.length < 1"
            >
              No recipients configured for completed job reports at the
              <span class="fw-semibold">client level</span>.
            </div>
            <div *ngIf="userListData.length > 0" class="small text-secondary">
              <em>Completed job reports for all sites will be sent to:</em>
            </div>
            <table class="table table-hover">
              <tbody>
                <tr
                  *ngFor="let contact of userListData"
                  class="cursor-pointer"
                  (click)="openSiteContactTemplate(contact, detailData, 'jc')"
                >
                  <td>
                    <div class="d-flex">
                      <div class="d-flex align-items-center me-3">
                        <i class="fa-solid fa-address-book fa-2x"></i>
                      </div>
                      <div class="flex-grow-1">
                        {{ contact?.full_name }}
                        <div class="small-font">
                          {{ contact?.email }}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <div>
              <button
                *ngIf="clientData?.users?.length > 3"
                class="btn btn-primary btn-sm"
                (click)="
                  userListData?.length > 3
                    ? (userListData = clientData?.users?.slice(0, 3))
                    : (userListData = clientData?.users)
                "
              >
                {{ userListData?.length > 3 ? "View Less" : "View All" }}
              </button>
            </div>
          </dd>
        </dl>
      </div>
      <!-- <dl class="cursor-pointer">
        <dt (click)="openClientContactTemplate()">
          Client Contacts
          <span>
            <i class="fa-solid fa-pen-to-square"></i>
          </span>
        </dt>

        <dd>
          <div *ngIf="!contactListTemp?.length" class="alert alert-warning mt-2">
            No Contacts Have Been Added To This Client Yet
          </div>
          <div *ngIf="contactListTemp.length > 0" class="small text-secondary">
            <em>Contacts assigned to the Client:</em>
          </div>
          <table class="table table-hover">
            <tbody>
              <tr
                *ngFor="let contact of contactListTemp"
                class="cursor-pointer"
                (click)="openSiteContactTemplate(contact, detailData, 'lc')"
              >
                <td>
                  <div class="d-flex">
                    <div class="d-flex align-items-center me-3">
                      <i class="fa-solid fa-address-book fa-2x"></i>
                    </div>
                    <div class="flex-grow-1">
                      {{ contact?.full_name }}
                      <div class="small-font">
                        {{ contact?.email }}
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </dd>
      </dl> -->
    </div>
  </div>
  <ng-container *ngIf="largeView && isAdmin">
    <gtapp-gt-table
      id="clientHistory"
      [desktopTableUIData]="clientHistory"
      [desktopTableDetails]="userEventDetailsTableView"
      (rowClick)="onTableRowClick($event)"
    ></gtapp-gt-table>
  </ng-container>
  <ng-container *ngIf="selectedEvent">
    <gtapp-selected-event-detail
      [selectedEvent]="selectedEvent"
    ></gtapp-selected-event-detail>
  </ng-container>
  <div class="bottom-section d-block d-lg-none" id="fixedBtn" *ngIf="isAdmin">
    <div>
      <ng-container>
        <div class="p-2">
          <div class="d-flex justify-content-between">
            <div
              *ngIf="detailData?.company_stats?.job_count > 0"
              (click)="openJobView()"
              class="d-flex align-items-center justify-content-center flex-fill me-1 border border-primary btm-btn-height"
            >
              <i class="fa-solid fa-briefcase fa-2x"></i>
            </div>
            <div
              *ngIf="detailData?.company_stats?.checkpoint_count > 0"
              (click)="openCPView()"
              class="d-flex align-items-center justify-content-center flex-fill ms-1 border border-primary btm-btn-height"
            >
              <i class="fa-solid fa-location-dot fa-2x"></i>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <!-- Templates used to show in Dialogue  -->

  <!-- Client Information POPOVER / DIALOG -->
  <ng-template #requestRef let-data let-requestRef="dialogRef">
    <div class="dialog-box card">
      <div class="card-header">
        Client Information
        <span class="float-end" (click)="requestRef.close()">
          <i class="fa-solid fa-xmark"></i>
        </span>
      </div>

      <!-- Request Detail Section start-->
      <div class="row">
        <div class="col">
          <textarea
            class="form-control"
            placeholder="Client Information"
            cols="20"
            rows="5"
            wrap="hard"
            type="text"
            [(ngModel)]="newInfromation"
          ></textarea>
        </div>
      </div>

      <div class="row row-cols-2 mt-4">
        <div class="col">
          <button
            class="btn btn-lg w-100 btn-dark"
            (click)="requestRef.close()"
          >
            Cancel
          </button>
        </div>
        <div class="col">
          <button
            class="btn btn-lg w-100 btn-primary"
            [disabled]="newInfromation === this.detailData?.information"
            (click)="requestRef.close(); updateClientInformation()"
          >
            Update
          </button>
        </div>
      </div>

      <!-- Request Detail Section end-->
    </div>
  </ng-template>

  <!-- Client file Upload DIALOG -->
  <ng-template #uploadRef let-data let-uploadRef="dialogRef">
    <div class="dialog-box card">
      <div class="card-header">
        Upload File
        <span
          class="float-end"
          (click)="
            uploadRef.close();
            fileUploadError = null;
            fileDescription = null;
            uploadFile = null
          "
        >
          <i class="fa-solid fa-xmark"></i>
        </span>
      </div>

      <div class="mt-3" *ngIf="fileUploadError">
        <div class="alert alert-danger">
          {{ fileUploadError }}
        </div>
      </div>
      <div class="col">
        <button
          type="button"
          class="btn btn-md w-100 btn-primary"
          (click)="selectFile()"
        >
          {{ uploadFile ? "Change File" : "Upload File" }}
        </button>

        <input
          #fileInput
          id="fileInput"
          style="display: none; cursor: pointer"
          type="file"
          accept=".pdf, .jpg, .jpeg, .png"
          (change)="handleFileInput($event)"
        />
      </div>

      <div class="form-floating mt-3">
        <input
          class="form-control"
          placeholder="File Description"
          required
          maxlength="30"
          type="text"
          [(ngModel)]="fileDescription"
        /><label>File Description</label>
      </div>

      <div class="row row-cols-2 mt-4">
        <div class="col">
          <button
            class="btn btn-lg w-100 btn-dark"
            (click)="
              uploadRef.close();
              fileUploadError = null;
              fileDescription = null;
              uploadFile = null
            "
          >
            Cancel
          </button>
        </div>

        <div class="col">
          <button
            class="btn btn-lg w-100 btn-primary"
            [disabled]="!uploadFile || !fileDescription"
            (click)="onUploadFile(); uploadRef.close()"
          >
            Upload {{ uploadFile?.name }}
          </button>
        </div>
      </div>
    </div>
  </ng-template>
</div>
