<div class="dialog-box card">
  <div class="card-header">
    {{
      contactType === "jc"
        ? "Completed Job Report Recepients"
        : siteData?.company_name
        ? "Site Contacts"
        : "Client Contacts"
    }}
    <span class="float-end" (click)="onCloseDialogue()">
      <i class="fa-solid fa-xmark"></i>
    </span>
    <span>
      for
      <span class="fw-semibold">{{
        siteData?.company_name
          ? siteData?.company_name
          : clientData?.company_name
      }}</span>
    </span>
  </div>

  <div>
    <div class="mb-3">
      <gtapp-auto-complete
        [data]="contactLookupList"
        placeHolder="Recipient"
        searchKeyword="user_full_name"
        [itemTemplate]="userTemplate"
        [addInitialKey]="true"
        (inputChanged)="contactLookup($event)"
        (selected)="addContactToSelected($event)"
      >
      </gtapp-auto-complete>
    </div>

    <ng-template #userTemplate let-user>
      @if (user?.key === 0) {
      <div>Add Recipient</div>
      } @else {
      <div>
        {{ user?.user_full_name }}
        <div
          *ngIf="user?.location_contact_id"
          text="Onsite Contact"
          class="badge"
        ></div>
        <div>
          <small>
            {{ user?.user_full_name ? user.user_email : "" }}
            {{
              user?.user_mobile_number ? " , " + user?.user_mobile_number : ""
            }}
          </small>
        </div>
      </div>
      }
    </ng-template>
    <table class="table my-2">
      <tbody>
        <tr
          *ngFor="let contact of clientContactList"
          class="cursor-pointer"
          (click)="addRemoveContact(contact)"
        >
          <td>
            <div class="d-flex">
              <div>
                <div class="form-floating">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    [checked]="contact?.selected"
                  />
                </div>
              </div>
              <div class="flex-grow-1 ms-2">
                <div>
                  {{ contact?.user_full_name }}
                </div>
                <div class="small">
                  {{ contact?.user_full_name ? contact.user_email : "" }}
                  {{
                    contact?.user_mobile_number
                      ? " , " + contact?.user_mobile_number
                      : ""
                  }}
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="row row-cols-2 mt-3">
      <div class="col">
        <button (click)="onCloseDialogue()" class="btn btn-dark btn-lg w-100">
          Cancel
        </button>
      </div>
      <div class="col">
        <button class="btn btn-primary btn-lg w-100" (click)="addRemoveUsers()">
          Update
        </button>
      </div>
    </div>
  </div>
</div>
