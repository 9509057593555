import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DIALOG_DATA } from '../../../../global.variable';
import { ClientsService } from '../../../pages/pages/clients/clients.service';
import { LoadingSpinnerService } from '../../services/loading-spinner.service';
import { ToasterService } from '../../services/toaster.service';
import { DialogRef } from '../modal-dialogue/model-dialogue.ref';

@Component({
  selector: 'gtapp-add-edit-client',
  templateUrl: './add-edit-client.component.html',
  styleUrl: './add-edit-client.component.scss',
})
export class AddEditClientComponent implements OnInit {
  @Input() updateView: any;
  @Input() companyData: any;
  @Input() customerName: any;

  clientForm: UntypedFormGroup = new UntypedFormGroup({
    company_name: new UntypedFormControl('', [
      Validators.required,
      Validators.max(30),
    ]),
    addressLookup: new UntypedFormControl(''),
    is_individual: new UntypedFormControl(0),
    address1: new UntypedFormControl(''),
    address2: new UntypedFormControl('', [Validators.required]),
    address3: new UntypedFormControl(' '),
    city_name: new UntypedFormControl('', [Validators.required]),
    postcode: new UntypedFormControl(
      '',
      Validators.compose([Validators.required, Validators.max(99999999)])
    ),
    state_code: new UntypedFormControl('', [Validators.required]),
    longitude: new UntypedFormControl('', [Validators.required]),
    latitude: new UntypedFormControl('', [Validators.required]),
    country_name: new UntypedFormControl('Australia'),
    is_new_ui: new UntypedFormControl(true),
  });

  blurCalledDict: any = {};
  constructor(
    @Optional() protected dialogRef: DialogRef,
    private spinnerService: LoadingSpinnerService,
    private clientService: ClientsService,
    @Inject(DIALOG_DATA) public dialogueData: any,
    private toasterService: ToasterService
  ) {}

  ngOnInit(): void {
    this.updateView = this.dialogueData?.updateView;
    this.companyData = this.dialogueData?.companyData;
    this.customerName = this.dialogueData?.customerName;

    this.clientForm.reset(this.companyData?.address);
    this.clientForm.controls['company_name'].setValue(
      this.companyData?.company_name || this.customerName
    );
    this.clientForm.controls['is_new_ui'].setValue(true);

    if (!this.clientForm.value?.company_name) {
      this.shiftFocus('clientField');
    }
  }

  onCloseDialogue(companyData?: any) {
    if (companyData) {
      this.dialogRef.close(companyData);
    } else {
      this.dialogRef.close(false);
    }
  }

  onClearForm() {
    this.clientForm.reset();
  }

  updateData() {
    if (this.clientForm.valid) {
      var requestData = this.clientForm.value;

      this.spinnerService.show();
      if (!this.updateView) {
        this.clientService.addCompany(requestData).then((response: any) => {
          if (response['status'] == 'success') {
            this.spinnerService.hide();
            let subscriberStats = JSON.parse(
              localStorage.getItem('subscriberStats') || '{}'
            );

            subscriberStats.company_count =
              (subscriberStats?.company_count || 0) + 1;

            localStorage.setItem(
              'subscriberStats',
              JSON.stringify(subscriberStats)
            );

            this.onCloseDialogue(response);
            this.clientForm.reset();

            this.updateView = false;
          } else {
            this.spinnerService.hide();
            this.toasterService.setMessage({
              errorMessage: response['message'],
              successMessage: '',
            });
          }
        });
      } else {
        requestData['id'] = this.companyData.id;
        this.clientService
          .updateCompanyDetailsById(requestData)
          .then((response: any) => {
            if (response['status'] == 'success') {
              this.spinnerService.hide();

              this.toasterService.setMessage({
                successMessage: response['message'],
                errorMessage: '',
              });

              this.onCloseDialogue(response);

              this.updateView = false;
            } else {
              this.spinnerService.hide();
              this.toasterService.setMessage({
                errorMessage: response['message'],
                successMessage: '',
              });
            }
          });
      }
    } else {
      this.toasterService.setMessage({
        errorMessage: 'Please fill the required fields',
        successMessage: '',
      });
    }
  }

  shiftFocus(elementId: string) {
    setTimeout(() => {
      var element = <HTMLInputElement>document.getElementById(elementId);
      element?.focus();
    });
  }
  forceUppercaseConditionally(event: any) {
    this.clientForm.controls['city_name'].setValue(
      event.target.value.toUpperCase()
    );
  }
}
