import { Component, Inject, OnInit, Optional } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DIALOG_DATA } from '../../../../global.variable';
import { ClientsService } from '../../../pages/pages/clients/clients.service';
import { DataCheckService } from '../../services/data-check.service';
import { LoadingSpinnerService } from '../../services/loading-spinner.service';
import { ToasterService } from '../../services/toaster.service';
import { DialogRef } from '../modal-dialogue/model-dialogue.ref';

@Component({
  selector: 'gtapp-contact',
  templateUrl: './contact.component.html',
  styleUrl: './contact.component.scss',
})
export class ContactComponent implements OnInit {
  primarySiteContact: boolean = false;

  contactType: any;
  siteData: any;
  reportId: any;
  clientData: any;

  patrolReportUsers: boolean = false;
  addNewSiteContact: boolean = false;
  contactData: any;

  contactList: any = [];
  showAddForm: boolean = false;

  existingContacts: any = [];
  contactLookupList: any = [];

  patrolReportUser: any;

  editContact: boolean = false;

  contactForm: UntypedFormGroup = new UntypedFormGroup({
    first_name: new UntypedFormControl('', [Validators.required]),
    last_name: new UntypedFormControl('', [Validators.required]),
    title: new UntypedFormControl(),
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    mobile_number: new UntypedFormControl(null, [
      Validators.minLength(10),
      Validators.maxLength(10),
      Validators.pattern('^[0-9]*$'),
    ]),
  });
  isAdmin = this.dataCheckService.isUserAdmin();

  constructor(
    @Optional() protected dialogRef: DialogRef,
    private clientService: ClientsService,
    @Inject(DIALOG_DATA) public dialogueData: any,
    private spinnerService: LoadingSpinnerService,
    private dataCheckService: DataCheckService,
    private toasterService: ToasterService
  ) {}

  ngOnInit(): void {
    if (this.dialogueData) {
      this.contactType = this.dialogueData?.contactType;
      this.siteData = this.dialogueData?.siteData;
      this.reportId = this.dialogueData?.reportId;
      this.clientData = this.dialogueData?.clientData;
      this.patrolReportUsers = this.dialogueData?.patrolReportUsers;
      this.addNewSiteContact = this.dialogueData?.addNewSiteContact;
      this.contactData = this.dialogueData?.contactData;
    }

    if (!this.siteData && !this.clientData) {
      this.toasterService.setMessage({
        successMessage: '',
        errorMessage: 'No site or client found',
      });
      this.onCloseDialogue();
    } else {
      if (!this.contactData) this.getExistingContacts();
      if (this.addNewSiteContact === true) {
        this.showAddForm = true;
        this.contactForm.reset();
      }
    }
  }
  onCloseDialogue(val = false) {
    this.dialogRef.close(val);
  }
  sendDataBack() {
    this.dialogRef.close(this.contactForm.value);
  }

  getExistingContacts() {
    let clientId = this.clientData?.id
      ? this.clientData?.id
      : this.siteData?.company?.id;
    this.clientService
      .searchLocationContacts({ union: 1, company_id: clientId })
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.existingContacts = response?.data;
        }
      });
  }
  contactLookup(event: any) {
    this.contactForm.controls['first_name'].setValue(event.target.value);

    this.contactLookupList = this.existingContacts?.filter(
      (item: any) =>
        item?.user_full_name?.toLowerCase().includes(event?.target?.value) ||
        item?.user_email?.toLowerCase().includes(event?.target?.value)
    );
  }
  mobileNumberCheck() {
    if (!this.contactForm.value?.mobile_number) {
      this.contactForm.controls['mobile_number'].setValue(null);
      this.contactForm.controls['mobile_number'].removeValidators([
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$'),
      ]);
    } else {
      this.contactForm.controls['mobile_number'].setValidators([
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$'),
      ]);
    }
  }

  createContact() {
    var requestData: any = { id: 1, data: {} };
    requestData['data'] = this.contactForm.value;
    requestData['data']['contact_type'] = this.contactType;
    if (this.contactType === 'pc') {
      requestData['data']['report_mail_id'] = this.reportId;
    }
    if (this.contactType === 'pc' && !this.reportId) {
      this.clientService
        .addContactSubscriberUser(requestData)
        .subscribe((response: any) => {
          if (response['status'] == 'success') {
            this.toasterService.setMessage({
              successMessage: response['message'],
              errorMessage: '',
            });
            this.spinnerService.hide();
          } else {
            this.spinnerService.hide();
            this.toasterService.setMessage({
              successMessage: '',
              errorMessage: response['message'],
            });
          }
          this.onCloseDialogue(response?.data);
          this.showAddForm = false;
          this.contactForm.reset();
        });
    } else if (this.siteData) {
      this.spinnerService.show();
      requestData['id'] = this.siteData?.id;
      requestData.data.is_primary = this.primarySiteContact;
      this.clientService
        .addSiteContactById(requestData)
        .then((response: any) => {
          if (response['status'] == 'success') {
            this.onCloseDialogue(response?.data);

            this.toasterService.setMessage({
              successMessage: response['message'],
              errorMessage: '',
            });
            this.spinnerService.hide();
          } else {
            this.spinnerService.hide();
            this.toasterService.setMessage({
              successMessage: '',
              errorMessage: response['message'],
            });
          }
        });
    } else if (this.clientData) {
      this.spinnerService.show();
      if (this.patrolReportUsers) {
        this.patrolReportUser = this.contactForm.value?.email;
      }

      requestData['id'] = this.clientData?.id;
      this.clientService
        .addCustomerContactById(requestData)
        .then((response: any) => {
          if (response['status'] == 'success') {
            this.onCloseDialogue(response?.data);

            this.toasterService.setMessage({
              successMessage: response['message'],
              errorMessage: '',
            });
            this.spinnerService.hide();
            if (this.patrolReportUsers) {
              this.onCloseDialogue(true);
            }
          } else {
            this.toasterService.setMessage({
              successMessage: '',
              errorMessage: response['message'],
            });
            this.spinnerService.hide();
          }
        });
    }
  }
  togglePrimary(event: any) {
    this.primarySiteContact = event;
  }
  shiftFocus(elementId: string) {
    setTimeout(() => {
      var element = <HTMLInputElement>document.getElementById(elementId);
      element?.focus();
    });
  }

  selectContact(user: any) {
    this.contactForm.patchValue({
      first_name: user?.user_first_name,
      last_name: user?.user_last_name,
      mobile_number: user?.mobile_number ? user?.mobile_number : null,
      email: user?.user_email,
    });
  }
  onEditContact() {
    this.editContact = true;
    this.contactForm.patchValue(this.contactData);
    this.showAddForm = true;
  }
  updateContact() {
    if (this.contactForm.valid && this.contactData) {
      this.spinnerService.show();
      let requestData: any = { ...this.contactForm.value };
      requestData['contact_type'] = this.contactType;
      if (this.contactType === 'pc') {
        requestData['report_mail_id'] = this.reportId;
      }
      if (this.siteData) {
        requestData['site_id'] = this.siteData?.id;
      }
      if (this.clientData) {
        requestData['company_id'] = this.clientData?.id;
      }
      this.clientService
        .updateLocationContact(
          this.contactData?.id || this.contactData?.location_contact_id,
          requestData
        )
        .subscribe((response: any) => {
          if (response?.status == 'success') {
            this.spinnerService.hide();
            this.dialogRef.close(response?.data);
          } else {
            this.toasterService.setMessage({
              successMessage: '',
              errorMessage: response['message'],
            });
            this.spinnerService.hide();
          }
        });
    }
  }
  deleteContact() {
    if (this.clientData) {
      this.spinnerService.show();
      this.clientService
        .deleteClientContact(
          this.clientData?.id,
          this.contactData?.id,
          this.contactType
        )
        .then((res: any) => {
          this.dialogRef.close(res?.data);
          this.spinnerService.hide();
        });
    } else {
      this.spinnerService.show();
      this.clientService
        .deleteSiteContact(
          this.siteData?.id,
          this.contactData?.id,
          this.contactType
        )
        .then((res: any) => {
          this.dialogRef.close(res?.data);
          this.spinnerService.hide();
        });
    }
  }
}
