<div class="d-flex align-items-end justify-content-end pt-2">
  <div>
    <select
      class="form-select form-select-sm"
      [(ngModel)]="promptStatusSelected"
      (change)="getAllUserPrompts()"
    >
      <option [value]="option.key" *ngFor="let option of promptStatusOptions">
        {{ option.value }}
      </option>
    </select>
  </div>
  <button
    class="btn btn-sm me-lg-2 ms-2"
    [ngClass]="visibleOnHomePage ? 'btn-primary' : 'btn-secondary'"
    type="button"
    (click)="visibleOnHomePage = !visibleOnHomePage; getAllUserPrompts()"
  >
    {{ visibleOnHomePage ? "Show All" : "Visible on Home" }}
  </button>
</div>
<div class="d-flex flex-column flex-sm-row input-btn-group my-2">
  <div class="mb-2 me-sm-2 col-lg-2">
    <button class="btn btn-primary btn-lg w-100" routerLink="/user-prompt">
      + Add Prompt
    </button>
  </div>
  <div
    class="mb-2 flex-sm-grow-1"
    *ngIf="initialTotalUserPromptCount > 5"
    id="filterCheckpoint"
  >
    <gtapp-auto-complete
      [data]="userPromptDropDownList"
      placeHolder="Search Prompt"
      searchKeyword="name"
      [itemTemplate]="userPromptLookupTemplate"
      (inputChanged)="onUserPromptLookup($event)"
      (selected)="getPromptDetail($event)"
    >
    </gtapp-auto-complete>
    <ng-template #userPromptLookupTemplate let-item>
      <div>{{ item?.name }}</div>
    </ng-template>
  </div>
</div>

<div class="mb-3 small">Prompts are used to show or collect information.</div>
<gtapp-card-list
  [mobileCardBackendData]="userPromptList"
  [mobileCardDetails]="{
    individualDetails: [
      {
        name: 'name',
        cardRowStyle: {
          'font-weight': 'bolder',
          'display': 'flex',
          'padding': '1.8rem'
        }
      }
    ]
  }"
  [mobileActionIcons]="cardIcons"
  [otherParams]="otherParamsUserPrompt"
  (actionClick)="actionClick($event, copyPromptTemplateRef)"
  (rowClick)="getPromptDetail($event)"
  (paginationChange)="onChangePagination($event)"
></gtapp-card-list>
<ng-template
  #copyPromptTemplateRef
  let-copyPromptTemplateRef="dialogRef"
  let-data
>
  <div class="card dialog-box">
    <div class="card-header d-flex justify-content-between align-items-center">
      Copy User Prompt
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="copyPromptTemplateRef.close()"
      ></button>
    </div>
    <div>
      <div class="form-floating my-3">
        <input
          type="text"
          class="form-control"
          [(ngModel)]="promptName"
          placeholder="Prompt Name"
          id="promptNameInput"
        />
        <label for="promptNameInput">Prompt Name</label>
      </div>
      <div class="row row-cols-2">
        <div class="col">
          <button
            type="button"
            class="btn btn-dark btn-lg w-100"
            (click)="copyPromptTemplateRef.close()"
          >
            Cancel
          </button>
        </div>
        <div
          class="col"
          (click)="onConfirmCopy(data?.data?.id); copyPromptTemplateRef.close()"
        >
          <button type="button" class="btn btn-primary btn-lg w-100">
            Confirm
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
