import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isLargeScreen } from '../../../../../../global.variable';
import { AppService } from '../../../../../app.service';
import { ImagePreviewComponent } from '../../../../../shared/components/image-preview/image-preview.component';
import { ModelDialogueService } from '../../../../../shared/components/modal-dialogue/model-dialogue.service';
import { WebcamImageComponent } from '../../../../../shared/components/web-cam-image/web-cam-image.component';
import { LoadingSpinnerService } from '../../../../../shared/services/loading-spinner.service';
import { ToasterService } from '../../../../../shared/services/toaster.service';
import { WebSocketService } from '../../../../../shared/services/web-socket.service';
import { JobsService } from '../../../jobs/jobs.service';
import { UsersService } from '../../../users/users.service';
import { HolAndChatService } from '../../hol-and-chat.service';

@Component({
  selector: 'gtapp-message',
  templateUrl: './message.component.html',
  styleUrl: './message.component.scss',
})
export class MessageComponent implements OnInit, AfterViewInit, OnDestroy {
  userMessageList: any = [];
  assigneeSearchList: any = [];
  assigneeList: any = [];
  subscriberUserId =
    this.appService.getUserData()?.subscriber?.subscriber_user_id;

  previous: number = 0;
  rows: number = 20;
  totalRows: number = 0;

  selectedUser: any;
  messageList: any = [];
  newChat: boolean = false;
  chatMessage: string = '';
  largeView: Boolean = isLargeScreen;
  hideAdditionalItems: Boolean = false;
  containerHeight: string = '';
  messageFixedHeight: string = '';

  @ViewChild('content') content: any = ElementRef;

  handOverLogData: any;

  showFabActions: boolean = false;

  selectedMessage: any;
  filteredJobList: any = [];
  jobFilterValue: any;
  selectedJob: any;
  webSocketSubscription: any;
  websocketMessageReceivedCount = 0;

  constructor(
    private messageService: HolAndChatService,
    private jobService: JobsService,
    private toasterService: ToasterService,
    private appService: AppService,
    private dialogService: ModelDialogueService,
    private viewContainerRef: ViewContainerRef,
    private router: Router,
    private spinnerService: LoadingSpinnerService,
    private route: ActivatedRoute,
    private webSocketService: WebSocketService,
    private userService: UsersService
  ) {
    const userId = this.route.snapshot.fragment;
    if (userId) {
      this.getUserMessage({ id: userId });
    }
    this.getUserMessageList();
    this.webSocketSubscription =
      this.webSocketService.webSocketSubject.subscribe((event: any) => {
        if (event?.event === 'individual_chat' && event?.data?.id) {
          if (this.isLastItemVisible()) {
            // user is at the bottom of the screen
            this.appendNewMessage(event?.data);
            this.websocketMessageReceivedCount = 0;
            setTimeout(() => {
              this.scrollToBottom();
            }, 50);
          } else {
            this.appendNewMessage(event?.data);
            this.websocketMessageReceivedCount += 1;
          }
        } else if (
          event?.event == 'update_read_messages' &&
          event?.data?.length
        ) {
          const map = new Map<number, any>();
          for (const item of event?.data) {
            map.set(item.id, item);
          }

          this.messageList = this.messageList.map(
            (item: any) => map.get(item.id) || item
          );
        }
      });
  }
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updateViewPort();
  }
  ngOnDestroy(): void {
    if (this.webSocketSubscription) this.webSocketSubscription.unsubscribe();
  }

  isLastItemVisible = () => {
    const lastItemElement = document.getElementById('lastItem');
    const contentElement = this.content.nativeElement;

    if (!lastItemElement || !contentElement) {
      return false;
    }
    const lastItemRect = lastItemElement?.getBoundingClientRect();
    const contentRect = contentElement?.getBoundingClientRect();
    return lastItemRect.bottom <= contentRect.bottom;
  };
  ngOnInit(): void {
    this.handOverLogData = JSON.parse(
      sessionStorage.getItem('commentOnHOLData') || '{}'
    );
    sessionStorage.removeItem('commentOnHOLData');
  }
  ngAfterViewInit(): void {
    this.updateUserDetailMessageViewPort();
  }
  updateViewPort() {
    const btnBtnsHeight: any =
      document?.getElementById('bottom-btns')?.offsetHeight;
    // hard-coded values are calculated based on trial and error method
    this.containerHeight = `${window.innerHeight - 60}px`;
    this.messageFixedHeight = `${window.innerHeight - (85 + btnBtnsHeight)}px`;
  }
  scrollToBottom = () => {
    try {
      this.content.nativeElement.scrollTop =
        this.content.nativeElement.scrollHeight;
    } catch (err) {}
  };
  appendNewMessage(msg: any) {
    if (this.selectedUser?.id === msg?.sender?.id) {
      // this is a hack to avoid multiple api calls to get correct unread message count
      sessionStorage.setItem('lastMsgReceivedAt', String(new Date().getTime()));
      this.messageList.push(msg);
      this.markMessagesRead();
    } else {
      const isUserthere = this.userMessageList?.findIndex(
        (user: any) => user?.id === msg?.sender?.id
      );
      if (isUserthere >= 0) {
        this.userMessageList[isUserthere].unread += 1;
      } else {
        this.userMessageList?.splice(0, 0, {
          unread: 1,
          id: msg?.sender?.id,
          full_name: msg?.sender?.full_name,
        });
      }
    }
  }
  markMessagesRead() {
    const idList = [
      ...new Set(
        this.messageList
          ?.filter(
            (msg: any) =>
              !msg?.read_at && this.selectedUser?.id === msg?.sender?.id
          )
          ?.map((msg: any) => msg?.id)
      ),
    ];

    if (idList?.length) {
      const readMessageData = {
        event: 'read_messages',
        idList: idList,
      };

      this.webSocketService.sendData(readMessageData);
    }
  }
  getUserMessageList() {
    const lastWSTime = sessionStorage.getItem('lastMsgReceivedAt') || 0;
    this.messageService
      .getUserMessageList({ group: 1, count_after: lastWSTime })
      .subscribe((response: any) => {
        if (response?.status == 'success') {
          this.userMessageList = response?.data;
        }
      });
  }
  searchAssignee(event?: any) {
    if (event.target.value?.length > 2) {
      let params: any = { full_name: event.target.value };
      this.jobService.getAssignees(params).subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.assigneeSearchList = response?.data?.filter(
            (guard: any) => guard?.id != this.subscriberUserId
          );
        } else {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
      });
    }
  }
  getGuardList() {
    this.jobService.getAssignees({}).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.assigneeList = response?.data?.filter(
          (guard: any) => guard?.id != this.subscriberUserId
        );
      } else {
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: response['message'],
        });
      }
    });
  }
  openTemplate(template: TemplateRef<any>) {
    this.assigneeList = [];
    this.getGuardList();
    this.dialogService.open(
      template,
      {
        data: {},
      },
      this.viewContainerRef
    );
  }
  getUserMessage(userData: any) {
    if (this.selectedUser?.id == userData?.id) return;
    this.selectedUser = userData;

    this.router.navigate(['/message'], { fragment: userData?.id });
    let params: any = { counter_part_id: userData?.id };

    if (this.rows) {
      params['rows'] = this.rows;
    }
    if (this.previous) {
      params['previous'] = this.previous;
    }

    this.messageService
      .getUserMessageList(params)
      .subscribe((response: any) => {
        if (response?.status == 'success') {
          this.totalRows = response?.total_count;
          this.formatMessageList(response?.data);
          if (response?.receiver_data?.id) {
            this.selectedUser = response?.receiver_data;
          }
          this.updateUserDetailMessageViewPort();
        }
      });
  }
  updateUserDetailMessageViewPort() {
    setTimeout(() => {
      this.updateViewPort();
      this.scrollToBottom();
    }, 50);
  }
  formatMessageList(messageData: any) {
    this.chatMessage = '';
    this.handOverLogData = null;
    this.selectedMessage = null;
    this.selectedJob = null;
    this.messageList = messageData?.reverse();
    this.markMessagesRead();

    if (this.newChat) {
      this.getUserMessageList();
      this.newChat = false;
    }
    this.updateUserDetailMessageViewPort();
    this.spinnerService.hide();
  }
  openCamPopupDialog() {
    const dialogRef = this.dialogService.open(WebcamImageComponent, {
      data: {},
    });
    dialogRef.afterClosed().subscribe((value: any) => {
      if (value && value !== 'error') {
        this.sendFileMessage(value);
      }
    });
  }
  sendFileMessage(value: any) {
    let fileData: FormData = new FormData();
    this.spinnerService.show('Uploading File');
    fileData.append('file', value.actualFileUpload);
    if (value.newComment) {
      fileData.append('message', value.newComment);
    }
    let url = `gt_message`;
    fileData.append('counter_part_id', this.selectedUser?.id);
    fileData.append('image_source', value?.uploadType);
    if (this.selectedMessage?.id)
      fileData.append('message_id', this.selectedMessage?.id);

    if (this.selectedJob?.event_id) {
      fileData.append(
        this.selectedJob?.job_id ? 'job_id' : 'patrol_route_id',
        this.selectedJob?.event_id
      );
    }

    this.appService.formDataApi(url, fileData).then((response: any) => {
      if (response?.data) {
        this.formatMessageList(response?.data);
      }
    });
  }
  sendMessage() {
    let body: any = {
      counter_part_id: this.selectedUser?.id,
      message: this.chatMessage,
      hand_over_log_id: this.handOverLogData?.id,
      message_id: this.selectedMessage?.id,
    };
    if (this.selectedJob?.event_id) {
      body[this.selectedJob?.job_id ? 'job_id' : 'patrol_route_id'] =
        this.selectedJob?.event_id;
    }

    this.messageService.sendMessage(body).subscribe((response: any) => {
      if (response?.status == 'success') {
        this.formatMessageList(response?.data);
      }
    });
  }
  goBack() {
    this.messageList = [];
    this.selectedUser = null;
    this.getUserMessageList();
    this.router.navigate(['/message']);
  }
  onOpenImage(event: any, data: any) {
    var target = event.target || event.srcElement || event.currentTarget;
    var srcAttr = target.attributes.src;

    this.dialogService.open(ImagePreviewComponent, {
      data: { imageSrc: srcAttr.nodeValue, timeStamp: data.updated_at },
    });
  }
  showHideFAB() {
    this.showFabActions = !this.showFabActions;
  }
  openJobSearchTemplate(template: any) {
    this.dialogService.open(
      template,
      {
        data: {},
      },
      this.viewContainerRef
    );
  }
  searchJobs(event: any) {
    this.jobFilterValue = event.target.value;
    if (event.target?.value?.length > 3) {
      let body: any = {
        event_type: 'filter_user_jobs',
        search_str: event.target?.value,
      };
      this.userService.getUserUpdateLog(body).subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.filteredJobList = response?.data;
        } else {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
          this.spinnerService.hide();
        }
      });
    } else {
      this.filteredJobList = [];
    }
  }
  routeToJob(messageData: any) {
    if (messageData?.job?.id) {
      this.router.navigate([`/job-detail/JOBID`], {
        queryParams: { jId: messageData?.job?.id },
      });
    } else {
      this.router.navigate([
        '/view-route',
        {
          rKey: String(messageData?.patrol_route?.id),
        },
      ]);
    }
  }
  onWindowScroll(event: any): void {
    // function to load older messages if any if the scroll reaches the top (event.target.scrollTop === 0)

    if (this.isLastItemVisible()) {
      this.websocketMessageReceivedCount = 0;
    }
    if (
      event.target.scrollTop === 0 &&
      this.messageList?.length !== this.totalRows &&
      this.selectedUser?.id
    ) {
      this.spinnerService.show();
      const currentScrollHeight = event.target.scrollHeight;
      const currentScrollTop = event.target.scrollTop;

      this.previous = this.previous + this.rows;
      let params: any = { counter_part_id: this.selectedUser?.id };
      if (this.rows) {
        params['rows'] = this.rows;
      }
      if (this.previous) {
        params['previous'] = this.previous;
      }
      this.messageService
        .getUserMessageList(params)
        .subscribe((response: any) => {
          if (response?.status == 'success') {
            const previousData = this.messageList?.reverse();
            const oldDataAppended = previousData.concat(response?.data);
            this.messageList = oldDataAppended?.reverse();

            setTimeout(() => {
              // so that list doesnt jump to the top
              const newScrollHeight = event.target.scrollHeight;
              const newScrollTop =
                newScrollHeight - (currentScrollHeight - currentScrollTop);
              event.target.scrollTop = newScrollTop;
              this.spinnerService.hide();
            }, 10);
          }
        });
    }
  }
}
