<div class="card dialog-box">
  <div class="card-header" *ngIf="title">
    {{ title }}
    <span class="float-end" (click)="onDismiss()"
      ><i class="fa-solid fa-xmark"></i
    ></span>
  </div>
  <div>
    <ng-container *ngIf="customTemplate">
      <ng-container *ngTemplateOutlet="customTemplate"></ng-container>
    </ng-container>
    <div *ngIf="message">
      <div class="alert" [ngStyle]="messageStyle">{{ message }}</div>
    </div>

    <div *ngIf="confirmation">
      <div class="text-center">
        To confirm, type {{ randomNUmber }} in the field:
      </div>
      <input
        type="tel"
        placeholder="{{ randomNUmber }}"
        [(ngModel)]="confirmNumber"
        class="form-control"
        autofocus
      />
    </div>
    <div class="row row-cols-2 mt-2">
      <div class="col">
        <button
          (click)="onDismiss()"
          class="btn btn-lg btn-secondary w-100"
          type="button"
        >
          Cancel
        </button>
      </div>
      <div class="col">
        <button
          (click)="onConfirm()"
          [disabled]="confirmation ? randomNUmber != confirmNumber : false"
          class="btn btn-lg btn-primary w-100"
          type="button"
        >
          Confirm
        </button>
      </div>
    </div>
  </div>
</div>
